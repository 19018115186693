<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    
    <!---->
    <div 
      fxFlex="100"
      class="page-wrap"
      style="height: 100vh;"
      >
      <div class="session-form-hold"> 
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4 style="color: white;">Restablecer contraseña</h4>
            </div>
            <div class="form-box">
              <form [formGroup]="form" (ngSubmit)="send()">
                <div class="form-group">
                  <input (input)="form.controls['temporalPassword'].markAsTouched()" type="text" matInput formControlName="temporalPassword" placeholder="Código vía correo electrónico" value="">
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['temporalPassword'].hasError('required') && form.controls['temporalPassword'].touched" 
                  class="form-error-msg"> * Requerido</small>
                </div>
                <!---->
                <div class="form-group">
                  <input (input)="form.controls['newPassword'].markAsTouched()" matInput formControlName="newPassword" placeholder="Nueva contraseña" value="" type="password">
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['newPassword'].hasError('required') && form.controls['newPassword'].touched" 
                  class="form-error-msg"> * Requerido</small>
                </div>
                <!---->
                <div class="form-group">
                  <input (input)="form.controls['confirmPassword'].markAsTouched()" matInput formControlName="confirmPassword" placeholder="Confirmar contraseña" value="" type="password" >
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.errors?.mismatch && (form.controls['confirmPassword'].dirty || form.controls['confirmPassword'].touched)" 
                      class="form-error-msg"> * Las contraseñas no coinciden</small>
                </div>
                <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">Cambiar contraseña</button>
                <div class="text-center">
                  <a [routerLink]="'/signin'" class="m-1 hyper mat-primary text-center full-width">Cancelar</a>
                  <span fxFlex></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="session-form-hold">
        <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
        <mat-card style="padding: 0px;">
          <mat-card-title class="text-left bg-purple text-light p-2">
            <h3 style="font-size: 1.5em; text-align: center; width: 100%; padding-top:8px;">Restablecer Contraseña:</h3>
          </mat-card-title>
          <mat-card-content class="p-3">
            <form [formGroup]="form" (ngSubmit)="openConfirm()">
              <div class="m-2">
                  <mat-form-field class="full-width">
                    <mat-icon matPrefix class="m-1 color-purple">vpn_key</mat-icon>
                    <input matInput formControlName="temporalPassword" placeholder="Código vía correo electrónico" value="">
                  </mat-form-field>
                  <small *ngIf="form.controls['temporalPassword'].hasError('required') && form.controls['temporalPassword'].touched" 
                    class="form-error-msg"> 
                      * Requerido
                  </small>
                </div>

              <div class="m-2">
                <mat-form-field class="full-width">
                  <mat-icon matPrefix class="m-1 color-purple">vpn_key</mat-icon>
                  <input matInput formControlName="newPassword" placeholder="Nueva contraseña" value="" type="password" >
                </mat-form-field>
                <small *ngIf="form.controls['newPassword'].hasError('required') && form.controls['newPassword'].touched" 
                  class="form-error-msg"> 
                    * Requerido
                </small>
              </div>

              <div class="m-2">
                  <mat-form-field class="full-width">
                    <mat-icon matPrefix class="m-1 color-purple">vpn_key</mat-icon>
                    <input matInput formControlName="confirmPassword" placeholder="Confirmar contraseña" value="" type="password" >
                  </mat-form-field>
                  <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> 
                      * Requerido
                  </small>
                  <small *ngIf="form.errors?.mismatch && (form.controls['confirmPassword'].dirty || form.controls['confirmPassword'].touched)" 
                    class="form-error-msg"> 
                    * Las contraseñas no coinciden
                </small>
                </div>

              <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">Cambiar contraseña</button>
              <div class="text-center">
                <a [routerLink]="'/signin'" class="m-1 hyper mat-primary text-center full-width">Cancelar</a>
                <span fxFlex></span>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    -->
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>