import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-1">{{ data.message }}</div>
    <div mat-dialog-actions align="end">
    <button
    type="button"
    class="btn-danger"
    mat-raised-button
    (click)="dialogRef.close(false)">Cancelar</button>
    &nbsp;
    <button
    type="button"
    mat-raised-button
    class="btn-purple"
    (click)="dialogRef.close(true)">Confirmar</button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
