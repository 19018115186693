import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AdminService } from './views/services/admin.service';
import { StorageService } from './views/services/storage.service';
import { Notification } from './views/shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ComunicationService {
  private notifications$ = new Subject<Notification[]>();
  notifications:Notification[] = [];
  constructor(
    private service:AdminService,
    private storage:StorageService
  ) {   
  }

  async setNotifications(){
    let userInfo = this.storage.getUserInfo();
    if (userInfo){
      this.service.getNotficationXUserId().subscribe(
        async (data:any) => {
          if (data){
            switch(data.code){
              case 200:
                //console.log(data.content);
                this.notifications= await data.content;
                this.notifications$.next(this.notifications);
                break;
            }
          }
        },
        (http: HttpErrorResponse) => {
          //console.log(http);
          switch (http.status){
            case 401:
              //console.log("Operación no permitida");
              break;
            case 500:
              //console.log(http);
              break;
          }
        }
      );
    }
  }
  clearNotificacion(){
    this.service.clearNotification().subscribe(
      async (data:any) => {
        
        switch(data.code){
          case 200:
            this.notifications= await data.content;
            this.notifications$.next(this.notifications);
            break;
        }
      },
      (http: HttpErrorResponse) => {
        this.service.openDialog("Error en el Servicio1204");
      }
    );
   
  }
  deleteNotificacion(id){
    this.service.deleteNotification(id).subscribe(
      async (data:any) => {        
        switch(data.code){
          case 200:
            this.notifications= await data.content;
            this.notifications$.next(this.notifications);
            break;
        }
      },
      (http: HttpErrorResponse) => {
        console.log(http);
        this.service.openDialog("Error en el Servicio1204");
      }
    );
   
  }
  getNotifications(): Observable<Notification[]> {
    return this.notifications$.asObservable();
  }
}
