<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-base text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content style="max-width: 780px;">
  <hr>
  <div class="f-18 text-light" [innerHTML]="sanitizeText(message)"></div>
  <hr>
</div>
<div fxLayout="row" fxLayoutAlign="center center" mat-dialog-actions>
  <button class="btn-primary" mat-button [mat-dialog-close]="true">
    Aceptar
    <mat-icon class="m-1">check_circle_outline</mat-icon>
  </button>
</div>
