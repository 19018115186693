import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { API } from '../../shared/api';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends BaseService implements OnInit {


  public userEmail;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  public form: FormGroup;
  public email:any = "";


  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    this.form = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
      ]),
      temporalPassword: new FormControl('', [
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.required,
      ])
    },{validators: this.passwordMatchValidator});

    let emailTemp = localStorage.getItem('emailToReset');
    this.email = emailTemp ? emailTemp : '';
    this.form.get('email').setValue(this.email);
  }

  reset() {
    this.loader.open();
    let data:any = this.form.value;
    this.service.reset(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(body.message).subscribe(
          () => {
            if (body.success) this.navigateTo('/signin');
          }
        );
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['newPassword'].value === frm.controls['confirmPassword'].value ? null : {'mismatch': true};
  }

  send() {
    this.openConfirm(`¿Seguro(a) que desea restablecer la contraseña?`)
    .subscribe(
      (response) => {
        if (response) {
          this.reset();
        }
      }
    );
  }

}
