import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-buy-course',
  templateUrl: './dialog-buy-course.component.html',
  styleUrls: ['./dialog-buy-course.component.css']
})
export class DialogBuyCourseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
