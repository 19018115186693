<div class="header-topnav text-light" style="padding-bottom: 4px;" fxFlex.sm="100"  fxFlex.xs="100">
  <header class="main-header main-header--one  clearfix">
    <div class="main-header--one__top clearfix">
        <div class="container">
            <div class="main-header--one__top-inner clearfix">
                <div class="main-header--one__top-left">
                    <div class="main-header--one__top-logo">
                        <a href="index.html"><img src="assets/new/images/resources/logo-1.png" alt="" /></a>
                    </div>
                </div>

                <div class="main-header--one__top-right clearfix">
                    <ul class="main-header--one__top-social-link list-unstyled clearfix">
                        <li><a href="https://www.instagram.com/sayucfox/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/@sayucfox" target="_blank"><i class="fab fa-youtube"></i></a></li>

                    </ul>

                    <div class="main-header--one__top-contact-info clearfix">
                        <ul class="main-header--one__top-contact-info-list list-unstyled">
                            <li class="main-header--one__top-contact-info-list-item">
                                <div class="icon" style="width:50px;">
                                    <img src="../../../../../assets/new/images/shapes/discord.svg" alt="">
                                    
                                </div>
                                <div class="text">
                                    <h6>Contáctanos</h6>
                                    <p><a href="https://discord.gg/TUdhuAX9gJ">Únete al DISCORD</a></p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="main-header-one__bottom clearfix">
        <div class="container">
            <div class="main-header-one__bottom-inner clearfix">
                <nav class="main-menu main-menu--1">
                    <div class="main-menu__inner">
                        <div fxFlex="100" fxLayoutAlign="space-between center" style="z-index: 9999;">
                            <div fxFlex="100" fxLayoutAlign="start center">
                              <a class="mobile-nav__toggler" (click)="showMenu = !showMenu" style="margin-left: 20px;">
                                <i class="fa fa-bars"></i>
                              </a>
                            </div>
                        </div>                            
                        <div class="left">
                            <ul class="main-menu__list">
                                <li><a [ngClass]="getActiveRouteClass('/home')" (click)="goTo('/home')">Inicio</a></li>
                                <li><a [ngClass]="getActiveRouteClass('/courses')" (click)="goTo('/courses')">Assets</a></li>
                                <li><a [ngClass]="getActiveRouteClass('/about')" (click)="goTo('/about')">¿Quiénes somos?</a></li>
                                <li><a [ngClass]="getActiveRouteClass('/contactus')" (click)="goTo('/contactus')">Contáctanos</a></li>
                                <li><a [ngClass]="getActiveRouteClass('/terms')" (click)="goTo('/terms')">Términos</a></li>
                                <li><a [ngClass]="getActiveRouteClass('/privacy')" (click)="goTo('/privacy')">Privacidad</a></li>
                            </ul>
                        </div>
                        <div class="right">
                            <div class="main-menu__right">
                                <div class="main-menu__right-login-register">
                                    <ul class="list-unstyled">
                                        <li><a [ngClass]="getActiveRouteClass('/signin')" [routerLink]="['/signin']">Iniciar sesión</a></li>
                                        <li><a [ngClass]="getActiveRouteClass('/signup')" [routerLink]="['/signup']">Regístrate</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
  </header>
</div>
<div 
    *ngIf="showMenu" 
    [@animate]="{value:'*',params:{delay: '100ms', x:'-250px'}}" class="sidebar-panel" style="margin-top: 190px;background-color: var(--thm-black);"
    >
    <app-sidenav style="color: white !important;" [items]="menuItems"></app-sidenav>  
</div>