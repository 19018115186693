import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthGuard } from 'app/shared/services/auth/auth.guard';
import { Subscription } from 'rxjs';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService } from 'app/views/services/base.service';
import { API } from 'app/views/shared/api';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
  animations: [egretAnimations]
})
export class SigninComponent extends BaseService implements OnInit,AfterViewInit {
  layoutConf: any;
  menuItems:any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  @Input() notificPanel;
  //
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  disableButtonLogin:boolean = false;
  form: FormGroup;
  //social attributes
  loggedIn:boolean = false;
  hide = true;

  constructor(
    private authGuard: AuthGuard,
    injector:Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });
    this.IsRememberAccount();
  }

  ngAfterViewInit(){
    
  }

  signin() {
    //this.submitButton.disabled = true;
    this.disableButtonLogin = true;
    this.progressBar.mode = 'indeterminate';
    const data = this.form.value;
    this.rememberAccount();
    this.service.login(data).subscribe(
      (response) => {
        this.progressBar.mode = 'determinate';
        let data = response.body.data;
        this.storage.clearUserInfo();
        this.storage.setUserInfo(data);
        this.router.navigate(['/dashboard/home']).then(
          () => {window.location.reload();}
        );
      },
      (http: HttpErrorResponse) => {
        console.log(http);
        this.progressBar.mode = 'determinate';
        this.proccessHttpResponse(http);
        this.disableButtonLogin = false;
        this.form.get('password').reset('');
      }
    );
  }

  rememberAccount(){
    const signinData = this.form.value;
    localStorage.removeItem('rememberMe');
    if(signinData.rememberMe){
      localStorage.setItem('rememberMe', JSON.stringify({email: signinData.email, password: signinData.password}));
    }
  }

  IsRememberAccount(){
    let account = JSON.parse(localStorage.getItem('rememberMe'));
    if(account && account.hasOwnProperty('email') && account.hasOwnProperty('password')){
      this.form.controls.rememberMe.setValue(true);
      this.form.controls.email.setValue(account.email);
      this.form.controls.password.setValue(account.password);
    }
  }

  ngOnDestroy() {
    if (this.menuItemSub != undefined)
      this.menuItemSub.unsubscribe();
  }
  
  changeTheme(theme) {
    this.layout.publishLayoutChange({matTheme: theme.name})
  }
  
  toggleNotific() {
    this.notificPanel.toggle();
  }
  
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  search(){
    
  }

  progressBarOn(): void {
    if (this.submitButton && this.progressBar){
      this.submitButton.disabled = true;
      this.progressBar.mode = 'indeterminate';
    }
  }

  progressBarOff(): void {
    if (this.submitButton && this.progressBar){
      this.submitButton.disabled = false;
      this.progressBar.mode = 'determinate';
    }
  }

}
