import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogTopicComponent } from 'app/shared/components/dialog-topic/dialog-topic.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { DialogOrderTopicResourcesComponent } from 'app/views/dialogs/dialog-order-topic-resources/dialog-order-topic-resources.component';
import { DialogOrderTopicsComponent } from 'app/views/dialogs/dialog-order-topics/dialog-order-topics.component';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-admin-topics',
  templateUrl: './admin-topics.component.html',
  styleUrls: ['./admin-topics.component.css']
})
export class AdminTopicsComponent extends BaseService implements OnInit {
  
  row:any;
  moduloId:number;
  courseId:number;
  idTopic:string;
  displayedColumns: string[] = [ 'topic','description','activeLabel','Borrar','Editar','order'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    this.moduloId =0; 
    this.courseId=0;
    this.activatedRoute.paramMap.subscribe(params => {
      this.moduloId = Number(params.get('id'));
      this.courseId = Number(params.get('courseId'));
      if (this.moduloId) {
        this.getTopics(this.moduloId);
      } else {
        this.openDialog('No fue posible obtener la información del módulo.');
        this.router.navigateByUrl('/dashboard/cmsCourses');
      }
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
    //console.log(this.dataSource.data);
  }

  getTopics(moduloId:number){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getModuleTopics(moduloId).subscribe(
      (response) => {
        loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.dataSource.data = body.data;
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }
  
  onClickNuevo() {
    let item:any = {
      id : 0,
      topic : '',
      description : '',
      active : 1,
      moduloId : this.moduloId,
      Resource:[],
      moduleId : this.moduloId,
    }
    this.row = item;
    this.openDialogTopic();
  }

  saveTopic(dataSave){
    this.loader.open();
    this.service.createTopic(dataSave).subscribe(
    async (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        const files:any= dataSave.files as [];
        if(files.length>0){
          this.loader.open();
          this.idTopic =  body.data.id;
          for(var index=(files.length-1);index>=0;index--){
            const file = files[index];
              let formData = new FormData();
              formData.append('id',this.idTopic);
              formData.append('titulo',file.titulo);
              formData.append('file',file.file as File);
              let result = await this.saveTopicResource(formData,index,"guardado");
            files.splice(index, 1); 
          }
         
        }
        else{
          if (!body.success) {
            this.openDialog(body.message);
            return;
          }
          this.getTopics(this.moduloId);
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  onClickEdit(item:any){
    this.row =item;
    this.openDialogTopic();
  }

  public openDialogTopic() {
    this.dialog.open(DialogTopicComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe(async (data: any)=> {
        if (data != null) {
          if(this.row.id==0){
            await this.saveTopic(data);
          }else{
            await this.updateTopic(data);
          }
        }
      });
  }

  async updateTopic(dataSave){
    this.loader.open();
    await this.service.updateTopic(dataSave).subscribe(
      async (response)  => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        const files:any= await dataSave.files as [];
        // filtramos el array
        const arrayFiles :any =[];
        for(var index=(files.length-1);index>=0;index--){
          const file = await files[index];
          if(file.hasOwnProperty("idTemp")){
            arrayFiles.push(file);
          }
        }
        if(arrayFiles.length>0){
          this.loader.open();
          this.idTopic = await dataSave.id;
          // procesamos array filtrado
           for(var index=(arrayFiles.length-1);index>=0;index--){
            const file = await arrayFiles[index];
            if(file.hasOwnProperty("idTemp")){
              let formData = await new FormData();
              await formData.append('id',this.idTopic);
              await formData.append('titulo',file.titulo);
              await formData.append('file',file.file as File);
              await this.saveTopicResource(formData,index,'actualizado');
            }
          }
        } else {
          if (!body.success) {
            this.openDialog(body.message);
            return;
          }
          this.getTopics(this.moduloId);
        } 
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  async saveTopicResource(formData,index,mensaje){  
    this.service.saveResourseTopic(formData).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        if (index <= 0) {
          if (!body.success) {
            this.openDialog(body.message);
            return;
          }
          this.getTopics(this.moduloId);
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      },
    );
  }

  deleteTopic(data){
    this.row = data;
    this.loader.open('Eliminando Tema ...');
    this.service.deleteModule(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Tema eliminado correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  goToCatModule(){
    this.router.navigate(['/dashboard/cmsModule',this.courseId]);
  }

  onClickDelete(item:any){
    this.row =item;
    this.openDialogConfirm("Eliminar","¿Desea eliminar tema?",true);
  }

  public openDialogConfirm(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteTopic(this.row);
        } 
      });
  }

  dialogCongfirm(message:string){
    this.openConfirm(message)
    .subscribe(
      (response) => {
        this.getTopics(this.moduloId);
      }
    );
  }

  openDialogOrderTopics() {
    let topics:any[] = [];
    topics = this.dataSource.data;
    if (topics.length <= 0) {
      this.openDialog(`No hay temas para ordenar`).subscribe(
        () => {this.getTopics(this.moduloId);}
      )
    } else {
      this.dialog.open(DialogOrderTopicsComponent,{
        data:{
          topics:topics
        },autoFocus:false
      }).afterClosed().subscribe(
        (response) => {
          if (response) {
            this.updateOrderTopics(response);
          }
        }
      );
    }
  }

  updateOrderTopics(orderedTopics:any) {
    let data = {
      orderedTopics:orderedTopics
    };
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.updateOrderTopics(data).subscribe(
      (response:any) => {
        loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getTopics(this.moduloId);
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  openDialogOrderResources(topic:any) {
    let resources:any[] = [];
    resources = topic.Resource;
    resources = this.service.sortByOrder(resources);
    if (resources.length <= 0) {
      this.openDialog(`No hay recursos del tema para ordenar`).subscribe(
        () => {this.getTopics(this.moduloId);}
      )
    } else {
      this.dialog.open(DialogOrderTopicResourcesComponent,{
        data:{
          resources:resources
        },autoFocus:false
      }).afterClosed().subscribe(
        (response) => {
          if (response) {
            this.updateOrderTopicResources(response);
          }
        }
      );
    }
  }

  updateOrderTopicResources(orderedResources:any) {
    let data = {
      orderedResources:orderedResources
    };
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.updateOrderTopicResources(data).subscribe(
      (response) => {
        loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getTopics(this.moduloId);
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

}
