import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ActivatedRoute } from '@angular/router';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { CustomValidators } from 'ngx-custom-validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [egretAnimations]
})
export class SignupComponent extends BaseService implements OnInit {

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  form: FormGroup;
  hide = true;
  hideConfirm = true;

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.createForm();
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params) {
          if (
            params.hasOwnProperty('name') &&
            params.hasOwnProperty('lastname') &&
            params.hasOwnProperty('email') 
          ) {
            this.form.controls['name'].setValue(params.name);
            this.form.controls['lastName'].setValue(params.lastname);
            this.form.controls['email'].setValue(params.email);
          }
        }
      }
    )
  }
  
  public matchEmail:boolean = true;

  createForm(){
    let regexPswd:RegExp = /^(?!.* )(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,20}$/;
    const regex = "[a-zA-Z-ñÑáéíóúÁÉÍÓÚ ,.$&%_]*$";
    const password = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20),
      Validators.pattern(regexPswd)
    ]);
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern(regex)
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50),
        Validators.pattern(regex)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
        Validators.maxLength(90)
      ]),
      confirm_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
        Validators.maxLength(90)
      ]),
      password: password,
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        CustomValidators.equalTo(password),
        Validators.pattern(regexPswd)
      ]),
    });
  }

  showConfirmDialog(){
    this.openConfirm(`¿Seguro(a) que desea crear su cuenta?`).subscribe((response) => {
      if (response == true){
        this.signup();
      } else {
        this.service.openSnackBar('No se creó la cuenta');
      }
    });
  }

  signup(){
    if (this.form.valid){
      this.loader.open();
      const loader = this.loader.getDialog();
      this.service.signup(this.form.value).subscribe(
        (response) => {
          loader.close();
          let body:IResponse = response.body;
          this.openDialog(body.message).subscribe(
            () => {
              if (body.success) this.navigateTo('/signin');
            }
          );
        },
        (http: HttpErrorResponse) => {
          loader.close();
          this.proccessHttpResponse(http);
        }
      );
    } else {
      this.openDialog('Complete los campos correctamente');
    }
  }

  // Funcion para confirmacion de correo
  public onEmailChange(){
    if (this.form.get('email').value != this.form.get('confirm_email').value){
      this.matchEmail = false;
    } else {
      this.matchEmail = true;
    }
  }

}
