<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirm()">
<div mat-dialog-content style="overflow-x: clip;">
  <div class="w-100" fxFlex="100" fxLayout="column" fxLayoutGap="1%">
    <!---->
    <div class="w-100" fxFlex="100" fxLayout="column" fxLayoutGap="1%">
      <div fxFlex="100">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Tipo Promoción</mat-label>
          <mat-select formControlName="promotionTypeId" (selectionChange)="setPromotionTypeId($event.value)">
            <mat-option *ngFor="let tp of tipoPromocines" [value]="tp.id">
              {{ tp.promotion_type}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small *ngIf="form.controls['promotionTypeId'].hasError('required') && form.controls['promotionTypeId'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
    </div>
    <!---->
    <div fxFlex="100" fxLayout="column" fxLayoutGap="1%">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Nombre de la promoción</mat-label>
        <input matInput placeholder="Promoción" formControlName="promotion">
      </mat-form-field>
      <small *ngIf="form.controls['promotion'].hasError('required') && form.controls['promotion'].touched" class="form-error-msg">
        * Requerido.
      </small>
      <small *ngIf="form.controls['promotion'].hasError('maxlength') && form.controls['promotion'].touched" class="form-error-msg">
        * Máximo 100 caracteres.
      </small>
    </div>
    <!---->
    <div fxFlex="100" fxLayout="column" fxLayoutGap="1%">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Descripción</mat-label>
        <input matInput placeholder="Descripción" formControlName="description">
      </mat-form-field>
      <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
        * Requerido.
      </small>
      <small *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched" class="form-error-msg">
        * Máximo 2000 caracteres.
      </small>
    </div>      
    <!---->
    
    <div fxFlex="100" [style.display]="promotionTypeId == 1 ? 'flex' : 'none'">
      <mat-checkbox [(ngModel)]="applyAll" (ngModelChange)="setApplyAll($event)" formControlName="applyAll">Aplica a todos los cursos</mat-checkbox>
    </div>
    <div fxFlex="100" [style.display]="promotionTypeId == 1 ? 'flex' : 'none'">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Elegir cursos para aplicar promoción:</mat-label>
        <mat-select [formControl]="coursesSelect" multiple (selectionChange)="setCourses($event.value)">
          <mat-option *ngFor="let item of courses" [value]="item.id">$&nbsp;{{item.salePrice}} - {{item.course}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!---->
    <div class="w-100" fxFlex="100" fxLayout="row" fxLayoutGap="1%">
      <div fxFlex="50">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Vigencia</mat-label>
          <input matInput [matDatetimepicker]="dts" (click)="dts.open()" formControlName="expiresAt">
          <mat-datetimepicker-toggle [for]="dts" matSuffix></mat-datetimepicker-toggle>
          <mat-datetimepicker #dts type="datetime" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
        </mat-form-field>
        <small *ngIf="form.controls['expiresAt'].hasError('required') && form.controls['expiresAt'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
      <!---->
      <div fxFlex="50">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Monto:</mat-label>
          <mat-icon matPrefix>attach_money</mat-icon>
          <input (input)="verifyAmount()" matInput placeholder="300.00" formControlName="amount" type="number">
        </mat-form-field>
        <small *ngIf="form.controls['amount'].hasError('required') && form.controls['amount'].touched" class="form-error-msg">
          * Requerido.
        </small>
        <small *ngIf="form.controls['amount'].hasError('pattern') && form.controls['amount'].touched" class="form-error-msg">
          * Solo números.
        </small>
      </div>
    </div>
    <!---->
    <div class="w-100" fxFlex="100" fxLayout="row" fxLayoutGap="1%">
      <div flex="50">
        <mat-checkbox style="display:none;" class="example-margin" formControlName="active">Activo</mat-checkbox>
      </div>
      <div flex="50"><small class="text-purple">{{amountMessage}}</small></div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" class="btn-purple" (click)="cerrarDialogo()">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
  <button mat-button type="submit" class="btn-green" [disabled]="!form.valid">
    <mat-label class="m-1">Confirmar</mat-label>
    <mat-icon aria-hidden="false">check_circle_outline</mat-icon>
  </button>
</div>
</form>
  