import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { DialogCourseComponent } from '../dialog-course/dialog-course.component';
import { Module } from "app/views/shared/interfaces";
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
@Component({
  selector: 'app-dialog-module',
  templateUrl: './dialog-module.component.html',
  styleUrls: ['./dialog-module.component.css']
})
export class DialogModuleComponent implements OnInit {
  public form: FormGroup;
  title:string;
  constructor(public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Module,
    private loader:AppLoaderService,
    private service: AdminService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    if(this.data.id==0){
      this.title = "Alta Módulo"
    }else{
      this.title="Editar Módulo";
    }
    this.form = new FormGroup({
      module: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      description: new FormControl('', [
        Validators.required
      ]),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ])

    });
    
    this.form.controls.module.setValue(this.data.module);
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
  }
  cerrarDialogo(): void {
    this.dialogo.close(null);
  }
  confirmado(): void {
    var formulario = this.form.value;
    let dataSave ={
      id:this.data.id,
      courseId :this.data.courseId,
      module : formulario.module,
      description : formulario.description,
      active : formulario.IsCheckedActivo?1:0
    }
    //
    let message:string =  (this.data.id)?`actualizar el modulo`:`crear el modulo`; 
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          this.dialogo.close(dataSave);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    ); 
  }
}
