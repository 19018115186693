import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-sessionemp',
  templateUrl: './dialog-sessionemp.component.html',
  styleUrls: ['./dialog-sessionemp.component.css']
})
export class DialogSessionempComponent implements OnInit {
  public title:string;
  public message:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  ngOnInit(): void {
    
    this.message = this.data.message;
  }

}
