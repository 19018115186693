<div class="header-topnav text-light" style="padding-bottom: 4px;" fxFlex.sm="100"  fxFlex.xs="100">
<header class="main-header main-header--one  clearfix">
  <div class="main-header--one__top clearfix">
      <div class="container">
          <div class="main-header--one__top-inner clearfix">
              <div class="main-header--one__top-left">
                  <div class="main-header--one__top-logo">
                      <a href="index.html"><img src="assets/new/images/resources/logo-1.png" alt="" /></a>
                  </div>
              </div>

              <div class="main-header--one__top-right clearfix">
                <ul class="main-header--one__top-social-link list-unstyled clearfix">
                  <li><a href="https://www.instagram.com/sayucfox/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                  <li><a href="https://www.youtube.com/@sayucfox" target="_blank"><i class="fab fa-youtube"></i></a></li>

              </ul>

                  <div class="main-header--one__top-contact-info clearfix">
                      <ul class="main-header--one__top-contact-info-list list-unstyled">
                        <li class="main-header--one__top-contact-info-list-item">
                          <div class="icon" style="width:50px;">
                              <img src="../../../../../assets/new/images/shapes/discord.svg" alt="">
                              
                          </div>
                          <div class="text">
                              <h6>Contáctanos</h6>
                              <p><a href="https://discord.gg/TUdhuAX9gJ">Únete al DISCORD</a></p>
                          </div>
                      </li>
                      </ul>
                  </div>

              </div>
          </div>
      </div>
  </div>

  <div class="main-header-one__bottom clearfix">
    <div class="container">
      <div class="main-header-one__bottom-inner clearfix">
          <nav class="main-menu main-menu--1">
              <div class="main-menu__inner">
                  <!--<a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>-->
                  <div fxFlex="100" fxLayoutAlign="space-between center" *ngIf="layoutConf.isMobile && user">
                    <div fxFlex="60" fxLayoutAlign="start center">
                      <a class="mobile-nav__toggler" (click)="toggleSidenav()" style="margin-left: 20px;">
                        <i class="fa fa-bars"></i>
                      </a>
                    </div>
                    <div fxFlex="40" fxLayoutAlign="end center">
                      <button *ngIf="issetUserSession()" id="notification" mat-icon-button matTooltip="Notifications" color="purple" (click)="toggleNotific()"
                        [style.overflow]="'visible'" class="topbar-button-right">
                        <mat-icon>notifications</mat-icon>
                        <span class="notification-number mat-bg-warn">{{countNotification}}</span>
                      </button>
                      <button *ngIf="issetUserSession()" id="profile" style="width: auto; border-radius: 10px !important;color: var(--thm-gray);"
                        mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button">
                        <img [src]="imagenProfile" onError="this.src='./assets/images/perfil-usuario.png'" alt=""
                          class="bg-light img-thumbnail">
                        <small fxHide.xs fxHide.sm class="m-1">{{userFullName}}</small>
                        <span fxHide fxShow.xs fxShow.sm style="margin-right: 15px;"></span>
                        <span fxShow fxHide.xs fxHide.sm style="margin-right: 50px;"></span>
                      </button>
                    </div>
                    
                  </div>
                  
                  <div class="left">
                    <ul class="main-menu__list" style="color: var(--thm-gray);">
                      <li *ngFor="let item of menuItems; let i = index;" [attr.id]="item.name">
                        <div class="text-center" *ngIf="item.type !== 'separator'" routerLinkActive="open">
                          <a matRipple [ngClass]="getActiveRouteClass('/'+item.state)" (click)="goTo('/'+item.state)" *ngIf="item.type === 'link'">
                            <mat-icon *ngIf="item.icon != '' && item.icon != undefined">{{item.icon}}</mat-icon> 
                            {{item.name | translate}}
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="right" style="margin-right: 10px;">  
                    <ul class="main-menu__list">
                        <!--<li><a [routerLink]="['/signin']">Iniciar sesión</a></li>
                        <li><a [routerLink]="['/signup']">Regístrate</a></li>-->
                        <!---->
                        <button *ngIf="issetUserSession()" id="profile" style="width: auto; border-radius: 10px !important;color: var(--thm-gray);"
                          mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right mr-1 img-button">
                          <img [src]="imagenProfile" onError="this.src='./assets/images/perfil-usuario.png'" alt=""
                            class="bg-light img-thumbnail">
                          <small fxHide.xs fxHide.sm class="m-1">{{userFullName}}</small>
                          <span fxHide fxShow.xs fxShow.sm style="margin-right: 15px;"></span>
                          <span fxShow fxHide.xs fxHide.sm style="margin-right: 50px;"></span>
                        </button>
                        <mat-menu #accountMenu="matMenu">
                          <button *ngIf="(role.isClient() || role.isInstructor()) && userLevelId" mat-menu-item>
                            <span>Nivel actual: <b>"{{ getUserLevel() }}"</b></span>
                          </button>
                          <button *ngIf="role.isClient() || role.isInstructor()" mat-menu-item [routerLink]="['/dashboard/profile']">
                            <mat-icon>account_box</mat-icon>
                            <span>Perfil</span>
                          </button>
                          
                          <button mat-menu-item (click)="logout();">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Cerrar Sesión</span>
                          </button>
                        </mat-menu>
                        <button id="notification" mat-icon-button color="purple" matTooltip="Notifications" (click)="toggleNotific()"
                          [style.overflow]="'visible'">
                          <mat-icon>notifications</mat-icon>
                          <span class="notification-number mat-bg-warn">{{countNotification}}</span>
                        </button>
                        <button mat-icon-button class="mr-1" (click)="toggleSidenav()" *ngIf="layoutConf.isMobile && user">
                          <mat-icon>menu</mat-icon>
                        </button>
                    </ul>
                  </div>

              </div>
          </nav>

      </div>
    </div>
  </div>
</header>
</div>