<div class="container-fluid">
  
  <!---->
  <div fxLayout="row wrap">
    <!---->
    <div 
      fxFlex="100"
      fxFlex.gt-sm="100"
      fxFlex.sm="100"
      [@animate]="{ value: '*', params: { y: '50px', delay: '100ms' } }">
      <h3 class="ml-1" [ngClass.xs]="'text-center'">{{user?.name}}&nbsp;{{user?.lastName}}</h3>
      <hr/>
    </div>
    <!--/-->

    <!---->
    <div
      fxFlex="100"
      fxFlex.gt-sm="20"
      fxFlex.sm="50"
      [@animate]="{ value: '*', params: { y: '50px', delay: '300ms' } }"
    >
      <mat-card class="text-center p-3 mat-elevation-z4" style="padding-left: 0 !important; padding-right: 0 !important;">
        <img [src]="imageUrl" class="img-circle h-60 w-60 img-thumbnail">
        <br/>
        <!---->
        <button [routerLink]="['/dashboard/profile']" mat-button class="m-1">Editar perfil</button>
        <!---->
        <div *ngIf="role.isClient() && user.hasOwnProperty('totalLeekels')" class="bg-light-gray text-center w-100p p-3">
          <span class="text-bold">Leekels Totales</span>
        </div>
        <div *ngIf="role.isClient() && user.hasOwnProperty('totalLeekels')"  class="row m-1">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" style="vertical-align: middle !important;">
            <img style="border-radius: 2px;" class="m-1" src="assets/images/game/leekels_symbol_purple.png"><span style="font-size: 18px;">{{user?.totalLeekels}}</span>
          </div>
        </div>
        <div *ngIf="role.isClient()" class="bg-light-gray text-center w-100p p-3">
          <span class="text-bold">4 C's para emprender</span>
        </div>
        <div class="row m-1" *ngIf="role.isClient()" >
          <div class="col col-sm-6 text-left">
            <span class="m-3 text-13">
              Compromiso
            </span>
          </div>
          <div class="col col-sm-6">
            <mat-progress-bar
              color="green"
              mode="determinate"
              [value]="user.committal"
              class="rounded m-1"
              style="height: 6px; display: inline-block; width: 60%; vertical-align: middle;"
            >
            </mat-progress-bar>
            <span class="text-bold">{{user.committal}}%</span>
          </div>
        </div>
        <div class="row m-1" *ngIf="role.isClient()" >
          <div class="col col-sm-6 text-left">
            <span class="m-3 text-13">
              Competencia
            </span>
          </div>
          <div class="col col-sm-6">
            <mat-progress-bar
              color="primary"
              mode="determinate"
              [value]="user.competency"
              class="rounded m-1"
              style="height: 6px; display: inline-block; width: 60%; vertical-align: middle;"
            >
            </mat-progress-bar>
            <span class="text-bold">{{user.competency}}%</span>
          </div>
        </div>
        <div class="row m-1" *ngIf="role.isClient()" >
          <div class="col col-sm-6 text-left">
            <span class="m-3 text-13">
              Carácter
            </span>
          </div>
          <div class="col col-sm-6">
            <mat-progress-bar
              color="accent"
              mode="determinate"
              [value]="user.character"
              class="rounded m-1"
              style="height: 6px; display: inline-block; width: 60%; vertical-align: middle;"
            >
            </mat-progress-bar>
            <span class="text-bold">{{user.character}}%</span>
          </div>
        </div>
        <div class="row m-1" *ngIf="role.isClient()" >
          <div class="col col-sm-6 text-left">
            <span class="m-3 text-13">
              Contribución
            </span>
          </div>
          <div class="col col-sm-6">
            <mat-progress-bar
              color="warn"
              mode="determinate"
              [value]="user.contribution"
              class="rounded m-1"
              style="height: 6px; display: inline-block; width: 60%; vertical-align: middle;"
            >
            </mat-progress-bar>
            <span class="text-bold">{{user.contribution}}%</span>
          </div>
        </div>
        
      </mat-card>
      
    </div>
    <!--/-->

    <!---->
    <div
      fxFlex="100"
      fxFlex.gt-sm="80"
      fxFlex.sm="50"
      [@animate]="{ value: '*', params: { y: '50px', delay: '600ms' } }"
    >
      <div fxLayout="row wrap">
        <div 
          *ngIf="role.isClient()"
          fxFlex="100"
          fxFlex.gt-sm="50">
          <mat-card class="p-3 mat-elevation-z4">
            <div fxLayout="row wrap">
              <div fxFlex="40" fxLayoutAlign="center center" class="p-1">
                <button mat-mini-fab color="primary" focusable="false">
									<mat-icon>class</mat-icon>
								</button>
              </div>
              <div fxFlex="60" fxLayout="column" fxLayoutAlign="center center">
                <mat-label class="text-secondary">Cursos Adquiridos</mat-label>
                <h4>{{purchasesCount}}</h4>
              </div>
            </div>
          </mat-card>
        </div>
        <!---->
        <div 
          fxFlex="100"
          [fxFlex.gt-sm]="(role.isClient())?50:100">
          <mat-card class="p-3 mat-elevation-z4">
            <div fxLayout="row wrap">
              <div fxFlex="40" fxLayoutAlign="center center" class="p-1">
                <button mat-mini-fab color="primary" focusable="false">
									<mat-icon>rule</mat-icon>
								</button>
              </div>
              <div fxFlex="60" fxLayout="column" fxLayoutAlign="center center">
                <mat-label class="text-secondary">Actividades Pendientes</mat-label>
                <h4>{{activitiesCount}}</h4>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <!---->
      <div>
        <mat-card 
          *ngFor="let post of posts; let i = index;"
          [@animate]="{ value: '*', params: { scale: '.9', y:'50px', delay: '600ms' } }" 
          class="p-3 mat-elevation-z4"
          fxLayout="row"
          fxLayout.sm="row"
          fxLayout.md="row"
          fxLayout.xs="row"
          >
          <div
            fxFlex="100">
            <div class="text-right" fxFlex="10" fxLayoutAlign="end center">
              <img [src]="getUserImage(post?.chatUserImageUrl)" class="img-circle h-60 w-60 m-2 img-thumbnail">
            </div>
            <div class="p-1" fxFlex="90">
              <span class="text-bold">{{post?.name}}</span>&nbsp;<span class="text-secondary">{{dateService.parseToDiagonalFormat(post?.createdAt)}}</span>
              <p style="overflow-wrap: break-word;text-align: justify;">
                {{post?.message}}
              </p>
              <img 
                class="rounded" 
                [src]="post?.resourceUrl" 
                [style]="'max-width: 180px;height: auto;max-height:180px;'">
            </div>
          </div>
        </mat-card>
        <div class="text-center m-3"><!--  *ngIf="showLoadMore" -->
          <button mat-button (click)="loadMorePosts()">
            <mat-label>Cargar más</mat-label>
            <mat-icon class="m-1">refresh</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--/-->

  </div>
  <!--/-->

</div>
<hr/>