import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { DialogCourseComponent } from '../dialog-course/dialog-course.component';

@Component({
  selector: 'app-dialog-promotion-user',
  templateUrl: './dialog-promotion-user.component.html',
  styleUrls: ['./dialog-promotion-user.component.css']
})
export class DialogPromotionUserComponent implements OnInit {
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public dialogo: MatDialogRef<DialogCourseComponent>,
    private paginatorService: PaginatorService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private loader:AppLoaderService,
    private service: AdminService) { }

  ngOnInit(): void {  
    this.displayedColumns = ['selected', 'alumno', 'correo'];
    this.getCatPromotionUsers();
  }
  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }
  getCatPromotionUsers(){
    this.loader.open('Cargando Alumnos ...');
    this.service.getCatPromotionUser(this.data.id).subscribe(
      (data:any) => {
        let dataLength = Object.keys(data.body.content).length;
        if(dataLength > 0){
          switch(data.status){
            case 200:
              data.body.content.map(function(item){
                if(item.selected==0){
                  item.selected=false;
                }
                else{
                  item.selected=true;
                }
              });
              this.dataSource.data =  data.body.content;
              this.loader.close();
              break;
            default:
              this.loader.close();
              this.service.openDialog("No se encontraron datos");
              break;
          }
        }
        else{
          this.loader.close();
          this.service.openDialog("No se encontraron datos");
        }
        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }
  confirmado(): void {
    
    const list =  this.dataSource.data.filter(word => word.selected== true);

    let dataSave ={
      promotionId:this.data.id,
      list :list
    }    
    this.dialogo.close(dataSave);
  }
  cerrarDialogo(): void {
    this.dialogo.close(null);
  }
  valid():boolean{
    return this.dataSource.data.filter(word => word.selected== true).length>0;
  }
}
