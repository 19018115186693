<div fxFlex="100" fxLayout="column">
	<div fxFlex="100" fxLayoutAlign="space-between none" class="p-2">
		<!---->
		<div fxFlex="80" fxLayoutAlign="space-between center">
			<h2>
				Administración de curso
			</h2>
		</div>
		<!---->
		<button fxFlex="20" mat-flat-button class="btn-primary" (click)="onClickNuevo()" *ngIf="rolVer">
			<mat-label class="mr-1">Agregar curso</mat-label>
			<mat-icon>add_circle</mat-icon>
		</button>
  </div>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row" style="width: 100%;">
		<!---->
		<div fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
			<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
				<div class="example-container mat-elevation-z4">
					<mat-table #table [dataSource]="dataSource">
						<ng-container matColumnDef="course">
							<mat-header-cell class="m-1" *matHeaderCellDef> Curso</mat-header-cell>
							<mat-cell class="m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Curso:</span>
							  {{element.course}} 
							</mat-cell>
						  </ng-container>
							
						  <ng-container matColumnDef="name" *ngIf="rolVer">
							<mat-header-cell class="m-1" *matHeaderCellDef> Facilitador </mat-header-cell>
							<mat-cell class="m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Facilitador:</span>
							  {{element.name}} 
							</mat-cell>
						  </ng-container>
							
						  <ng-container matColumnDef="salePrice">
							<mat-header-cell *matHeaderCellDef class="centrado"> Precio </mat-header-cell>
							<mat-cell class="centrado" *matCellDef="let element"> 
							  <span class="mobile-label">Precio:</span>
							  {{element.salePrice}} 
							</mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="courseLeve">
							<mat-header-cell class="m-1" *matHeaderCellDef> Nivel Curso </mat-header-cell>
							<mat-cell class="text-justify m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Nivel Curso:</span>
							  {{element.courseLevel}} 
							</mat-cell>
						  </ng-container>
						  
						  
							<ng-container matColumnDef="activeLabel">
								<mat-header-cell *matHeaderCellDef> Activo</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Activo:</span>
									{{element.activeLabel}} 
								</mat-cell>
						  </ng-container>
						  
							<ng-container matColumnDef="Borrar" *ngIf="rolVer">
								<mat-header-cell *matHeaderCellDef>Activo</mat-header-cell>
								<mat-cell *matCellDef="let element; let i = index;"> 
									<span class="mobile-label">Activar/Desactivar</span>
									<mat-checkbox style="margin-left: 5%;" color="primary" (change)= "onClickDelete(element,$event,i)" [checked]="element.active == 1"></mat-checkbox>
								</mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="Editar" *ngIf="rolVer">
								<mat-header-cell *matHeaderCellDef> Editar</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Editar:</span>
										<button mat-mini-fab color="primary" focusable="false" (click)="onClickEdit(element)">
										<mat-icon>edit</mat-icon>
									</button>
								</mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="Modules">
								<mat-header-cell *matHeaderCellDef> Módulo</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Módulo:</span>
										<button mat-mini-fab color="primary" focusable="false" (click)="goToModule(element.id)">
										<mat-icon>source</mat-icon>
									</button>
								</mat-cell>
						  </ng-container>
							
						  <ng-container matColumnDef="Activiti">
							<mat-header-cell *matHeaderCellDef> Actividades</mat-header-cell>
							<mat-cell *matCellDef="let element"> 
							  <span class="mobile-label">Actividades:</span>
							  	<button mat-mini-fab color="primary" focusable="false" (click)="goToActivity(element.id)">
									<mat-icon>event</mat-icon>
								</button>
							</mat-cell>
						  </ng-container>

							<!--<ng-container matColumnDef="paymentMethods">
								<mat-header-cell *matHeaderCellDef> Métodos de pago</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Métodos de pago:</span>
									<button mat-mini-fab color="primary" (click)="goToPaymentMethods(element.id)">
										<mat-icon>credit_card</mat-icon>
									</button>
								</mat-cell>
							</ng-container>-->
						  
						  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
						  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
					</mat-table>
					<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>
<hr/>
