import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { ComunicationService } from 'app/comunication.service';
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;

  // Dummy notifications
  notifications:any=[];
  constructor(
    private router: Router,
    private notificacionC:ComunicationService,
    private service: AdminService
  ) {
  }

  ngOnInit() {
    this.notificacionC.getNotifications().subscribe(
      (dataNotification)=>{
        this.notifications = dataNotification;
    });
    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.notificPanel.close();
        }
    });
  }
  
  clearAll(e) {
    e.preventDefault();
    this.service.openConfirm(`¿Seguro(a) que desea borrar todas la notificaciones?`)
    .then (
      (response) => {
        if (response) {
          this.notificacionC.clearNotificacion();
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al eliminar notificaciones`).subscribe(
          () => {this.notificPanel.close();}
        );
      }
    )
  }

  deleteNotification(id){
    this.notificacionC.deleteNotificacion(id);
  }

}
