<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >

    <!---->
    <div 
      fxFlex="100"
      class="page-wrap"
      style="height: 100vh;"
      >
      <div class="session-form-hold" [@animate]="{value:'*',params:{delay: '200ms', x:'250px'}}">
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4 class="text-white;" style="color: white !important;">Inicia sesión</h4>
            </div>
            <div class="form-box">
              <!--
              <div 
                fxLayout="row" fxLayoutGap="2%" class="mb-1">
                <button 
                mat-button
                class="btn-outline-light"
                type="button"
                (click)="signInWithGoogle()"
                style="width: 100%;"
                >
                  <img src="assets/images/google24x24.png" />
                  Google
                </button>
                <button 
                  mat-button
                  class="btn-outline-light"
                  type="button"
                  (click)="signInWithFB()"
                  style="width: 100%;"
                  >
                  <img src="assets/images/facebook24x24.png" />
                  Facebook
                </button>
              </div>
              <div class="text-center mb-1">
                <span>ó</span>
                <br/>
                <mat-label>Entra con tu cuenta:</mat-label>
              </div>
              -->
              <form [formGroup]="form" (ngSubmit)="signin()">
                <div class="form-group">
                  <input type="email" matInput [formControl]="form.controls['email']" placeholder="ejemplo@dominio" value="">
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                    class="form-error-msg"> 
                      * Requerido
                  </small>
                  <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                    class="form-error-msg"> 
                      * Correo Electrónico incorrecto
                  </small>
                </div>
                <div class="form-group form-group-center">
                  <input class="placeholder" [type]="hide ? 'password' : 'text'" matInput type="password" [formControl]="form.controls['password']" placeholder="*****" value="">
                  <button type="button" mat-button matSuffix (click)="hide = !hide;" [matTooltip]="hide ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="width: auto !important; color: white;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                    class="form-error-msg"> 
                      * Requerido
                  </small>
                </div>
                <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
                <button type="submit" mat-raised-button class="btn btn-purple full-width mb-1 mt-2" [disabled]="form.invalid || disableButtonLogin">
                  Iniciar Sesión
                </button>
                <div fxLayoutAlign="center">
                  <mat-checkbox color="primary" formControlName="rememberMe">
                    <mat-label class="text-white;" style="color: white;">Recuerda mis credenciales</mat-label>
                  </mat-checkbox>
                </div>
                <div fxLayoutAlign="center">
                  <a class="hyper" [routerLink]="'/forgot'">¿Olvidaste tu Contraseña?</a>
                </div>
                <div fxLayoutAlign="center" style="color: white !important;">
                  ¿No tienes cuenta?&nbsp;<a class="hyper" [routerLink]="'/signup'">Regístrate</a>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--
        <mat-card style="padding: 0px;">
          <mat-card-title class="text-left bg-purple text-light p-2">
            <h3 style="font-size: 1.5em; text-align: center; width: 100%; padding-top:8px;">Inicio de Sesión</h3>
          </mat-card-title>
          
          <mat-card-content class="p-3">
            <div class="text-center">
              <div 
                fxLayout="row">
                <button 
                mat-button
                class="btn-outline-light m-1"
                type="button"
                (click)="signInWithGoogle()"
                style="width: 100%;"
                >
                <img src="assets/images/google24x24.png" />
                Google
              </button>
              <button 
                mat-button
                class="btn-outline-light m-1"
                type="button"
                (click)="signInWithFB()"
                style="width: 100%;"
                >
                <img src="assets/images/facebook24x24.png" />
                Facebook
              </button>
              </div>
            </div>
            
            <div class="text-center">
              <span>ó</span>
              <br/>
              <mat-label>Entra con tu cuenta:</mat-label>
            </div>

            <form [formGroup]="form" (ngSubmit)="signin()">
              <div class="m-2">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>CORREO ELECTRÓNICO:</mat-label>
                  <mat-icon matPrefix class="m-1 color-purple">email</mat-icon>
                  <input matInput [formControl]="form.controls['email']" placeholder="ejemplo@dominio" value="">
                </mat-form-field>
                <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                  class="form-error-msg"> 
                    * Requerido
                </small>
                <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                  class="form-error-msg"> 
                    * Correo Electrónico incorrecto
                </small>
              </div>
              
              <div class="m-2">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>CONTRASEÑA:</mat-label>
                  <mat-icon matPrefix class="m-1 color-purple">vpn_key</mat-icon>
                  <input [type]="hide ? 'password' : 'text'" matInput type="password" [formControl]="form.controls['password']" placeholder="*****" value="">
                  <button type="button" mat-button matSuffix (click)="hide = !hide;" [matTooltip]="hide ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="width: auto !important;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                  class="form-error-msg"> 
                    * Requerido
                </small>
              </div>
              <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
              <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid || disableButtonLogin">
                Iniciar Sesión
              </button>
              <br/><br/>
              <div fxLayoutAlign="center">
                <mat-checkbox color="primary" formControlName="rememberMe">
                  <mat-label>Recuerda mis credenciales</mat-label>
                </mat-checkbox>
              </div>
              <br/>
              <div fxLayoutAlign="center">
                <a class="hyper" [routerLink]="'/forgot'">¿Olvidaste tu Contraseña?</a>
              </div>
              <div fxLayoutAlign="center">
                ¿No tienes cuenta?&nbsp;<a class="hyper" [routerLink]="'/signup'">Regístrate</a>
              </div>
              
            </form>

          </mat-card-content>
        </mat-card>
        -->
      </div>
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>
<!--
<app-footer></app-footer>
-->