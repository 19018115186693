<p mat-dialog-title>Asignar método de pago:</p>
<mat-dialog-content>
  <mat-form-field class="full-width" appearance="outline">
    <mat-icon class="color-purple" matPrefix>credit_card</mat-icon>
    <mat-label>Elige el método de pago:</mat-label>
    <select matNativeControl (selectionChange)="setPaymentMethod($event.value)" [disabled]="true">
      <option *ngFor="let pm of paymentMethods" [selected]="paymentMethod.id === pm.id" [value]="pm" class="color-purple">
        <mat-label>{{pm.paymentMethod}}</mat-label>
      </option>
    </select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end" style="overflow-x: clip;">
  <button class="btn-purple" mat-button (click)="close()">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
  <button class="btn-green" mat-button (click)="openDialogConfirm()">
    <mat-label class="m-1">Confirmar</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>