import { Component, OnInit } from '@angular/core';
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(
    private service: AdminService
  ) { }

  isLogged:boolean = false;
  ngOnInit(): void {
    let userInfo:any = JSON.parse(localStorage.getItem('userInfo'));
    this.isLogged = (userInfo && userInfo.hasOwnProperty('access_token'))?true:false;
  }

}
