import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { DateService } from 'app/views/services/date.service';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';
import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { Profile, profileInfo } from '../users/profile';

@Component({
  selector: 'app-chat-course',
  templateUrl: './chat-course.component.html',
  styleUrls: ['./chat-course.component.css']
})
export class ChatCourseComponent implements OnInit {

  public form: FormGroup;
  posts:any = [];
  currentPage = 0;
  showLoadMore:boolean = false;
  user:any;
  courseId:number=0;
  imageUrl:string = 'assets/images/entrepeneurs/profile.png';
  activitiesCount:number = 0;
  dataProfileSubscription:Subscription;
  alumnoId:number=0;
  objetFile:any;
  rolVer:boolean;
  titulo:string = "Conecta con tus compañeros de curso:";
  public validIMagen:boolean=false;

  public uploader: FileUploader = new FileUploader({
    url: "",
    allowedFileType: ['jpg', 'png'],
    disableMultipart : false,
    autoUpload: true,
    method: 'post',
    itemAlias: 'attachment'
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: AdminService,
    private loader: AppLoaderService,
    public dateService: DateService,
    public roleService:RoleService,
    private profileInfo: Profile,
    private router: Router,
    public dialog: MatDialog,
  ) { }

  public onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
     this.form.setControl('fileName', new FormControl('')); 
     
     if(file.type=="image/png" || file.type=="image/jpeg" || file.type=="image/jpg"){
       if((file.size/1024/1024)<=2){
        this.form.controls.fileName.setValue(file.name);
        this.objetFile = file;
       }
       else{
        this.service.openDialog('El archivo no debe de pesar más de 2 MB, el archivo que intenta subir pesa: '+ (file.size/1024/1024).toFixed() +' MB.');
       }
     }
     else{
      this.service.openDialog('Se requiere archivos de tipo JPG, JPEG ó PNG.');
     }

  }

  resetFom(){
    this.form = new FormGroup({
      mensaje: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      fileName: new FormControl(''),
    });
  }

  KEY_USER_CHAT:string = 'user_chat';
  setUserChat(user:any) {
    localStorage.setItem(this.KEY_USER_CHAT,JSON.stringify(user));
  }

  getUserChat():any {
    let user:any = JSON.parse(localStorage.getItem(this.KEY_USER_CHAT));
    return user;
  }

  async ngOnInit() {
    this.resetFom();
    this.rolVer = !this.roleService.isInstructor();
    if(!this.rolVer){
      this.titulo = "Conecta con los alumnos inscritos en el curso:";
    }
    this.dataProfileSubscription = this.profileInfo.profileInfoPartner$.subscribe(
      (data:profileInfo) => {
        this.user = {... data};
        let rutaimagen = '';
        if (this.user.imagenURL){
          rutaimagen = data.imagenURL;
        }
        this.imageUrl = (rutaimagen) ? API.URL_BASE_STORAGE + rutaimagen : 'assets/images/entrepeneurs/not-found.png';
        this.user.imagenURL = this.imageUrl;
        this.setUserChat((this.user.hasOwnProperty('username') && this.user.hasOwnProperty('imagenURL'))?this.user:this.getUserChat());
    });
    this.alumnoId =0; 
    this.activatedRoute.paramMap.subscribe(async params => {
      this.alumnoId = await Number(params.get('id'));
      this.courseId = await Number(params.get('courseId'));
      //console.log(this.alumnoId,this.courseId);
      if (this.alumnoId) {
        await this.loadMorePosts();
      } else {
        this.service.openDialog('No fue posible obtener la información.');
        this.router.navigateByUrl('/dashboard/courses');
        this.restoreProfileInfo();
      }
    });
    this.user = this.getUserChat();
  }
  
  restoreProfileInfo() {
    this.profileInfo.setinfoProfile(this.service.getUserInfo());
  }
  
  getUserImage(url:string):string{
    if(url){
      return (url.includes(API.URL_BASE_STORAGE) || url.includes('http'))?url:API.URL_BASE_STORAGE+url;
    }
    return 'assets/images/entrepeneurs/not-found.png';
  }

  async loadMorePosts(){
    this.loader.open('Cargando publicaciones');
    const loader = this.loader.getDialog();
    await this.service.getChatAlumnoPosts(this.currentPage,this.alumnoId).subscribe(
      async (data) => {
        loader.close();
        if (data.status == 200){
          const content:any[] = data.body.content;
          if (content.length == 0) {
            this.service.openSnackBar('No hay más publicaciones para mostrar');
            this.showLoadMore = false;
          } else {
            content.forEach(post => {
              if (post.resourceUrl) {
                post.resourceUrl = API.URL_BASE_STORAGE+post.resourceUrl;
              }
              this.posts.push(post);
            });
            this.currentPage++;
            this.showLoadMore = true;
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.posts = [];
        this.currentPage = 0;
        this.showLoadMore = false;
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  goToCourses(){
    this.restoreProfileInfo();
    this.router.navigate(['/dashboard/cmsAlumnos/',this.courseId]);
  }

  confirmado(): void {
    this.openDialogConfirm("Enviar","¿Desea enviar mensaje?",true);
  }

  guardar():void{
    this.currentPage = 0;
    var formulario = this.form.value;
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let dataSave ={
      id:userInfo.id,
      alumnoId:this.alumnoId,
      mensaje :formulario.mensaje,
      instructor : userInfo.name+" " +userInfo.lastName,
      paginaId: this.currentPage
    };
    const formdata = new FormData();
    formdata.append("data",JSON.stringify(dataSave));
    if(this.objetFile!=null){
      formdata.append("file",this.objetFile)
    }else{
      formdata.append("file",null)
    }

    this.loader.open('Guardando Mensaje ...');
    this.service.saveChat(formdata).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Mensaje enviado correctamente");
        
        if (data.code == 200){
          const content:any[] = data.content;
          if (content.length == 0) {
            this.service.openSnackBar('No hay más publicaciones para mostrar');
            this.showLoadMore = false;
          } else {
            this.posts = [];
 
            content.forEach(post => {
              if (post.resourceUrl) {
                post.resourceUrl = API.URL_BASE_STORAGE+post.resourceUrl;
              }
              this.posts.push(post);
            });
            this.currentPage++;
            this.showLoadMore = true;
          }
        }

      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }

  public openDialogConfirm(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.guardar();
        } 
      });
  }

  openConfirm(message:string){
    return this.dialog.open(DialogConfirmComponent,{
      data:{message:message,confirm:false},
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }

  dialogCongfirm(message:string){
    this.openConfirm(message)
    .then(
      (response) => {
        if (response) {
          this.loader.close();
          this.posts = [];
          this.currentPage=0;
          this.resetFom();
          this.loadMorePosts();
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    );
  }

  public refreshChat(){
    this.posts = [];
    this.currentPage=0;
    this.loadMorePosts();
  }

}
export function requiredFileType( type: string ) {
  return function (control: FormControl) {
    const file = control.value;
    if ( file ) {
      const extension = file.name.split('.')[1].toLowerCase();
      if ( type.toLowerCase() !== extension.toLowerCase() ) {
        return {
          requiredFileType: true
        };
      }
      
      return null;
    }

    return null;
  };
}