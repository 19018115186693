import { AfterViewInit, Component, Injector, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DATA_BILLS } from 'app/views/shared/constants';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { API } from 'app/views/shared/api';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { HttpErrorResponse } from '@angular/common/http';
registerLocaleData(localeEsMx, 'es-Mx');

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Mx' } ],
})
export class BillingComponent extends BaseService implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id','courseName', 'concept', 'payment_state', 'date'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  arrayPaymentHistory:Array<any> = new Array();
  infoUser:any;

  private headers:any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'responseType': 'json',
  }

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    this.displayedColumns = ['id','concept', 'amount', 'createdAt'];
    this.dataSource.data = [];
    this.infoUser = this.service.getUserInfo();
  }

  ngAfterViewInit() {
    this.getPaymentHistory();
    if(this.dataSource.data.length > 0){
      this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
      this.dataSource.paginator = this.paginator;
      this.arrayPaymentHistory = new Array();
      this.dataSource = new MatTableDataSource<any>(this.arrayPaymentHistory);
    }
  }

  public getPaymentHistory(){
    this.loader.open();
    this.service.getPaymentHistories().subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.dataSource = new MatTableDataSource<any>(body.data);
        this.dataSource.paginator = this.paginator;
        
        this.showTutorial();
    },
    (error:HttpErrorResponse)=> {
      this.loader.close();
      this.proccessHttpResponse(error);
    });
  }

  showTutorial(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialBillingIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialBillingIsDisplayed','1');
      setTimeout(this.service.showTutorialBillingClient, 100);
    }
  }
}
