import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { CustomValidators } from 'ngx-custom-validators';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent extends BaseService implements OnInit {

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  form: FormGroup;
  hide = true;
  hideConfirm = true;

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    const password = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(20)
    ]);
    const regex = "[a-zA-Z-ñÑáéíóúÁÉÍÓÚ ,.$&%_]*$";
    const regexPhone = /^-?(0|[1-9]\d*)?$/;
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(regex)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
        Validators.maxLength(60)
      ]),
      comment: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(255),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(regexPhone)
      ]),
      subject: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(40),
      ]),
    });
  }

  send(){
    if (this.form.valid){
      this.loader.open();
      const loader = this.loader.getDialog();
      this.service.contactus(this.form.value).subscribe(
        (response) => {
          loader.close();
          let body:IResponse = response.body;
          console.log(body);
          this.openDialog(body.message).subscribe(
            () => {
              if (body.success) this.form.reset('');
            }
          );
        },
        (http: HttpErrorResponse) => {
          loader.close();
          this.proccessHttpResponse(http);
        }
      );
    } else {
      this.openDialog('Complete los campos correctamente');
    }
  }
}
