import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  isLogged:boolean = false;
  ngOnInit(): void {
    let userInfo:any = JSON.parse(localStorage.getItem('userInfo'));
    this.isLogged = (userInfo && userInfo.hasOwnProperty('access_token'))?true:false;
  }

}
