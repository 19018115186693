<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
      <mat-icon>close</mat-icon>
  </button>
</div>
<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content>
  <div fxFlex="100" fxLayout="column">
    <div fxFlex="100">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Curso</mat-label>
        <input matInput placeholder="Curso" formControlName="course">
      </mat-form-field>
      <small *ngIf="form.controls['course'].hasError('required') && form.controls['course'].touched" class="form-error-msg">
        * Requerido.
      </small>
      <small *ngIf="form.controls['course'].hasError('maxlength') && form.controls['course'].touched" class="form-error-msg">
        * Máximo 100 caracteres.
      </small>
    </div>
    <!---->
    <div flex="100">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Descripción</mat-label>
        <textarea matInput placeholder="Descripción" formControlName="description"></textarea>
      </mat-form-field>
      <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
        * Requerido.
      </small>
      <small *ngIf="form.controls['description'].hasError('maxlength') && form.controls['description'].touched" class="form-error-msg">
        * Máximo 500 caracteres.
      </small>
    </div>
    <!---->
    <div fxFlex="100" fxLayoutGap="1%">
      <div fxFlex="50">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Facilitador</mat-label>
          <mat-select formControlName="userId" [(ngModel)]="instructorId">
            <mat-option *ngFor="let instructor of instructores" [value]="instructor.id">
              {{instructor.name+ ' ' +instructor.lastname}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small *ngIf="form.controls['userId'].hasError('required') && form.controls['userId'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
      <div fxFlex="50">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nivel Curso</mat-label>
          <mat-select formControlName="nivelCursoSelected" [(ngModel)]="courseLevelId">
            <mat-option *ngFor="let nivel of niveles" [value]="nivel.id">
              {{nivel.course_level}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <small *ngIf="form.controls['nivelCursoSelected'].hasError('required') && form.controls['nivelCursoSelected'].touched" class="form-error-msg">
          * Requerido.
        </small>
      </div>
    </div>
    <!--
    <div fxFlex="100">
      <mat-checkbox class="example-margin" formControlName="IsCheckedPromo">Admitir promociones para este curso (si no admite promociones ningún alumno podrá aplicar descuentos).</mat-checkbox>
    </div>
    -->
    <div fxFlex="100">  
      <div fxFlex="50">
        <mat-form-field class="example-full-width" appearance="outline">
          <span matPrefix>$&nbsp;</span>
          <mat-label>Precio</mat-label>
          <input matInput placeholder="300.00" formControlName="salePrice" type="number">
          <span matSuffix>&nbsp;MXN</span>
        </mat-form-field>
        <small *ngIf="form.controls['salePrice'].hasError('required') && form.controls['salePrice'].touched" class="form-error-msg">
          * Requerido.
        </small>
        <small *ngIf="form.controls['salePrice'].hasError('pattern') && form.controls['salePrice'].touched" class="form-error-msg">
          * Solo número.
        </small>
      </div>
      <div fxFlex="33" fxLayoutAlign="center center">
        <mat-checkbox class="example-margin" formControlName="IsCheckedActivo">Activo</mat-checkbox>
      </div>
    </div>
    <!---->
    <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="form-group">
                  <div class="row">
                      <div class="text-center" color="primary">
                        <div style="display:none;">
                          <input type="file" #fileInput ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event,'image')" accept=".jpg, .png" />
                        </div>
                        <div style="display:none;">
                          <input type="file" #fileInput2 ng2FileSelect [uploader]="uploader" (onFileSelected)="onFileSelected($event,'video')" accept=".mp4, .mkv" />
                        </div>
                        <div class="btn-group">
                          <button type="button" mat-button class="btn-primary m-1" (click)="fileInput.click()">
                            <mat-label>Agregar imagen</mat-label>
                            <mat-icon>drive_folder_upload</mat-icon>
                          </button>
                          <button type="button" mat-button class="btn-primary m-1" (click)="fileInput2.click()">
                            <mat-label>Agregar video</mat-label>
                            <mat-icon>drive_folder_upload</mat-icon>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <!-- <label class="col-sm-3 col-form-label" for="files">Archivos:</label> -->
          <div class="col-md-12">
            <table class="table">
              <thead>
                <tr>
                  <th>Archivo</th>
                  <th>Tipo</th>
                  <th *ngIf="showFromDB == true">Tamaño</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of arrayObjetFile">
                  <td>
                    {{ item.fileName }}
                  </td>
                  <td>
                    {{ item.type }}
                  </td>
                  <td *ngIf="showFromDB == true">
                    {{ item?.fileSize/1024/1024 | number:'.2' }} MB
                  </td>
                  <td>
                    <button mat-mini-fab type="button" color="primary"  (click)="deleteFile(item)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" class="btn-secondary" (click)="cerrarDialogo()">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
  <button mat-button type="submit" class="btn-primary" [disabled]="form.invalid || arrayObjetFile.length <= 0">
    <mat-label class="m-1">Guardar</mat-label>
    <mat-icon aria-hidden="false">check_circle_outline</mat-icon>
  </button>
</div>
</form>
  