import { Component, Injector, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { API } from 'app/views/shared/api';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-study-plan',
  templateUrl: './study-plan.component.html',
  styleUrls: ['./study-plan.component.css']
})
export class StudyPlanComponent extends BaseService implements OnInit {
  
  public course:any;
  public modules:any;
  public modifiedAt:string;
  userInfo:any;
  public isLogged:boolean = false;
  panelOpenState = true;

  constructor(
    injector:Injector
  ) { 
    super(injector);
    const userInfo = this.storage.getUserInfo();
    this.isLogged = (userInfo != null && userInfo != undefined);
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const courseId = Number(params.get('id'));
      if (this.isLogged) {
        
        if (courseId) {
          this.getUserInformation();
          this.getCourse(courseId);
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
          this.location.back();
        }

      } else {
        if (courseId) {
          this.getCourse(courseId);
        } else {
          this.service.openDialog('No fue posible obtener la información del curso.');
          this.location.back();
        }
      }

    });
  }

  getCourse(courseId:number){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      (response) => {
        loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.course = body.data.course;
        this.modifiedAt = this.datepipe.transform(this.course?.modifiedAt, 'dd/MMM/yyyy','UTC-6','ES-MX');
        this.modules = body.data.modules;
        if (this.modules){
          this.modules.forEach(
            (module) => {
              if (module.topics) {
                module.topics = this.service.sortByOrder(module.topics);
              }
            }
          )
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  buyCourse(){
    this.router.navigate(['/dashboard/course/buy/',this.course.id]);
  }

  userHasLevelrequired(levelRequiredId:number):boolean{
    if(!this.isLogged) return false;
    const user:any = this.storage.getUserInfo();
    return (user.userLevelId >= levelRequiredId)?true:false;
  }

  async getUserInformation(){
    this.userInfo = await this.storage.getUserInfo();
  }

}
