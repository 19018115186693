<div fxLayout="row" fxLayoutAlign="end top">
  <button mat-icon-button aria-label="close icon" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="mat-typography">
  <app-terms style="max-height: 500px;"></app-terms>
</div>
<mat-dialog-actions align="center">
  <button class="btn-outline-light" mat-button [mat-dialog-close]="true">
    <mat-label class="m-1">Aceptar</mat-label>
    <mat-icon aria-hidden="false">check_circle</mat-icon>
  </button>
</mat-dialog-actions>