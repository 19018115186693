<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="margin-top: 190px;"
    fxFlex="100"
    >

    <!-- /.preloader -->
    <div class="page-wrapper">

    <!--Page Header Start-->
    <section class="page-header clearfix" style="background-image: url(assets/new/images/backgrounds/page-header-bg.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page-header__wrapper clearfix">
                        <div class="page-header__menu">
                            <ul class="page-header__menu-list list-unstyled clearfix">
                                <li><a href="index.html">Inicio</a></li>
                                <li class="active">¿Quiénes somos?</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!--Page Header End-->


    <!--Start Teaching One-->
    <section class="teaching-one">
        <div class="container">
            <div class="row">
                <!--Start Teaching One Apply Form-->
                <div class="col-xl-6 col-lg-5">
                    <div class="teaching-one__apply-form">
                        <div class="title-box">
                            <h4 class="text-white">Regístrate</h4>
                        </div>
                        <div class="form-box">
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input (input)="form.controls['name'].markAsTouched()" type="text" name="name" formControlName="name" placeholder="Nombre" required="">
                                </div>
                                <div class="form-group">
                                    <small *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                                    class="form-error-msg"> * Requerido</small>
                                </div>
                                <div class="form-group">
                                    <input (input)="form.controls['lastname'].markAsTouched()" type="text" name="lastname" formControlName="lastname" placeholder="Apellidos" required="">
                                </div>
                                <div class="form-group">
                                    <small *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched" 
                                    class="form-error-msg"> * Requerido</small>
                                </div>
                                <div class="form-group">
                                    <input (input)="form.controls['email'].markAsTouched()" type="email" name="email" formControlName="email" placeholder="Correo electrónico" required="">
                                </div>
                                <div class="form-group">
                                    <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                                    class="form-error-msg"> * Requerido</small>
                                    <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                                    class="form-error-msg"> * Correo electrónico no válido.</small>
                                </div>
                                <button class="registration-one__right-form-btn" type="submit"
                                        name="submit-form">
                                        <span class="thm-btn">Enviar</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <!--End Teaching One Apply Form-->

                <!--Start Teaching One Content-->
                <div class="col-xl-6 col-lg-7">
                    <div class="teaching-one__content">
                        <h2 class="teaching-one__content-title text-yellow">¿Quiénes somos?</h2>
                        <p class="teaching-one__content-text1 text-white">
                            Sayucfox es una plataforma de cursos, capacitaciones y venta de materiales gráficos y multimedia, que forma parte de la empresa Sayuc Element S.A de C.V., cuyas actividades principales son la creación de APP & APK, Metaverso, Gamification, Web y Desarrollos informáticos a la medida. 
                        </p>
                        <div class="teaching-one__content-img" style="width:50%; text-align: center; margin-left: auto; margin-right: auto;">
                            <img style="padding-top: 50px;" src="assets/new/images/resources/sayuc.png" alt=""/>
                        </div>
                    </div>
                </div>
                <!--End Teaching One Content-->
            </div>
        </div>
    </section>
    <!--End Teaching One-->

    <!--Start Meet Teachers One-->
    <section class="meet-teachers-one meet-teachers-one--teachers">
        <div class="container">
            <div class="row">
                <h2 class="teaching-one__content-title text-white" style="text-align:center; padding-bottom:50px;">Streamer</h2>
                <!--Start Single Meet Teachers One-->
                <div class="col-xl-12 col-lg-12">
                    <div class="meet-teachers-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="meet-teachers-one__single-img">
                            <img src="assets/new/images/resources/meet-teachers-v1-img1.png" alt=""/>
                        </div>

                        <div class="meet-teachers-one__single-content">
                            <div class="meet-teachers-one__single-middle-content">
                                <div class="title text-white">
                                    <h4 class="text-white"><a href="#">Sayucfox</a></h4>
                                    <p>Streamer, Editor y Músico</p>
                                </div>
                                <p class="meet-teachers-one__single-content-text">Ingeniero en tecnologías Interactivas en Animación Digital, Director Creativo de Sayuc Element S.A de C.V, Ganadores de 1er lugar en el concurso Hazlo en Cortometraje en la categoría animación en el 2012 y 3er lugar en el Festival Cine de Campo en la categoría animación en el 2012. Creador de varios torneos digitales y overlays para multiples marcas y streamers. Editor de video para canales con alcance de más de 2 millones de reproducciones. Músico del conservatorio nacional de Música </p>
                            </div>

                            <div class="meet-teachers-one__single-bottom-content">
                                <div class="meet-teachers-one__single-content-courses-box">
                                    <div class="text">
                                        <p>Ponte en contacto:</p>
                                    </div>
                                    <div class="social-icon">
                                        <ul class="list-unstyled">
                                            <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin" style="color: var(--thm-black)"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Single Meet Teachers One-->
            </div>
        </div>
    </section>
    <!--End Meet Teachers One-->

    </div><!-- /.page-wrapper -->

    <div class="mobile-nav__wrapper">
        <div class="mobile-nav__overlay mobile-nav__toggler"></div>
        <!-- /.mobile-nav__overlay -->
        <div class="mobile-nav__content">
            <span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

            <div class="logo-box">
                <a href="index.html" aria-label="logo image"><img src="assets/images/resources/mobilemenu_logo.png" width="155" alt="" /></a>
            </div>
            <!-- /.logo-box -->
            <div class="mobile-nav__container"></div>
            <!-- /.mobile-nav__container -->

            <ul class="mobile-nav__contact list-unstyled">
                <li>
                    <i class="icon-phone-call"></i>
                    <a a href="https://discord.gg/TUdhuAX9gJ">Únete al DISCORD</a>
                </li>
                <li>
                    <i class="icon-letter"></i>
                    <a href="tel:666-888-0000">666 888 0000</a>
                </li>
            </ul><!-- /.mobile-nav__contact -->
            <div class="mobile-nav__top">
                <div class="mobile-nav__social">
                    <li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                </div><!-- /.mobile-nav__social -->
            </div><!-- /.mobile-nav__top -->
        </div>
        <!-- /.mobile-nav__content -->
    </div>
    <a (click)="gotoTop()" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
  </div>

  <div 
    fxFlex="100"
    >
    <app-footer></app-footer>
  </div>
</div>
