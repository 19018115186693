import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ComunicationService } from 'app/comunication.service';
import { DialogCourseComponent } from 'app/shared/components/dialog-course/dialog-course.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { RoleService } from 'app/views/services/role.service';
import { CatCourse } from "app/views/shared/interfaces";


@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.css']
})
export class AdminCoursesComponent extends BaseService implements OnInit {
  row:CatCourse;
  rolVer:boolean;
  actualIndexSelected:number = -1;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(
    injector:Injector
  ) { super(injector); }
  tope=0;
  intervalo;
  async mensaje() {
   await this.notificationC.setNotifications();
    this.tope++;
    //console.log("this.intervalo",this.intervalo);
  }
  intervalo_(notificationC_) {
  this.intervalo= setInterval(() => { this.mensaje(); },600000);
  }

  ngOnInit(){
    let userInfo = this.storage.getUserInfo();
    let isLogged = (userInfo != null && userInfo != undefined);
    if (isLogged){
      this.notificationC.setNotifications();
      //this.intervalo_(this.notificationC);
    }
    this.rolVer = !this.roleService.isInstructor();
    if(!this.rolVer){
      this.displayedColumns = ['course', 'courseLeve','salePrice','activeLabel','Modules'];
    }else{
      this.displayedColumns = ['course','name', 'courseLeve','salePrice','Borrar','Editar','Modules'];
    }
    this.getCatCourse();
  }

  getCatCourse(){
    this.loader.open();
    this.service.getCoursesAll().subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.dataSource.data = body.data;
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }
  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }
  
  public openDialogEdit() {    
    this.dialog.open(DialogCourseComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveEditCatCourse(data);
        } 
      });
  }

  desactivarCourse(){
    let mensaje =this.row.active==1?"desactivado":"activado"
    this.row.active =this.row.active==1?0:1;
     
    this.loader.open();
    this.service.deleteCatCourse(this.row).subscribe(
      (data:any) => {
        //console.log(data);
        this.loader.close();
        let message:string = `Desactivar curso`; 
        this.openDialog(`Curso ${mensaje} correctamente`)
        .subscribe(
          () => {
            this.getCatCourse();
          }
        );
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  saveEditCatCourse(data){    
    this.loader.open('Guardando Edición ...');
    const formdata = new FormData();
    data.userIds = data.userId;
    formdata.append("course",JSON.stringify(data));
    data.files.forEach(f => {
      formdata.append(f.type,f.file);
    });
    this.service.saveEditCatCourse(formdata).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getCatCourse();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  saveCatCourse(data){
    this.loader.open();
    data.userIds = data.userId;
    const formdata = new FormData();
    formdata.append('course',JSON.stringify(data));  
    data.files.forEach(f => {
      formdata.append(f.type,f.file);
    });
    this.service.saveCatCourse(formdata).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getCatCourse();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  onClickDelete(item:any,$event : any, index:number){
    this.actualIndexSelected = index;
    this.row =item;
    let mensaje =this.row.active==1?"desactivar":"activar"
    this.openConfirm(`¿Seguro(a) que desea ${mensaje} el curso?`).subscribe(
      (response) => {
        if (response) this.desactivarCourse();
      }
    );
  }

  onClickEdit(item:any){
    this.row =item;
    this.openDialogEdit();
  }

  onClickNuevo(): void {
    let item ={
      id:0,
      course:"",
      courseLevelId:0,
      courseLeve:"",
      active:1,
      activeLabel:"",
      salePrice:"",
      name:"",
      userId:0,
      description:"",
      imageUrl:""
    }
    this.dialog.open(DialogCourseComponent, {
      disableClose: true,
      width: '50%',
      data:item
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveCatCourse(data);
        } 
      });
  }
  
  goToListPupil(course_id){
    this.router.navigate(['/dashboard/cmsAlumnos',course_id]);
  }

  goToModule(course_id){
    if(this.rolVer){
      this.router.navigate(['/dashboard/cmsModule', course_id]);
    }else{
      this.router.navigate(['/dashboard/course', course_id]);
    }
    
  }
  
  goToActivity(course_id){
    this.router.navigate(['/dashboard/cmsActivity', course_id]);
  }

  goToTeams(course_id){
    this.router.navigate(['/dashboard/cmsTeam', course_id]);
  }

  goToPaymentMethods(courseId:number): void {
    this.router.navigate(['/dashboard/cms/courses/payments/methods', courseId]);
  }
}
