<div mat-dialog-title>
  <mat-icon class="text-white" color="white" (click)="close()">close</mat-icon>
</div>
<div mat-dialog-content>
  <video 
    [src]="video.src" 
    controlsList="nodownload"
    type="video/mp4" 
    [controls]="true" 
    [muted]="false" 
    [autoplay]="true"
    [loop]="true"
    oncontextmenu="return false;"
    poster="assets/images/entrepeneurs/video_placeholder.jpeg"
    >
    Tu navegador no es compatible con la etiqueta de video.
  </video>
</div>
