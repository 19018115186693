import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { API } from 'app/views/shared/api';
import { ProfileInfoInstructor, CertificatesInstructor } from 'app/views/shared/classes';

@Component({
  selector: 'app-instructor',
  templateUrl: './instructor.component.html',
  styleUrls: ['./instructor.component.css'],
  animations: [egretAnimations]
})
export class InstructorComponent extends BaseService implements OnInit {

  public currentPage:number;//pagination config
  public itemsPerPage:number = 4;//pagination config
  public rowsNumber:number = 0;//pagination config
  public rowNumberInit:number = 1;//pagination config
  public certifications:Array<CertificatesInstructor> = [];
  public infoInstructor:ProfileInfoInstructor;

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const idInstructor = Number(params.get('id'));
      if(Number.isNaN(idInstructor)){
        this.service.openDialog('No se pudo obtener informacion del instructor, intente de nuevo');
      }
      this.getinfoInstructor(idInstructor);
    });
  }

  onImgError(event:any){
    event.target.src = 'assets/images/entrepeneurs/facebook.png';
  }

  onImgErrorProfile(event:any){
    if(this.infoInstructor?.imageUrl) this.infoInstructor.imageUrl = this.defaultImage;
  }

  goToDetails(){}

  changePage(page:any){
    this.currentPage = Number(page);
    this.rowNumberInit = this.currentPage==1 ? 1 : ((this.currentPage-1) * this.itemsPerPage);
  }

  defaultImage:string = 'assets/images/perfil-usuario.png';
  getinfoInstructor(id:number)
  {
    this.loader.open();
    this.service.getInfoInstructor(id).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.certifications = body.data.certifications;
        this.infoInstructor = body.data.infoInstructor;
        if (this.infoInstructor.hasOwnProperty('imageUrl')) {
          if (!this.infoInstructor.imageUrl) {
            this.infoInstructor.imageUrl = this.defaultImage;
          }else{
            this.infoInstructor.imageUrl = this.infoInstructor.imageUrl;
          }
        }
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      },
    )
  }

}
