<app-header-top-sessions *ngIf="!isLogged"></app-header-top-sessions>
<app-header-top *ngIf="isLogged"></app-header-top>
<div
    [style.margin-top]="'10em'"
    fxFlex="100"
    fxLayout="column" 
    fxLayout.md="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <!--boton de retroceso-->
    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 0px 20px 5px 20px;">
        <div flex="5">
            <button mat-mini-fab color="purple" focusable="false" (click)="service.goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div flex="85" fxLayoutAlign="top center" style="width: 100%;margin-left: 10px;">
            <mat-label>Regresar a cursos</mat-label>
        </div>
    </div>
    <!--/-->
    <div fxLayout="row" fxLayoutAlign="center center" class="main" style="padding-top:40px;">
        <div fxFlex="80" fxFlex.xs="100" fxLayout.xs="column" fxLayout="row">
            <div fxFlex="60" fxFlex.xs="100" fxLayout="column">
                <mat-card fxLayout="column" style="background-color: var(--thm-sayu);">
                    <div class="p-3">
                        <h3 class="text-white" style="word-break: break-all;">{{course?.course}}</h3>
                        <mat-label class="text-white" style="word-break: break-all; font-size: 12px; margin-bottom: 1em;">{{course?.description}}</mat-label>
                    </div>
                    <!-- <label><mat-icon [inline]="true">alarm</mat-icon> Fecha de la última actualización: {{modifiedAt}}</label> -->
                    <mat-card-content class="text-center" *ngIf="course?.videoUrl">
                        <video #video
                          *ngIf="course.videoUrl" 
                          [src]="service.getResourceBlobWithoutToken(video,course.videoUrl)" 
                          controlsList="nodownload"
                          type="video/mp4" 
                          style="width: 70%;height: auto;"
                          [ngStyle.xs]="'width: 100%;height: auto;'" 
                          [ngStyle.sm]="'width: 100%;height: auto;'" 
                          [ngStyle.md]="'width: 100%;height: auto;'" 
                          [controls]="true" 
                          [muted]="true" 
                          [autoplay]="false"
                          [loop]="false"
                          oncontextmenu="return false;"
                          poster="assets/images/entrepeneurs/video_placeholder.jpeg"
                          >
                          Tu navegador no es compatible con la etiqueta de video.
                        </video>
                        <mat-label
                          *ngIf="!course?.videoUrl" 
                          >
                          No hay video para mostrar.
                        </mat-label>
                    </mat-card-content>
                </mat-card>
                <h3 class="text-white" style="margin-left: 8px;">Lo que aprenderás</h3>
                <mat-card style="padding: 0px;" style="background-color: var(--thm-sayu);">
                    <mat-accordion *ngIf="modules" style="background-color: var(--thm-sayu);">
                        <mat-expansion-panel style="background-color: var(--thm-sayu);" [expanded]="true" (opened)="panelOpenState = true"
                                                (closed)="panelOpenState = false">
                            <mat-expansion-panel-header>
                            <mat-panel-title style="color: #fff !important;">
                                {{(panelOpenState)?'Ocultar':'Ver'}} plan de estudios.
                            </mat-panel-title>
                            <mat-panel-description>
                                
                            </mat-panel-description>
                            </mat-expansion-panel-header>
                            <!--content-->
                            <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex>
                                <div fxFlex="50" fxFlex.xs="100" *ngFor="let module of modules;">
                                <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start">
                                    <label style="font-weight: bold; color: #fff;"><mat-icon style="font-size: 10px;" [inline]="true">circle</mat-icon> {{module?.module}}</label>
                                    <div fxFlex="50" fxFlex.xs="100" *ngFor="let topic of module?.topics;" style="margin-left: 10px; color: #fff;">
                                        <label><mat-icon style="font-size: 10px;" [inline]="true">remove</mat-icon> {{topic?.topic}}</label>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <!--/-->
                        </mat-expansion-panel>
                    </mat-accordion>
                    <div *ngIf="!modules" class="text-center" style="padding: 1em;">
                        <mat-label>No hay contenido del curso para mostrar.</mat-label>
                    </div>
                </mat-card>
                      
                <!-- <h3 class="text-white" style="margin-left: 8px;">Requisitos</h3>
                <mat-card style="background-color: var(--thm-sayu)">
                    <mat-icon style="font-size:10px; color: #fff;" [inline]="true">circle</mat-icon>
                    <mat-label style="color: #fff;">
                        Nivel requerido: <b><i>{{course?.courseLevel}}</i></b>
                    </mat-label>
                </mat-card> -->
            </div>
            <div fxLayout="column" fxFlex.xs="100" fxFlex="40">
                <mat-card fxLayout="column" style="background-color: var(--thm-sayu)">
                    <div fxLayout="column" fxLayoutAlign="space-around center" class="m-1">
                        <img 
                            style="width: 320px;height: auto;border-radius: 8px;box-shadow: black 4px 4px 4px 0px;"
                            onerror="this.src='assets/images/entrepeneurs/not-found.png'" [src]="course?.imageUrl" alt="" width="auto">
                        <mat-label class="m-3 text-white">Curso: <b>"{{course?.course}}"</b></mat-label>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <h3 class="text-white">$&nbsp;{{this.service.getPrecio(course?.salePrice)}}&nbsp;{{currency}}</h3>
                    </div>
                    <div fxLayout="row" *ngIf=" isLogged == true and userHasLevelrequired(course?.courseLevelId)">
                        <button [disabled]="!userHasLevelrequired(course?.courseLevelId) && role.isClient()" mat-button class="btn btn-purple" fxFlex="100" (click)="buyCourse()">
                            <mat-label>Comprar ahora</mat-label>
                        </button>
                    </div>

                    <div fxLayout="row" *ngIf="!isLogged">
                        <button mat-button class="btn btn-purple" fxFlex="100" [routerLink]="['/signin']"   >
                            <mat-label>Comprar ahora</mat-label>
                        </button>
                    </div>

                    <div *ngIf=" isLogged == true && !userHasLevelrequired(course?.courseLevelId) && role.isClient()" fxLayoutAlign="center center">
                        <p style="text-align: center;">
                            <span class="text-danger"><b>Aún no puedes adquirir este Curso</b></span>, <br>porque <b>eres Nivel "{{userInfo.userLevel}}"</b> y <b>se requiere Nivel "{{course.courseLevel}}"</b>
                        </p>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <app-footer *ngIf="!isLogged" fxFlex="100"></app-footer>
    <app-footer *ngIf="isLogged" fxFlex="100"></app-footer>
</div>
