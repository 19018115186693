<div fxLayout="column">
  <p class="f-18" mat-dialog-title class="text-purple text-bold">Detalles del Curso:</p>
  <mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
  <mat-dialog-content fxLayout="column" style="min-width: 300px;max-width: 540px;">
    <img class="mat-elevation-z2 img-thumbnail rounded" style="margin-bottom: 20px;" [src]="'assets/images/entrepeneurs/courses1.png'"
      alt="Imagen del curso">
    <table class="table table-sm">
      <tr>
        <th style="width: 120px !important">Nombre:</th>
        <td class="text-left">{{course?.course}}</td>
      </tr>
      <tr>
        <th style="width: 120px !important">Nivel del Curso:</th>
        <td class="text-left">{{course?.courseLevelId}}</td>
      </tr>
    </table>
    <div class="text-center">
      <div style="display: inline-block; margin-right: 0; margin-bottom: 0;" *ngIf="acquiredCourse == true">
        <button (click)="goToCalendar(course.id)" type="button" mat-button class="btn-outline-purple">
          <mat-label class="m-1">Ver Actividades</mat-label>
          <mat-icon>today</mat-icon>
        </button>
        &nbsp;|&nbsp;
        <button (click)="goToListPupil(course?.id)" type="button" mat-button class="btn-outline-purple">
          <mat-label class="m-1">Ver Compañeros</mat-label>
          <mat-icon>visibility</mat-icon>
        </button>
      </div>
      <button (click)="goToCourseDetails(course?.id)" type="button" mat-button class="btn-outline-purple">
        <mat-label class="m-1">Ver Plan de Estudios</mat-label>
        <mat-icon>menu_book</mat-icon>
      </button>
      
    </div>
  </mat-dialog-content>
  <mat-divider style="margin: 10px 0 10px 0;" [inset]="true"></mat-divider>
  <mat-dialog-actions align="center">
    <button mat-button [mat-dialog-close]="true">
      <mat-label class="m-1">Aceptar</mat-label>
      <mat-icon aria-hidden="false">check_circle</mat-icon>
    </button>
  </mat-dialog-actions>
</div>