import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ComunicationService } from 'app/comunication.service';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';

@Component({
  selector: 'app-admin-team',
  templateUrl: './admin-team.component.html',
  styleUrls: ['./admin-team.component.css']
})
export class AdminTeamComponent implements OnInit {
  team:any;
  courseId:number;
  course:any;
  displayedColumns: string[] = ['image','student','email','team','mentoria'];
  dataSource = new MatTableDataSource<any>();
  teams:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumnsTeams: string[] = ['id','team','actions'];
  dataSourceTeams = new MatTableDataSource<any>();
  @ViewChild('teamsPaginator',{static:false}) teamsPaginator: MatPaginator;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: AdminService,
    private router: Router,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private paginatorService: PaginatorService
  ) { }

  ngOnInit(): void {
    this.getEquipos();
    this.courseId =0; 
    this.activatedRoute.paramMap.subscribe(params => {
      this.courseId = Number(params.get('id'));
      if (this.courseId) {
        this.getCourse(this.courseId);
      } else {
        this.service.openDialog('No fue posible obtener la información de los equipos.');
        this.router.navigateByUrl('/dashboard/cmsCourses');
      }
    });
    this.dataSourceTeams.data = [
      {id:1,team:'Equipo 1'},
      {id:2,team:'Equipo 2'},
      {id:3,team:'Equipo 3'},
      {id:4,team:'Equipo 4'},
      {id:5,team:'Equipo 5'},
      {id:6,team:'Equipo 6'},
      {id:7,team:'Equipo 7'},
      {id:8,team:'Equipo 8'},
      {id:9,team:'Equipo 9'},
      {id:10,team:'Equipo 10'},
      {id:11,team:'Equipo 11'},
    ];
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
    //
    this.teamsPaginator._intl = this.paginatorService.translatePaginator(this.teamsPaginator._intl);
    this.dataSourceTeams.paginator = this.teamsPaginator;
  }

  getCourse(courseId:number){
    this.loader.open('Cargando información');
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      async (data) => {
        loader.close();
        if (data.status == 200){
          let content = data.body.content;
          if (content instanceof Array && content.length > 0){
            this.course = data.body.content[0];
          } else {
            this.service.openDialog('No es posible obtener la información del grupo');
          }
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          default:
            this.service.openDialog('Error con el servicio.');
        }
      },
      ()=>this.getTeams(this.courseId)
    );
  }

  getTeams(courseId:number){
    this.loader.open('Cargando Equipos');
    const loader = this.loader.getDialog();
    this.service.getTeams(courseId).subscribe(
      (data) => {
        
          if(data.status==200){
            this.dataSource.data =  data.body.content;
            this.loader.close();
          }else{
            this.loader.close();
            this.dataSource.data =[];
            this.service.openDialog('No se encontraron datos');
          }
          
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          default:
            this.service.openDialog('Error con el servicio.');
        }
      }
    );
  }
  getEquipos(){
    const data = [];
    for(var i=0;i<=100;i++){
      const item ={
        idTeam : i,
        team : i
      };
      data.push(item);
    }
   this.teams = data;
  }
  goToCatCourse(){
    this.router.navigate(['/dashboard/cmsCourses']);
  }
  onClickSave(){
    const arrayTeam =[];
    for(var i=0;i<this.dataSource.data.length;i++){
      arrayTeam.push(this.dataSource.data[i]);
    }
    
    if(arrayTeam.length<=0) return;

    const dataTeams ={
      teams: arrayTeam
    }
    this.loader.open('Guardando Equipo');
    const loader = this.loader.getDialog();
    this.service.saveTeam(dataTeams).subscribe(
      (dataResult:any) => {
        this.loader.close();
        if(dataResult.code==200){
          this.getTeams(this.courseId);
        }else{
          this.service.openDialog('Error al guardar equipos');
        }
          
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          default:
            this.service.openDialog('Error con el servicio.');
        }
      }
    );
  }
  sugerirMentoria(item){
    this.openDialog("Sugerir mentoria",`¿Está seguro que desaea sugerir mentoria para el alumno "${item.alumno}"?`,true);
  }
  public openDialog(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          //this.desactivarCourse();
        } 
      });
  }
}
