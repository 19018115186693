<div fxLayout="row" fxLayoutAlign="space-between center" >
  <h2 mat-dialog-title style="margin: 0px;" >Felicitaciones</h2>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="true"  >
      <mat-icon>close</mat-icon>
  </button>
</div>


<div mat-dialog-content class="congrats-banner">

    <!-- <h1 class="office-banner">
        ¡Felicidades! haz llegado al final del simulador de empresas
    </h1> -->
    <h1 [ngStyle.sm]="{'width': '61%', 'white-space': 'pre-wrap'}" style="position: fixed;left: 22%;">¡Felicidades! Has llegado al final del simulador de empresas</h1>
    
    <canvas id="my-canvas">
        
    </canvas>
        

</div>

<div mat-dialog-actions align="end">
  
</div>

