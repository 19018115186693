<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="margin-top: 190px;"
    fxFlex="100"
    >

    <!-- /.preloader -->
    <div class="page-wrapper">

      <!--Page Header Start-->
    <section class="page-header clearfix" style="background-image: url(assets/new/images/backgrounds/page-header-bg.jpg);">
      <div class="container">
          <div class="row">
              <div class="col-xl-12">
                  <div class="page-header__wrapper clearfix">
                      <div class="page-header__title">
                          <h2>Contacto</h2>
                      </div>
                      <div class="page-header__menu">
                          <ul class="page-header__menu-list list-unstyled clearfix">
                              <li><a href="index.html">Inicio</a></li>
                              <li class="active">Contacto</li>
                          </ul>
                      </div>
                  </div>
              </div>

          </div>
      </div>
    </section>
    <!--Page Header End-->

    <!--Start Contact Details One-->
    <section class="contact-details-one">
        <div class="container">
            <div class="row">
                <!--Start Single Contact Details One-->
                <div class="col-xl-6 col-lg-6">
                    <div class="contact-details-one__single">
                        <div class="contact-details-one__single-icon">
                            <span class="icon-chat"></span>
                        </div>
                        <div class="contact-details-one__single-text">
                            <h4><a href="https://discord.gg/TUdhuAX9gJ">Discord <br>Sayucfox</a></h4>
                            <p>Únete a la comunidad</p>
                        </div>
                    </div>
                </div>
                <!--End Single Contact Details One-->

                <!--Start Single Contact Details One-->
                <div class="col-xl-6 col-lg-6">
                    <div class="contact-details-one__single">
                        <div class="contact-details-one__single-icon">
                            <span class="icon-message-1"></span>
                        </div>
                        <div class="contact-details-one__single-text">
                            <h4><a a href="https://discord.gg/TUdhuAX9gJ">Ven al canal<br>twitch.tv/sayucfox</a></h4>
                            <p>Visítame</p>
                        </div>
                    </div>
                </div>
                <!--End Single Contact Details One-->
            </div>
        </div>
    </section>
    <!--End Contact Details One-->

    <!--Contact Page Start-->
    <section class="contact-page">
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-4">
                    <div class="contact-page__left">
                        <div class="section-title">
                            <span class="section-title__tagline">Envía un mensaje</span>
                            <h2 class="section-title__title text-white">Siempre <br> queremos escuchar <br> de ti</h2>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-8">
                    <div class="contact-page__right">
                        <form [formGroup]="form" (ngSubmit)="send()" class="comment-one__form contact-form-validated" novalidate="novalidate">
                            <div class="row">
                                <div class="col-xl-6 col-lg-6">
                                    <div class="comment-form__input-box">
                                      <input (input)="form.controls['name'].markAsTouched()" matInput type="text" formControlName="name" placeholder="Nombre completo" value="">
                                      <small *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                                        class="form-error-msg"> * Requerido</small>
                                      <small *ngIf="form.controls['name'].hasError('minlength') && form.controls['name'].touched" 
                                      class="form-error-msg"> * Al menos 3 caracteres. </small>
                                      <small *ngIf="form.controls['name'].hasError('maxlength') && form.controls['name'].touched" 
                                        class="form-error-msg"> * No más de 50 caracteres. </small>
                                      <small *ngIf="form.controls['name'].hasError('pattern') && form.controls['name'].touched && !form.controls['name'].hasError('minlength')" 
                                        class="form-error-msg"> * Sólo letras. </small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6">
                                    <div class="comment-form__input-box">
                                      <input (input)="form.controls['email'].markAsTouched()" matInput type="email" formControlName="email" placeholder="Correo electrónico" value="">
                                      <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                                        class="form-error-msg"> * Requerido</small>
                                      <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched && !form.controls['email'].hasError('minlength')" 
                                        class="form-error-msg"> * Correo electrónico no válido.</small>
                                      <small *ngIf="form.controls['email'].hasError('minlength') && form.controls['email'].touched" 
                                        class="form-error-msg"> * Al menos 5 caracteres. </small>
                                      <small *ngIf="form.controls['email'].hasError('maxlength') && form.controls['email'].touched" 
                                        class="form-error-msg"> * No más de 60 caracteres. </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-6 col-lg-6">
                                    <div class="comment-form__input-box">
                                      <input (input)="form.controls['phone'].markAsTouched()" matInput type="text" formControlName="phone" placeholder="Teléfono" value="">
                                      <small *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched" 
                                        class="form-error-msg"> * Requerido</small>
                                      <small *ngIf="form.controls['phone'].hasError('minlength') && form.controls['phone'].touched" 
                                        class="form-error-msg"> * Número telefónico a 10 dígitos. </small>
                                      <small *ngIf="form.controls['phone'].hasError('maxlength') && form.controls['phone'].touched" 
                                        class="form-error-msg"> * Número telefónico a 10 dígitos. </small>
                                      <small *ngIf="form.controls['phone'].hasError('pattern') && form.controls['phone'].touched" 
                                        class="form-error-msg"> * Sólo se permiten números. </small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6">
                                    <div class="comment-form__input-box">
                                      <input (input)="form.controls['subject'].markAsTouched()" matInput type="text" formControlName="subject" placeholder="Asunto">
                                      <small *ngIf="form.controls['subject'].hasError('required') && form.controls['subject'].touched" 
                                        class="form-error-msg"> * Requerido</small>
                                      <small *ngIf="form.controls['subject'].hasError('minlength') && form.controls['subject'].touched" 
                                        class="form-error-msg"> * Al menos 5 caracteres. </small>
                                      <small *ngIf="form.controls['subject'].hasError('maxlength') && form.controls['subject'].touched" 
                                        class="form-error-msg"> * No más de 40 caracteres. </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="comment-form__input-box">
                                      <textarea (input)="form.controls['comment'].markAsTouched()" matInput rows="5" formControlName="comment" placeholder="Escribe tus comentarios" value="" style="resize: none;"></textarea>
                                      <small *ngIf="form.controls['comment'].hasError('required') && form.controls['comment'].touched" 
                                        class="form-error-msg"> * Requerido</small>
                                      <small *ngIf="form.controls['comment'].hasError('minlength') && form.controls['comment'].touched" 
                                        class="form-error-msg"> * Al menos 10 caracteres. </small>
                                      <small *ngIf="form.controls['comment'].hasError('maxlength') && form.controls['comment'].touched" 
                                        class="form-error-msg"> * No más de 255 caracteres. </small>
                                      <small *ngIf="form.controls['comment'].hasError('pattern') && form.controls['comment'].touched && !form.controls['comment'].hasError('minlength')" 
                                        class="form-error-msg"> * Sólo letras. </small>
                                    </div>
                                    <button mat-raised-button type="submit" class="btn btn-purple btn-lg mb-1 rounded btn-lg" [disabled]="form.invalid">
                                      ENVIAR MENSAJE
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--Contact Page End-->

      <!--
      <div class="session-form-hold">
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4>Contáctanos</h4>
            </div>
            <div class="form-box">
              <form [formGroup]="form" (ngSubmit)="send()">
                <div class="form-group">
                  <input (input)="form.controls['name'].markAsTouched()" matInput type="text" [formControl]="form.controls['name']" placeholder="Nombre completo" value="">
                  <small *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['name'].hasError('minlength') && form.controls['name'].touched" 
                  class="form-error-msg"> * Al menos 3 caracteres. </small>
                  <small *ngIf="form.controls['name'].hasError('maxlength') && form.controls['name'].touched" 
                    class="form-error-msg"> * No más de 50 caracteres. </small>
                  <small *ngIf="form.controls['name'].hasError('pattern') && form.controls['name'].touched && !form.controls['name'].hasError('minlength')" 
                    class="form-error-msg"> * Sólo letras. </small>
                </div>
                <div class="form-group">
                  <input (input)="form.controls['email'].markAsTouched()" matInput type="email" [formControl]="form.controls['email']" placeholder="Correo electrónico" value="">
                  <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched && !form.controls['email'].hasError('minlength')" 
                    class="form-error-msg"> * Correo Electrónico incorrecto</small>
                  <small *ngIf="form.controls['email'].hasError('minlength') && form.controls['email'].touched" 
                    class="form-error-msg"> * Al menos 5 caracteres. </small>
                  <small *ngIf="form.controls['email'].hasError('maxlength') && form.controls['email'].touched" 
                    class="form-error-msg"> * No más de 255 caracteres. </small>
                </div>
                <div class="form-group">
                  <textarea matInput rows="5" formControlName="comment" placeholder="Escribe tus comentarios" value="" style="resize: none;"></textarea>
                  <small *ngIf="form.controls['comment'].hasError('required') && form.controls['comment'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['comment'].hasError('minlength') && form.controls['comment'].touched" 
                    class="form-error-msg"> * Al menos 10 caracteres. </small>
                  <small *ngIf="form.controls['comment'].hasError('maxlength') && form.controls['comment'].touched" 
                    class="form-error-msg"> * No más de 255 caracteres. </small>
                  <small *ngIf="form.controls['comment'].hasError('pattern') && form.controls['comment'].touched && !form.controls['comment'].hasError('minlength')" 
                    class="form-error-msg"> * Sólo letras. </small>
                </div>
                <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">
                  Enviar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      -->
  </div>

  <a (click)="gotoTop()" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
  </div>

  <div 
    fxFlex="100"
    >
    <app-footer></app-footer>
  </div>
</div>
