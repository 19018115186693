<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >

    
    <div 
      fxFlex="100"
      class="page-wrap"
      style="height: 100vh;"
      >
      <!---->
      <div class="session-form-hold" [@animate]="{value:'*',params:{delay: '200ms', x:'250px'}}"> 
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4 class="text-white" style="color: white !important;">Regístrate</h4>
            </div>
            <div class="form-box">
              <form [formGroup]="form" (ngSubmit)="showConfirmDialog()">
                <div class="form-group">
                  <input (input)="form.controls['name'].markAsTouched()" matInput type="text" [formControl]="form.controls['name']" placeholder="Nombre" value="">
                  <small *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['name'].hasError('minlength') && form.controls['name'].touched" 
                    class="form-error-msg"> * Al menos 2 caracteres. </small>
                  <small *ngIf="form.controls['name'].hasError('maxlength') && form.controls['name'].touched" 
                    class="form-error-msg"> * No más de 50 caracteres. </small>
                  <small *ngIf="form.controls['name'].hasError('pattern') && form.controls['name'].touched" 
                    class="form-error-msg"> * Sólo letras. </small>
                </div>
                <div class="form-group">
                  <input (input)="form.controls['lastname'].markAsTouched()" matInput type="text" [formControl]="form.controls['lastname']" placeholder="Apellidos" value="">
                  <small *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['lastname'].hasError('minlength') && form.controls['lastname'].touched" 
                    class="form-error-msg"> * Al menos 2 caracteres. </small>
                  <small *ngIf="form.controls['lastname'].hasError('maxlength') && form.controls['lastname'].touched" 
                    class="form-error-msg"> * No más de 50 caracteres. </small>
                  <small *ngIf="form.controls['lastname'].hasError('pattern') && form.controls['lastname'].touched" 
                    class="form-error-msg"> * Sólo letras. </small>
                </div>
                <div class="form-group">
                  <input (input)="form.controls['email'].markAsTouched(); onEmailChange()" matInput type="email" [formControl]="form.controls['email']" placeholder="Correo electrónico" value=""  (change)="onEmailChange()">
                  <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                    class="form-error-msg"> * Correo Electrónico incorrecto</small> 
                  <small *ngIf="form.controls['email'].hasError('minlength') && form.controls['email'].touched" 
                    class="form-error-msg"> * Al menos 5 caracteres. </small>
                  <small *ngIf="form.controls['email'].hasError('maxlength') && form.controls['email'].touched" 
                    class="form-error-msg"> * No más de 90 caracteres. </small>
                </div>
                <div class="form-group">
                  <input (input)="form.controls['confirm_email'].markAsTouched(); onEmailChange()" matInput type="email" [formControl]="form.controls['confirm_email']" placeholder="Confirmar correo electrónico" value=""  (change)="onEmailChange()" >
                  <small *ngIf="form.controls['confirm_email'].hasError('required') && form.controls['confirm_email'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['confirm_email'].hasError('confirm_email') && form.controls['confirm_email'].touched" 
                    class="form-error-msg"> * Correo Electrónico incorrecto</small>
                  <small *ngIf="form.controls['confirm_email'].hasError('minlength') && form.controls['confirm_email'].touched" 
                    class="form-error-msg"> * Al menos 5 caracteres. </small>
                  <small *ngIf="form.controls['confirm_email'].hasError('maxlength') && form.controls['confirm_email'].touched" 
                    class="form-error-msg"> * No más de 90 caracteres. </small>
                  <small class="form-error-msg" *ngIf="!matchEmail">El correo electrónico ingresado no coincide</small>
                </div>
                <div class="form-group form-group-center">
                  <input (input)="form.controls['password'].markAsTouched()" [type]="hide ? 'password' : 'text'" matInput [formControl]="form.controls['password']" placeholder="Contraseña" value="">
                  <button type="button" mat-icon-button matSuffix (click)="hide = !hide;" [matTooltip]="hide ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="color: white;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['password'].hasError('minlength') && form.controls['password'].touched" 
                    class="form-error-msg"> * Al menos 8 caracteres. </small>
                  <small *ngIf="form.controls['password'].hasError('maxlength') && form.controls['password'].touched" 
                    class="form-error-msg"> * No más de 20 caracteres. </small>
                  <small *ngIf="form.controls['password'].hasError('pattern') && form.controls['password'].touched" 
                    class="form-error-msg"> * 1er letra mayúscula, combinación de letras y números, sin espacios. </small>
                </div>
                <div class="form-group form-group-center">
                  <input (input)="form.controls['confirmPassword'].markAsTouched()" [type]="hideConfirm ? 'password' : 'text'" matInput [formControl]="form.controls['confirmPassword']" placeholder="Confirmar contraseña" value="">
                  <button type="button" mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm;" [matTooltip]="hideConfirm ? 'Mostrar' : 'Ocultar'">
                    <mat-icon style="color: white;">{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" 
                  class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['confirmPassword'].hasError('minlength') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> * Al menos 8 caracteres. </small>
                  <small *ngIf="form.controls['confirmPassword'].hasError('maxlength') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> * No más de 20 caracteres. </small>
                  <small *ngIf="form.controls['confirmPassword'].hasError('equalTo') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> * Las contraseñas no coinciden. </small>
                  <small *ngIf="form.controls['confirmPassword'].hasError('pattern') && form.controls['confirmPassword'].touched" 
                    class="form-error-msg"> * 1er letra mayúscula, combinación de letras y números, sin espacios. </small>
                </div>
                <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">
                  Crear cuenta
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>

  <div 
    fxFlex="100"
    >
    <app-footer></app-footer>
  </div>
</div>