<div class="container-fluid">
	<div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
        <div flex="5">

        </div>
        <div flex="85" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
            <h4>
                Administración de Promociones
            </h4>
        </div>
        <div flex="15">
            <button mat-button class="btn-purple float-right" (click)="onClickNuevo()">
                <mat-label>Nueva Promoción</mat-label>
            </button>
        </div>
    </div>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
		<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">

			<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
				<div class="example-container mat-elevation-z8">
					<mat-table #table [dataSource]="dataSource">
						<ng-container matColumnDef="promotion">
							<mat-header-cell class="m-1" *matHeaderCellDef> Promoción</mat-header-cell>
							<mat-cell class="text-justify m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Promoción:</span>
							  {{element.promotion}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="description" *ngIf="rolVer">
							<mat-header-cell class="m-1" *matHeaderCellDef>Descriptión</mat-header-cell>
							<mat-cell class="text-justify m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Descriptión:</span>
							  {{element.description}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="tipo">
							<mat-header-cell class="centrado" *matHeaderCellDef>Tipo </mat-header-cell>
							<mat-cell class="centrado" *matCellDef="let element"> 
							  <span class="mobile-label">Tipo:</span>
							  {{element.tipo}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="amount">
							<mat-header-cell class="centrado" *matHeaderCellDef>Monto</mat-header-cell>
							<mat-cell class="centrado" *matCellDef="let element"> 
							  <span class="mobile-label">Monto:</span>
							  $&nbsp;{{element.amount}}&nbsp;MXN 
							</mat-cell>
						  </ng-container>  
						  <!---->
						  <ng-container matColumnDef="expiresAt">
								<mat-header-cell class="centrado" *matHeaderCellDef>Expira</mat-header-cell>
								<mat-cell class="centrado" *matCellDef="let element"> 
									<span class="mobile-label">Expira:</span>
									{{element.fullExpiresAt}}
								</mat-cell>
						  </ng-container>
						  
							<ng-container matColumnDef="delete">
								<mat-header-cell *matHeaderCellDef>Activo</mat-header-cell>
								<mat-cell *matCellDef="let element; let i = index;"> 
									<span class="mobile-label">Activo</span>
									<mat-checkbox #_checkboxActive style="margin-left: 5%;" color="primary" (change)= "onClickDelete(element,_checkboxActive,i)" [checked]="element.active == 1"></mat-checkbox>
								</mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="edit">
							<mat-header-cell *matHeaderCellDef> Editar</mat-header-cell>
							<mat-cell *matCellDef="let element"> 
							  <span class="mobile-label">Editar:</span>
							  	<button mat-mini-fab color="primary" focusable="false" (click)="onClickEdit(element)">
									<mat-icon>edit</mat-icon>
								</button>
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="Users">
							<mat-header-cell *matHeaderCellDef>Regalar Promoción</mat-header-cell>
							<mat-cell *matCellDef="let element"> 
							  <span class="mobile-label">Regalar Promociónregalar:</span>
							  	<button mat-mini-fab color="primary" focusable="false" (click)="onClickAssignment(element)">
									<mat-icon>assignment</mat-icon>
								</button>
							</mat-cell>
						  </ng-container>
						  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
						  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
					</mat-table>
					<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>
<hr/>
