<div class="sidebar-panel bg-purple">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            
            <!-- App Logo -->
            <div class="branding">
                <!--
                <img src="assets/images/cms/logo.png" alt="" class="app-logo">
                <span class="app-logo-text">Celex CMS</span>
                -->
                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <div 
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf.sidebarCompactToggle}"
                (click)="toggleCollapse()" 
                *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div>
            </div>
        
            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                <img src="assets/images/hitec/user.png" class="mat-elevation-z1" alt="">
                </div>
                <span class="app-user-name mb-05">
                <mat-icon class="icon-xs text-muted">lock</mat-icon> 
                Bienvenido(a): {{user}}
                </span>
                <!-- Small buttons -->
                <div class="app-user-controls">
                <button 
                class="text-muted"
                mat-icon-button 
                mat-xs-button
                matTooltip="Configuración"
                [matMenuTriggerFor]="appUserMenu">
                    <mat-icon>settings</mat-icon>
                </button>
            <!--
                <button 
                class="text-muted"
                mat-icon-button 
                mat-xs-button
                matTooltip="Inbox"
                routerLink="/inbox">
                  <mat-icon>email</mat-icon>
                </button>
            -->
                <button 
                (click)="logout();"
                class="text-muted"
                mat-icon-button 
                mat-xs-button
                matTooltip="Cerrar Sesión">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
                    <mat-menu #appUserMenu="matMenu">
                        <button mat-menu-item [routerLink]="['/dashboard/userEdit']">
                            <mat-icon>account_box</mat-icon>
                            <span>Editar Perfil</span>
                        </button>
                        <button mat-menu-item [routerLink]="['/dashboard/userChangePassword']">
                            <mat-icon>lock</mat-icon>
                            <span>Cambiar contraseña</span>
                        </button>
                        <button mat-menu-item (click)="logout();">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Cerrar Sesión</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>