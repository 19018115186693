<div class="container-fluid">
	<div
		fxFlex="100"
		fxLayout="row">
		<div
			fxFlex="50">
			<div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
				<div flex="5">
					<button mat-mini-fab color="primary" focusable="false" (click)="goToCatCourse()">
						<mat-icon>arrow_back</mat-icon>
					</button>
				</div>
				<div flex="85" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
					<h4>
						Administración de Equipos - {{course?.course}}
					</h4>
				</div>
				<div flex="15">
				  <button mat-button class="btn-purple float-right" (click)="onClickSave()">
					<mat-icon>group_add</mat-icon>
					<mat-label>Guardar Equipo</mat-label>
				</button>
				</div>
			</div>
		
			<hr/>
			<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
				<!---->
				<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
		
					<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
						<div class="example-container mat-elevation-z8">
							<mat-table #table [dataSource]="dataSource">
									<ng-container matColumnDef="image">
										<mat-header-cell *matHeaderCellDef>Imagen</mat-header-cell>
										<mat-cell *matCellDef="let element"> 
										  <span class="mobile-label">Imagen:</span>
										  <img [src]="element.image" height="50" width="50" style="padding-top: 5px;padding-bottom: 5px;"/>
										</mat-cell>
									  </ng-container>
									  <ng-container matColumnDef="student">
										<mat-header-cell *matHeaderCellDef> Alumno</mat-header-cell>
										<mat-cell *matCellDef="let element"> 
										  <span class="mobile-label">Alumno:</span>
										  {{element.alumno}} 
										</mat-cell>
									  </ng-container>
									  <ng-container matColumnDef="email">
										<mat-header-cell *matHeaderCellDef> Correo</mat-header-cell>
										<mat-cell *matCellDef="let element"> 
										  <span class="mobile-label">Correo:</span>
										  {{element.correo}} 
										</mat-cell>
									  </ng-container>
						<ng-container matColumnDef="team">
										<mat-header-cell *matHeaderCellDef> Equipo</mat-header-cell>
										<mat-cell *matCellDef="let element"> 
										  <span class="mobile-label">Equipo:</span>
						  <mat-form-field appearance="fill" class="example-full-width">
							<mat-select  [(ngModel)]="element.team">
							  <mat-option *ngFor="let team of teams" [value]="team.idTeam">
								{{team.team}}
							  </mat-option>
							</mat-select>
						  </mat-form-field>
										</mat-cell>
									  </ng-container>
						<ng-container matColumnDef="mentoria">
						  <mat-header-cell *matHeaderCellDef> Sugerir mentoria:</mat-header-cell>
						  <mat-cell *matCellDef="let element"> 
							<span class="mobile-label">Sugerir mentoria:</span>
							  <button mat-mini-fab color="primary" focusable="false" (click)="sugerirMentoria(element)">
							  <mat-icon>groups</mat-icon>
							</button>
						  </mat-cell>
						  </ng-container>
									<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
									<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
								</mat-table>
								<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!---->
		<div
			class="m-2"
			fxFlex="50"
			fxLayout="column">
			<div fxFlex="100">
				<h2>Equipos registrados</h2>	
				<button class="btn-purple" mat-button>Crear Equipo</button>
			</div>
			<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">
				<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
					<div class="example-container mat-elevation-z8">
						<mat-table #table [dataSource]="dataSourceTeams">
							<ng-container matColumnDef="id">
								<mat-header-cell *matHeaderCellDef>id</mat-header-cell>
								<mat-cell *matCellDef="let row"> 
									<span class="mobile-label">id:</span>
									{{row.id}} 
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="team">
								<mat-header-cell *matHeaderCellDef>Equipo</mat-header-cell>
								<mat-cell *matCellDef="let row"> 
									<span class="mobile-label">Equipo:</span>
									{{row.team}} 
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="actions">
								<mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Acciones:</span>
									<button
										mat-button
										[matTooltip]="'Editar Equipo'" >
										<mat-icon>edit</mat-icon>
									</button> 
									<button 
										mat-button
										[matTooltip]="'Eliminar Equipo'"
										>
										<mat-icon>delete</mat-icon>
									</button> 
								</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedColumnsTeams"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumnsTeams;"></mat-row>
						</mat-table>
						<mat-paginator #teamsPaginator [length]="5" [pageSize]="5" showFirstLastButtons></mat-paginator>
					</div>
				</div>
			</div>
		</div>
	</div>
	
    
</div>
<hr/>
