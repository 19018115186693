<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">{{title}}</p>
    <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
        <mat-icon>close</mat-icon>
    </button>
</div>

<form [formGroup]="form" (ngSubmit)="confirmado()">
<div mat-dialog-content class="example-form">
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Módulo</mat-label>
                <input matInput placeholder="módulo" formControlName="module">
            </mat-form-field>
            <small *ngIf="form.controls['module'].hasError('required') && form.controls['module'].touched" class="form-error-msg">
              * Requerido.
            </small>
            <small *ngIf="form.controls['module'].hasError('maxlength') && form.controls['module'].touched" class="form-error-msg">
              * Máximo 500 caracteres.
            </small>
        </div>
    </div>
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Descripción" formControlName="description">
            </mat-form-field>
            <small *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched" class="form-error-msg">
              * Requerido.
            </small>
        </div>
    </div>
    
    <div layout="row" layout-xs="column">
        <div flex="">
            <mat-checkbox class="example-margin" formControlName="IsCheckedActivo">Activo</mat-checkbox>
        </div>
    </div>
   
</div>
<div mat-dialog-actions align="end">
  <button mat-button type="button" class="btn-secondary" (click)="cerrarDialogo()">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
  <button mat-button type="submit" class="btn-primary" [disabled]="form.invalid">
    <mat-label class="m-1">Guardar</mat-label>
    <mat-icon aria-hidden="false">check_circle_outline</mat-icon>
  </button>
</div>
</form>
  