import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-dialog-order-topic-resources',
  templateUrl: './dialog-order-topic-resources.component.html',
  styleUrls: ['./dialog-order-topic-resources.component.css']
})
export class DialogOrderTopicResourcesComponent implements OnInit {

  resources = [];

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.resources, event.previousIndex, event.currentIndex);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOrderTopicResourcesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private service: AdminService
  ) { }

  ngOnInit(): void {
    this.resources = this.data.resources;
  }

  openConfirmUpdateOrder() {
    let message:string = `actualizar el orden de los recursos del tema`;
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          let orderedResources:any = this.prepareResources();
          this.dialogRef.close(orderedResources);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    ); 
  }

  prepareResources() {
    let orderedResources:any[] = [];
    this.resources.forEach(
      (resource:any) => {
        let indexOf:number = this.resources.indexOf(resource);
        orderedResources.push(
          {
            resourceId:resource.id,
            resourceName:resource.fileName,
            resourceTittle:resource.titulo,
            order:indexOf
          }
        );
      }
    )
    this.resources = [];
    return orderedResources;
  }

  getFileExtension(fileName:string):string {
    let ext:String = fileName.substr(fileName.length-3,fileName.length);
    return ext.trim();
  }


}

export interface DialogData {
  resources:any;
}