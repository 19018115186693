<div fxLayout="row" fxLayoutAlign="space-between center" >
  <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Regalar Promoción</p>
  <button mat-icon-button aria-label="close icon" (click)="cerrarDialogo()"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content class="example-form">
<div class="example-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
      <!-- Position Column -->
      <ng-container matColumnDef="selected">
        <mat-header-cell *matHeaderCellDef>Check</mat-header-cell>
        <mat-cell *matCellDef="let element"> 
          <mat-checkbox [(ngModel)]="element.selected"></mat-checkbox>
        </mat-cell>
      </ng-container>
  
      <!-- Position Column -->
      <ng-container matColumnDef="alumno">
        <mat-header-cell *matHeaderCellDef> Alumno </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.alumno}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="correo">
        <mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.correo}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}" (click)="row.highlighted = !row.highlighted" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
    </mat-table>
    <mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
  </div>
</div>
<div mat-dialog-actions align="end" >
  <button mat-button type="button" (click)="cerrarDialogo()">Cancelar</button>
  <button mat-button type="button" (click)="confirmado()">Guardar</button>
</div>