<app-header-top-sessions *ngIf="!isLogged"></app-header-top-sessions>
<div style="background-color: var(--thm-sayu);"
	[style.margin-top]="(!isLogged)?'10em':''"
	fxFlex="100"
	fxLayout="column" 
	fxLayout.md="column"
	fxLayout.sm="column"
	fxLayout.xs="column"
	>
	<!--banner-->
	<div fxFlex="100">
		<div class="col-sm-12">
			 <div class="caption-2" style="width: 100%">
					<img src="assets/images/entrepeneurs/courses1.png" style="width: 100% !important; margin: 0px" class="img-full-width" alt="" />
			 </div>
		</div>
	</div>
	<!--/-->

	<!--Cursos-Adquiridos-->
	<div *ngIf="isLogged" id="adquired-courses" class="m-lr-10">
		<hr/>
		<h3 class="text-light">Mis Assets</h3>
		<!--Pagination-->
		<div *ngIf="acquiredCourses.length > 0" class="text-center">
			<pagination-controls id="pagination1" class="ngx-pagination" (pageChange)="changePagePaginator1($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
		</div>
		<!--/-->
		<!-- courses-container -->
		<div *ngIf="acquiredCourses.length > 0" class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}">
			<!-- Course Box -->
			<div class="product-wrap product-wrap-details m-1 course-container"
				*ngFor="let course of acquiredCourses | paginate: { id:'pagination1',itemsPerPage: itemsPerPageP1, currentPage: currentPageP1, totalItems: rowsNumberP1 }; let i = index;"
				[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
				<div fxLayout="column" fxFlex="100">
					<div 
						fxFlex="100" 
						fxLayout="column" 
						fxLayoutAlign="center center" 
						class="courses-one__single wow fadeInLeft"
						[routerLink]="['/dashboard/course', course.id]"
						>
						<div class="courses-one__single-img">
							<img [src]="course.imageUrl" alt="" />
							<div class="overlay-text" style="cursor: pointer;">
								<p [routerLink]="['/dashboard/course', course.id]">Ver detalles</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end course box -->
		</div>
		<!-- end courses-container -->
		<div *ngIf="acquiredCourses.length <= 0" class="text-light text-center">
			<h4>No has adquirido assets</h4>
		</div>
	</div>
	<!--/-->
	<div id="courses-available" class="m-lr-10">
		<hr/>
		<!--Cursos-Disponibles-->
		<h3 class="text-light">Assets disponibles</h3>
		<!--Pagination-->
		<div *ngIf="availableCourses.length > 0" class="text-center">
			<pagination-controls id="pagination2" class="ngx-pagination" (pageChange)="changePagePaginator2($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
		</div>
		
		<!-- courses-container -->
		<div *ngIf="availableCourses.length > 0" class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}">
			<!-- Course Box -->
			<div class="product-wrap product-wrap-details m-1 course-container"
				*ngFor="let course of availableCourses | paginate: { id:'pagination2',itemsPerPage: itemsPerPageP2, currentPage: currentPageP2, totalItems: rowsNumberP2 }; let i = index;"
				[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}"
				>
				<div fxLayout="column" fxFlex="100">
					<div 
						fxFlex="100" 
						fxLayout="column" 
						fxLayoutAlign="center center" 
						class="courses-one__single wow fadeInLeft"
						[routerLink]="['/studyPlan',course.id]"
						>
						<div class="courses-one__single-img" style="width: 100%;height: 100%;">
							<img [src]="course.imageUrl" alt="" />
							<div *ngIf="isLogged" class="overlay-text" style="cursor: pointer;">
								<p *ngIf="userHasLevelrequired(course?.courseLevelId)" (click)="buyCourse(course.id)">${{course?.salePrice}}&nbsp;{{currency}}</p>
								<p *ngIf="!userHasLevelrequired(course?.courseLevelId)" (click)="service.openDialog('Aún no puedes adquirir este curso')">${{course?.salePrice}}&nbsp;{{currency}}</p>
							</div>
							<div *ngIf="!isLogged" class="overlay-text" style="cursor: pointer;">
								<p [routerLink]="['/studyPlan',course.id]">${{course?.salePrice}}&nbsp;{{currency}}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end course box -->
		</div>
		<!-- end courses-container -->
		<div *ngIf="availableCourses.length <= 0" class="text-light text-center">
			<h4>No hay cursos disponibles</h4>
		</div>
		<hr/>
		<!--/-->
	</div>

	<app-footer *ngIf="!isLogged" fxFlex="100"></app-footer>
</div>
