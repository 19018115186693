import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService, ICatPaymentMethods, ICourse, IRelCoursesPaymentsMethods } from 'app/views/services/base.service';

@Component({
  selector: 'app-dialog-assign-payment-method',
  templateUrl: './dialog-assign-payment-method.component.html',
  styleUrls: ['./dialog-assign-payment-method.component.css']
})
export class DialogAssignPaymentMethodComponent extends BaseService implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogAssignPaymentMethodComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    injector: Injector
  ) {
    super(injector);
  }

  course:ICourse;
  paymentMethods:ICatPaymentMethods[] = [];
  coursePaymentMethods:IRelCoursesPaymentsMethods[] = [];
  ngOnInit(): void {
    try {
      this.course = this.data.course;
      this.paymentMethods = this.data.paymentMethods;
      this.coursePaymentMethods = this.data.coursePaymentMethods;
      //Descartamos los métodos de pago que el curso ya tenga vinculados
      let pmAux:ICatPaymentMethods[] = [];
      this.paymentMethods.forEach(
        (pm) => {
          let cpm:IRelCoursesPaymentsMethods = this.coursePaymentMethods.find(cpm => cpm.paymentMethodId === pm.id);
          if (!cpm) pmAux.push(pm);
        }
      )
      this.paymentMethods = pmAux;
      //Forzamos la elección de stripe ya que no tenemos más métodos de pago actualmente
      let pmStripe:ICatPaymentMethods = this.paymentMethods.find(pm => pm.id === 3);
      if (this.paymentMethods.length <= 0 || !pmStripe) {
        this.openDialog(`No hay métodos de pago disponibles para asignar al curso. Favor de verificar.`).subscribe(
          () => {this.close();}
        );
      }
      this.setPaymentMethod(pmStripe);
    } catch (error) {
      this.openDialog(`Ocurrió un error al iniciar asignación de métodos de pago: ${error.message}. Favor de verificar.`).subscribe(
        () => {this.close();}
      );
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  paymentMethod:ICatPaymentMethods;
  setPaymentMethod(paymentMethod:ICatPaymentMethods): void {
    this.paymentMethod = paymentMethod;
  }

  openDialogConfirm(): void {
    if (!this.paymentMethod) {
      this.service.openDialog(`No se ha elegido un método de pago.`);
    } else {
      let message:string = this.paymentMethod.id === 3 ? `Esta acción asignará el método de pago en 4x4 y creará el producto en la plataforma Stripe.` : '';
      this.openConfirm(`¿Seguro(a) que desea asignar el método de pago: ${this.paymentMethod.paymentMethod}?. ${message}`).subscribe(
        (response) => {
          if (response) this.dialogRef.close(this.paymentMethod);
        }
      );
    }
  }

}
