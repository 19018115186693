import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ComunicationService } from 'app/comunication.service';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';
import { Profile } from '../users/profile';

@Component({
  selector: 'app-admin-course-pupil',
  templateUrl: './admin-course-pupil.component.html',
  styleUrls: ['./admin-course-pupil.component.css']
})
export class AdminCoursePupilComponent implements OnInit {
  
  row:any;
  course:string="";
  displayedColumns: string[] = ['imageUrl', 'alumno','conectar'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  courseId:number=0;

  constructor(
    public service: AdminService,
    private paginatorService: PaginatorService,
    private loader:AppLoaderService,
    private router: Router,
    public dialog: MatDialog,
    public role:RoleService,
    public notificationC:ComunicationService,
    private activatedRoute: ActivatedRoute,
    private profileInfo: Profile,
  ) { }

  ngOnInit(): void {
    if (this.role.isInstructor()) {
      this.displayedColumns = ['imageUrl', 'alumno','email','conectar'];
    }
    this.courseId =0; 
    this.activatedRoute.paramMap.subscribe(params => {
      this.courseId = Number(params.get('id'));
      if (this.courseId) {
        this.getPupilByIdCourse(this.courseId);
      } else {
        this.service.openDialog('No fue posible obtener la información del curso.');
        this.router.navigateByUrl(this.getRedirectByRole());
      }
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }

  getRedirectByRole():string {
    return (this.role.isClient())?'/dashboard/courses':'/dashboard/cmsCourses';
  }

  getPupilByIdCourse(courseId:number){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getPupilByIdCourse(courseId).subscribe(
      (data) => {
        if(Object.keys(data.body.content).length < 1){
          this.loader.close();
          this.service.openDialog(`No hay ${(this.role.isClient())?'compañeros(as)':'alumnos(as)'} actualmente`).subscribe(
            () => {
              this.service.navigateTo(this.getRedirectByRole());
            }
          );
        }
        else{
          this.dataSource.data =  data.body.content;
          this.course = data.body.content[0].course;
          this.loader.close();
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  goToChat(row){
    let p={
      username : row.alumno,
      imagenURL : row.imageUrl
    };
    //console.log("row",row);
    // console.log("p",p);
    this.profileInfo.setinfoProfilePartner(p);
    this.router.navigate(['/dashboard/chatCourse',row.id,this.courseId]);
  }
  
  goToCourses(){
    let redirect:any[] =  (this.role.isClient())?['/dashboard/course',this.courseId]:['/dashboard/cmsCourses'];
    this.router.navigate(redirect);
  }

  getUserImage(url:string):string{
    if(url){
      return (url.includes(API.URL_BASE_STORAGE) || url.includes('http'))?url:API.URL_BASE_STORAGE+url;
    }
    return 'assets/images/entrepeneurs/not-found.png';
  }
}
