<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  class="container-sessions"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="background-color:none !important;"
    fxFlex="100"
    fxLayout="row"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <!---->
    <div 
      fxFlex="100"
      class="page-wrap"
      style="height: 100vh;"
      >
      <!---->
      <div class="session-form-hold"> 
        <div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
          data-wow-duration="2500ms">
          <div class="registration-one__right-form">
            <div class="title-box">
              <h4 style="color: white !important;">¿Olvidaste tu contraseña?</h4>
            </div>
            <div class="form-box">
              <form [formGroup]="form" (ngSubmit)="send()">
                <div class="form-group">
                  <input (input)="form.controls['email'].markAsTouched()" matInput type="text"  [formControl]="form.controls['email']" placeholder="Ingresa tu correo electrónico" value="">
                </div>
                <div class="form-group">
                  <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                    class="form-error-msg"> * Requerido</small>
                  <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                    class="form-error-msg"> * Correo Electrónico incorrecto</small>
                </div>
                <button mat-raised-button class="btn btn-purple full-width mb-1 rounded" [disabled]="form.invalid">Enviar código a mi E-mail</button>
                <div class="text-center">
                  <a [routerLink]="'/signin'" class="m-1 hyper mat-primary text-center full-width">Cancelar</a>
                  <span fxFlex></span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
  <div fxFlex="100" fxFlexFill>
    <app-footer></app-footer>  
  </div>
</div>