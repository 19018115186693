<div class="container-fluid" >
	<h4>Facturación
		<!-- <button mat-button class="btn-purple float-right">
			<mat-label>Hacer un pago</mat-label>
		</button> -->
	</h4>
	<hr/>
	<div fxFlexAlign="center" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column"  fxLayoutGap="20px">
		<div id="info-billing" fxFlex="30" class="mat-elevation-z4" fxLayout="column" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="30">
			<div class="p-3" style="height: fit-content; text-align: justify;">
				<mat-label class="fw-bold">Información básica</mat-label>
				<br/>
				<mat-label style="font-weight: bold;"> Nombre:  </mat-label>  <mat-label>{{infoUser?.name || '' }} {{infoUser?.lastName || '' }}</mat-label>
			</div>
		</div>
		<!---->
		<div id="purchase-history" class="mat-elevation-z4" fxFlex="70" fxLayout="column" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="70">
			<div class="p-3" fxFlex="30">
				<mat-label class="fw-bold">Facturas</mat-label>
				<br/>
				<mat-label>Historial de compras:</mat-label>
			</div>
			<div class="p-2 bg-light-gray" fxLayout.md="column" >
				<ng-container *ngIf="dataSource.data.length > 0 then showData else showMessage"></ng-container>
				<ng-template #showData>
					<div class="example-container mat-elevation-z8">
						<mat-table #table [dataSource]="dataSource">
							<ng-container matColumnDef="id">
								<mat-header-cell *matHeaderCellDef> # </mat-header-cell>
								<mat-cell *matCellDef="let row; let i = index"> 
								  <span class="mobile-label">#:</span>
								  {{i+1}} 
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="concept">
								<mat-header-cell *matHeaderCellDef> Concepto </mat-header-cell>
								<mat-cell *matCellDef="let element"> 
								  <span class="mobile-label">Nombre del Curso:</span>
								  {{element.courseName}} 
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="amount">
								<mat-header-cell *matHeaderCellDef> Monto </mat-header-cell>
								<mat-cell *matCellDef="let element"> 
								  <span class="mobile-label">Concepto:</span>
								  $&nbsp;{{element.finalAmount}} 
								</mat-cell>
							</ng-container>
							<ng-container matColumnDef="createdAt">
								<mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
								<mat-cell *matCellDef="let element"> 
								  <span class="mobile-label">Fecha:</span>
								  {{element.createdAt | date}}
								</mat-cell>
							</ng-container>
							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
						</mat-table>
						<!---->
						<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
					</div>
				</ng-template>
				<ng-template #showMessage>
					<div class="text-center">
						<b>No has adquirido cursos todavía.</b>
					</div>
				</ng-template>
			</div>
		</div>
	</div>
</div>
<hr/>
