import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit,EventEmitter, Injector } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { CatCourse } from "app/views/shared/interfaces";
import { FileUploader } from 'ng2-file-upload';


@Component({
  selector: 'app-dialog-course',
  templateUrl: './dialog-course.component.html',
  styleUrls: ['./dialog-course.component.css']
})
export class DialogCourseComponent extends BaseService implements OnInit {
  
  public form: FormGroup;
  title:string;
  niveles:[];
  instructores:[];
  arrayObjetFile:any[]=[];
  courseLevelId:number = null;
  showFromDB:boolean = true;

  constructor( public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CatCourse,
    injector:Injector
  ) { super(injector); }
    
  public uploader: FileUploader = new FileUploader({
    url: "",
    disableMultipart : false,
    autoUpload: false,
    method: 'post',
    itemAlias: 'attachment'
  });

  ngOnInit(): void {
    if(this.data.id==0){
      this.title = "Alta Curso"
    }else{
      this.title="Editar Curso";
      this.showFromDB = false;
    }
    this.form = new FormGroup({
      course: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(500),
      ]),
      nivelCursoSelected: new FormControl('', [
        Validators.required
      ]),
      userId: new FormControl('', [
        Validators.required
      ]),
      salePrice: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
      ]),
      IsCheckedPromo: new FormControl('', [
        Validators.required
      ]),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ]),
      imageUrl: new FormControl(''),
      videoUrl: new FormControl('',[])
    });
    this.form.controls.course.setValue(this.data.course);
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.salePrice.setValue(this.data.salePrice);
    this.form.controls.IsCheckedPromo.setValue((this.data.applyPromotion==0?false:true));
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
    this.form.controls.imageUrl.setValue(this.data.imageUrl);
    this.courseLevelId = this.data.courseLevelId;
    this.instructorId  = this.data.userId;
    this.arrayObjetFile=[];
    if(this.data.imageUrl!=""){ 
      let objet = {
        id:0,
        fileName : this.data.imageUrl,
        fileSize : 0,
        file:null,
        type:'image'
      };
      this.arrayObjetFile.push(objet);
    }
    if(this.data.videoUrl!=""){ 
      let objet = {
        id:0,
        fileName : this.data.videoUrl,
        fileSize : 0,
        file:null,
        type:'video'
      };
      this.arrayObjetFile.push(objet);
    }
    this.getCourseLevels();
  }

  cerrarDialogo(): void {
    this.dialogo.close(null);
  }

  confirmado(): void {
    var formulario = this.form.value;
    let dataSave = {
      id:this.data.id,
      courseLevelId : formulario.nivelCursoSelected,
      applyPromotion : formulario.IsCheckedPromo?1:0,
      active : formulario.IsCheckedActivo?1:0,
      course : formulario.course,
      salePrice : formulario.salePrice,
      userId:[formulario.userId],
      description:formulario.description,
      imageUrl:formulario.imageUrl,
      files:this.arrayObjetFile,
      courseId:this.data.id
    }
    if (this.arrayObjetFile.length <= 0) {
      this.openDialog("Elige una imágen para el curso");
      return;
    }
    //
    let message:string =  (this.data.id)?`actualizar el curso`:`crear el curso`; 
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          this.dialogo.close(dataSave);
        }
      }
    );
  }

  instructorId:number = null;
  getCourseLevels(){
    this.loader.open();
    this.service.getCourseLevels({courseId: this.data.id}).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.niveles = body.data.niveles;
        this.instructores = body.data.instructores;
        let instructorsIds = [];
        this.instructores.map((i:any)=>{
          if(i.selected == 1){
            instructorsIds.push(i.id)
          }
        });
        this.form.controls.userId.setValue('');
        if (this.data.id > 0) {
          this.form.controls.nivelCursoSelected.setValue(this.courseLevelId);
          this.form.controls.userId.setValue(this.data.userId);
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }
  public onFileSelected(event: EventEmitter<File[]>,type:string) {
    try {
      const file: File = event[0];
      let maxFileSize:number = 200;//50 MB
      let invalidFile:boolean = false;
      switch(type) {
        case 'image':
          if(
            file.name.split('.').pop() != "png" && 
            file.name.split('.').pop() != "jpg"
          ){
            invalidFile = true;
          }
          break;
        case 'video':
          if(
            file.name.split('.').pop() != "mp4" &&
            file.name.split('.').pop() != "mkv" 
          ){
            invalidFile = true;
          }
          break;
      }
      if (invalidFile) {
        this.openDialog(`Solo se aceptan archivos tipo ${type}.`);
        return;
      }
      const fileSizeInKB = Math.round(file.size / 1024);
      if(fileSizeInKB > (maxFileSize * 1024) ){
        this.openDialog("El archivo que desea subir supera los 200 MB intente con otro por favor.");
        return;
      }
      let fileFound:any = this.arrayObjetFile.find((v) => v.type == type);
      if (fileFound) {
        let index:number = this.arrayObjetFile.indexOf(fileFound);
        this.arrayObjetFile.splice(index,1);
      }
      //
      this.arrayObjetFile.push({
        id:0,
        fileName : file.name,
        fileSize : file.size,
        file:file,
        type:type
      });
      this.showFromDB = false;
    } catch (error) {
      this.openDialog(`Ocurrió un error al elegir archivo [${error.message}]`);
    }
  }

  public deleteFile(file) {
    switch(file.type) {
      case "image":
        this.form.controls.imageUrl.setValue("");
        break;
      case "video":
        this.form.controls.videoUrl.setValue("");
        break;
    }
    let fileFound:any = this.arrayObjetFile.find((f) => f.type == file.type);
    if (fileFound) {
      let index:number = this.arrayObjetFile.indexOf(fileFound);
      this.arrayObjetFile.splice(index,1);
    }
  }
}