import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogCourseDetailsComponent } from 'app/views/dialogs/dialog-course-details/dialog-course-details.component';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';
import { DATA_COURSES } from 'app/views/shared/constants';
import { Course } from 'app/views/shared/interfaces';
import { isArray } from 'util';
import { egretAnimations } from '../../../shared/animations/egret-animations';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { BaseService, IResponse } from 'app/views/services/base.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
  animations: [egretAnimations]
})
export class CoursesComponent extends BaseService implements OnInit {

  public viewMode: string = 'grid-view';//layout config
  public currentPageP1:number;//pagination config
  public itemsPerPageP1:number = 6;//pagination config
  public rowsNumberP1:number = 0;//pagination config
  public rowNumberInitP1:number = 1;//pagination config
  //
  public currentPageP2:number;//pagination config
  public itemsPerPageP2:number = 20;//pagination config
  public rowsNumberP2:number = 0;//pagination config
  public rowNumberInitP2:number = 1;//pagination config
  public isLogged:boolean = false;
  //
  public courses:Course[];
  public _courses:any[] = [];
  public acquiredCourses:Course[] = [];
  public availableCourses:Course[] = [];

  constructor(
    injector:Injector
  ) {
    super(injector);
    const userInfo = this.storage.getUserInfo();
    this.isLogged = (userInfo != null && userInfo != undefined);
  }

  ngOnInit(): void {
    if (this.isLogged) {
      this.getCourses();
    } else {
      this.getCoursesNotLoggedIn();
    }
    this.activatedRoute.queryParams.subscribe(
      (params:any) => {
        try {
          if (params.hasOwnProperty('success') && params.hasOwnProperty('message')) {
            let success:boolean = params.success === '1' ? true : false;
            let message:string  = String(params.message) || undefined;
            this.service.openDialog(message).subscribe(
              () => {}
            );
          }
        } catch (error) {
          console.log(error);
          this.service.openDialog(`Ocurrió un error al obtener la información del pedido, contacte con el equipo de soporte.`);
        }
      }
    );
  }

  showTutorial(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialCoursesIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialCoursesIsDisplayed','1');
      setTimeout(this.service.showTutorialCoursesClient, 1000);
    }
  }

  getCourses(){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCoursesByUser().subscribe(
      (response) => {
        loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        const acquiredCoyrses = body.data.acquiredCourses;
        if (acquiredCoyrses instanceof Array && acquiredCoyrses.length > 0){
          this.acquiredCourses = acquiredCoyrses;
        }
        const availableCourses = body.data.availableCourses;
        if (availableCourses instanceof Array && availableCourses.length > 0){
          this.availableCourses = availableCourses;
        } 
        if (this.acquiredCourses.length <= 0 && this.availableCourses.length <= 0) {
          this.service.openDialog(`No hay cursos para mostrar.`);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.proccessHttpResponse(http);
      },
      () => {
        this.showTutorial()
      }
    );
  }


  public getCoursesNotLoggedIn(){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCoursesNoLoggedIn().subscribe(
      (response) => {
        loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.availableCourses = body.data;
        this.scrollTop();
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.proccessHttpResponse(http);
      }

    );
  }

  splitCourses(){
    if (this.courses.length > 4) {
      let temp = [], courses:any[] = [];
      let i = 0;
      while (i < this.courses.length) {
        if (i % 4 == 0) {
          let k = i;
          let inc = i + 4;
          //console.log(k,inc);
          for (let j = k; j < inc; j++){
            if (this.courses[j] != undefined) {
              temp.push(this.courses[j]);
            }
          }
          courses.push(temp);
          temp = [];
          i += 4;
        } 
      }
      this._courses = courses;
    }
  }

  onImgError(event, id: any){
    
  }

  goToDetails(course_id){
    this.router.navigate(['/dashboard/course', course_id]);
  }

  changePagePaginator1(page:any){
    this.currentPageP1 = Number(page);
    this.rowNumberInitP1 = this.currentPageP1==1 ? 1 : ((this.currentPageP1-1) * this.itemsPerPageP1);
    this.scrollTop();
  }

  changePagePaginator2(page:any){
    this.currentPageP2 = Number(page);
    this.rowNumberInitP2 = this.currentPageP2==1 ? 1 : ((this.currentPageP2-1) * this.itemsPerPageP2);
    this.scrollTop();
  }

  getCourseDetails(courseId:number,acquiredCourse){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200 && data.body.content[0] != undefined){
          const course = data.body.content[0];
          this.openDialogCourseDetails(course,acquiredCourse);
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
              this.service.openDialog(http.error.content);
              break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  openDialogCourseDetails(course:any,acquiredCourse:boolean){
    const dialog = this.dialog.open(DialogCourseDetailsComponent, {
      data: {
        course:course,
        acquiredCourse:acquiredCourse
      },
      autoFocus: false
    }).afterClosed().subscribe(result => {
    });
  }

  userHasLevelrequired(levelRequiredId:number):boolean{
    const user:any = this.service.getUserInfo();
    return (user.userLevelId >= levelRequiredId)?true:false;
  }

  buyCourse(id:number){
    this.router.navigate(['/dashboard/course/buy/',id,{isMentoria: false}]);
  }

  getImage(url:string) {
    return API.URL_BASE_STORAGE+url;
  }

  public onImageError($event){
    console.log($event);
    
  }

  public checkImage(url:string){
    let urlImg = this.sanitizer.bypassSecurityTrustStyle(`url(${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""})`);
    
    let existe:boolean = true;
    var img = new Image();
    img.src = `${"img-src 'self' data:;" + (url != "")?API.URL_BASE_STORAGE+url:""}`;
    img.onerror = function() {
      existe = false;
    }
    return existe; 
  }

}
