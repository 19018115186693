import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService } from 'app/views/services/base.service';
import { CertificatesInstructor } from 'app/views/shared/classes';

@Component({
  selector: 'app-certification-dialog',
  templateUrl: './certification-dialog.component.html',
  styleUrls: ['./certification-dialog.component.css']
})
export class CertificationDialogComponent extends BaseService implements OnInit {
  
  public certificate:CertificatesInstructor = new CertificatesInstructor(0,'',0,1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public certificateEdit:CertificatesInstructor,
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    if(this.certificateEdit){
      this.certificate = this.certificateEdit;
    }
    let user = this.storage.getUserInfo();
    this.certificate.user_id = user.id;
  }

}
