import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { API } from '../../shared/api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { AdminService } from 'app/views/services/admin.service';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { M } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends BaseService implements OnInit {

  public userEmail;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  public form: FormGroup;
  public email;

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(this.email, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  send(){
    this.openConfirm(`Se enviará un código para restablecer la contraseña. ¿El E-mail "${this.form.controls.email.value}" es correcto?`)
    .subscribe(
      (response) => {
        if (response) {
          this.forgot();
        }
      }
    );
  }

  forgot() {
    this.loader.open();
    let data:any = this.form.value;
    this.service.forgot(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(body.message).subscribe(
          () => {
            if (body.success) this.navigateTo('/resetPwd');
          }
        );
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }


}
