<div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex>
    <div *ngIf="instructors.length == 0;" style="margin-left: 40%; margin-top: 20px;">
        <h3>No hay facilitadores cerca de ti.</h3>
    </div>
    <div fxFlex="23" fxFlex.xs="100" style="border: solid thin #000000; height: 250px;" class="m-2 mat-elevation-z2 bg-dark text-light"
        *ngFor="let instructor of instructors; let i = index;"
        [@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
        <div style="cursor: pointer;" fxLayout="column" fxFlex="100" fxLayoutAlign="center center" (click)="goToDetails(instructor.id)">
            <div style="display: flex; justify-content: center; align-items: center; min-width: 130px; min-height: 130px; max-width: 130px; max-height: 130px; overflow: hidden; border-radius: 50%;">
                <img class="featured-img" (error)="onImgError(instructor)" height="auto" width="130px" [src]="instructor?.imageUrl" alt="" style="max-width: max-content;">
            </div>
            <div class="container" [ngStyle.xs]="'margin-top:10px'">
                <p style="text-align: center;">{{instructor?.name}} {{instructor?.last_name}}</p>
                <p style="line-height: 1.5em; height: 3em; overflow: hidden;text-align: justify;">{{instructor?.description}}</p>
            </div>
        </div>
    </div>
</div>