import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-course-details',
  templateUrl: './dialog-course-details.component.html',
  styleUrls: ['./dialog-course-details.component.css']
})
export class DialogCourseDetailsComponent implements OnInit {

  course:any;
  acquiredCourse:boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogCourseDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.course = this.data.course;
    this.acquiredCourse = this.data.acquiredCourse;
  }

  goToCalendar(courseId){
    this.router.navigate(['/dashboard/calendar',courseId]);
    this.dialogRef.close();
  }
  goToListPupil(courseId){
    this.router.navigate(['/dashboard/cmsAlumnos',courseId]);
    this.dialogRef.close();
  }
  goToCourseDetails(course_id){
    if(this.acquiredCourse){
      this.router.navigate(['/dashboard/course', course_id]);
    }else{
      this.router.navigate(['/dashboard/studyPlan', course_id]);
    }
    this.dialogRef.close();
  }

}

export interface DialogData {
  course:any;
  acquiredCourse:boolean;
}
