import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { egretAnimations } from "app/shared/animations/egret-animations";
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { DateService } from 'app/views/services/date.service';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';
import { Subscription } from 'rxjs';
import { Profile, profileInfo } from '../profile';

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.css'],
  animations: egretAnimations
})
export class WallComponent implements OnInit {

  posts:any = [];
  currentPage = 0;
  showLoadMore:boolean = false;
  user:any;
  imageUrl:string = 'assets/images/entrepeneurs/profile.png';
  activitiesCount:number = 0;

  constructor(
    private service: AdminService,
    private loader: AppLoaderService,
    public dateService: DateService,
    private profileInfo: Profile,
    public role: RoleService
  ) {}

  ngAfterViewInit() {

  }
  
  dataProfileSubscription:Subscription;
  ngOnInit() {
    this.user = this.service.getUserInfo();
    this.dataProfileSubscription = this.profileInfo.profileInfoUser$.subscribe(
      (data:profileInfo) => {
        let rutaimagen = '';
        if (data.imagenURL){
          rutaimagen = data.imagenURL;
        } else {
          let user = JSON.parse(localStorage.getItem('userInfo'));
          rutaimagen = user.imageUrl;
        }
        this.imageUrl = (rutaimagen) ? API.URL_BASE_STORAGE + rutaimagen : 'assets/images/entrepeneurs/not-found.png';
    });
    this.getPurchases();
  }

  getPendingActivities(){
    this.loader.open('Cargando actividades pendientes');
    const loader = this.loader.getDialog();
    this.service.getPendingActivities(this.user.id).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200){
          const content:any = data.body.content[0];
          const activitiesCount = Number(content.activitiesCount);
          if (activitiesCount){
            this.activitiesCount = activitiesCount;
          }
          this.loadMorePosts();
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  purchasesCount:number = 0;
  getPurchases():void{
    //const purchases = this.user.paymentHistories;
    //return (purchases && purchases.length > 0)?purchases.length:0;
    this.loader.open('Cargando cursos adquiridos');
    const loader = this.loader.getDialog();
    this.service.getPurchases(this.user.id).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200){
          //this.loadMorePosts();
          const content:any[] = data.body.content;
          if (content){
            this.purchasesCount = content.length;
          }
          this.getPendingActivities();
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
    
  }

  loadMorePosts(){
    this.loader.open('Cargando publicaciones');
    const loader = this.loader.getDialog();
    this.service.getChatPosts(this.currentPage).subscribe(
      (data) => {
        loader.close();
        if (data.status == 200){
          const content:any[] = data.body.content;
          if (content.length == 0) {
            this.service.openSnackBar('No hay más publicaciones para mostrar');
            this.showLoadMore = false;
          } else {
            content.forEach(post => {
              if (post.resourceUrl) {
                post.resourceUrl = API.URL_BASE_STORAGE+post.resourceUrl;
              }
              if(post.roleId == 2){
                post.name = "Facilitador: " + post.name;
              }
              this.posts.push(post);
            });
            this.currentPage++;
            this.showLoadMore = true;
          }
          
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.posts = [];
        this.currentPage = 0;
        this.showLoadMore = false;
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          case 500:
            this.service.openDialog(http.error.content);
            break;
          default:
            this.service.openDialog('Error con el servicio.');
            break;
        }
      }
    );
  }

  getUserImage(url:string):string{
    if(url){
      return (url.includes(API.URL_BASE_STORAGE) || url.includes('http'))?url:API.URL_BASE_STORAGE+url;
    }
    return 'assets/images/entrepeneurs/not-found.png';
  }

}
