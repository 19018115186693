import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { modalMultimedia } from 'app/views/shared/classes';

@Component({
  selector: 'app-dialog-calendarinfo',
  templateUrl: './dialog-calendarinfo.component.html',
  styleUrls: ['./dialog-calendarinfo.component.css']
})
export class DialogCalendarinfoComponent implements OnInit {
  multimedia:modalMultimedia;
  constructor(@Inject(MAT_DIALOG_DATA) public data:modalMultimedia) { }

  ngOnInit(): void {
    if(this.data){
      this.multimedia = this.data;
    }
  }

}
