import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Renderer2} from '@angular/core';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-finish-dialog',
  templateUrl: './finish-dialog.component.html',
  styleUrls: ['./finish-dialog.component.css']
})
export class FinishDialogComponent implements OnInit {
  
  
  constructor(private renderer: Renderer2,private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.frame();
    setTimeout(()=> {this.frame()},5000);
  }

  renderExternalScript(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  

  public frame() {
    
    // let colors = ["#8b5642", "#6a696b"];
    // confetti.create()({
    //   particleCount: 2,
    //   angle: 60,
    //   spread: 55,
    //   origin: { x: 0 },
    //   colors: colors,
    // });

    // confetti.create()({
    //   particleCount: 2,
    //   angle: 120,
    //   spread: 55,
    //   origin: { x: 1 },
    //   colors: colors,
    // });
  
    // if (Date.now() < Date.now() + 15000) {
    //   requestAnimationFrame(this.frame.bind(this));
    // }
    
    // const canvas = this.renderer.createElement('canvas');

    var canvas = document.getElementById('my-canvas');
    //this.renderer.appendChild(this.elementRef.nativeElement, canvas);
 
    const myConfetti = confetti.create(canvas, {
      resize: true // will fit all screen sizes,
    });

 
    myConfetti();

    //  if (Date.now() < Date.now() + 15000) {
    //   myConfetti();
    // }
 
  
  }

  


}
