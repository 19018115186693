import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    public router:Router,
    private dialog:MatDialog,
    public service: AdminService
  ) { 
  }

  isLogged:boolean = false;
  ngOnInit() {
    let userInfo:any = JSON.parse(localStorage.getItem('userInfo'));
    this.isLogged = (userInfo && userInfo.hasOwnProperty('access_token'))?true:false;
  }

  openNewTab(url:string):void {
    window.open(url, '_blank');
  }

  openDialogTerms():void {
    this.service.openDialogTerms();
  }

  openDialogPrivacy():void {
    this.service.openDialogPrivacy();
  }

}
