<div fxLayout="row" fxLayoutAlign="space-between center" >
    <p mat-dialog-title class="text-purple text-bold" style="margin: 0px;">Agregar certificado</p>
    <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false"  >
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content fxLayout="column">
    <mat-label>Seleccione imagen</mat-label>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="certificate.image_id">
        <div fxLayout="column">
            <div fxLayout="row">
                <mat-button-toggle value="1"><img src="assets/images/certifications/1.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="2"><img src="assets/images/certifications/2.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="3"><img src="assets/images/certifications/3.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="4"><img src="assets/images/certifications/4.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="5"><img src="assets/images/certifications/5.png" width="40" height="40"></mat-button-toggle>
            </div>
            <div fxLayout="row">
                <mat-button-toggle value="6"><img src="assets/images/certifications/6.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="7"><img src="assets/images/certifications/7.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="8"><img src="assets/images/certifications/8.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="9"><img src="assets/images/certifications/9.png" width="40" height="40"></mat-button-toggle>
                <mat-button-toggle value="10"><img src="assets/images/certifications/10.png" width="40" height="40"></mat-button-toggle>
            </div>
        </div>
    </mat-button-toggle-group>
    <mat-form-field>
        <mat-label>Nombre del certificado</mat-label>
        <input matInput [(ngModel)]="certificate.certification">
    </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="center center">
    <button mat-button class="btn-purple" [mat-dialog-close]="false">Cancelar</button>
    <button mat-button class="btn-green" [mat-dialog-close]="certificate" cdkFocusInitial>Agregar</button>
</div>