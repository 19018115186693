import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { CertificatesInstructor } from 'app/views/shared/classes';
import { Profile } from '../profile';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { API } from '../../../shared/api';
import { CertificationDialogComponent } from 'app/views/dialogs/certification-dialog/certification-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseService implements OnInit {

  public form: FormGroup;
  public imageUrl:any = 'assets/images/entrepeneurs/not-found.png';
  public imagePath:any;
  public message:any = 'Seleccione una foto.';
  public infoUser:any;
  public imageProfileFile:any;
  public certifications:Array<CertificatesInstructor>;
  public countriesArray:any;
  public statesArray:any;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  public isMentory:boolean=false;
  public courseId_:any= -1;
  public fromCompraCursos:boolean = false;

  constructor(
    injector:Injector
  ) { super(injector); }

  ngOnInit(): void {
    this.createForm();
    let userSession = this.storage.getUserInfo();
    this.infoUser = {
      id: userSession.id,
      name: userSession.name,
      lastname: userSession.lastname,
      username: userSession.username,
      email: userSession.email,
      telephone: userSession.telephone,
      description: userSession.description,
      genderId: userSession.genderId,
      birthdate: userSession.birthdate,
      state_id: userSession.state_id,
      city: userSession.city,
      country: userSession.country,
      interests: userSession.interest,
      password:'',
      newPassword:'',
      roleId: userSession.roleId,
      imageUrl: userSession.imageUrl,
      profile_public: userSession.profile_public,
      title: userSession.title,
    };
    this.imageUrl = API.URL_BASE_STORAGE+this.infoUser.imageUrl;
    this.form.controls.name.setValue(this.infoUser.name);
    this.form.controls.lastname.setValue(this.infoUser.lastname);
    this.form.controls.description.setValue(this.infoUser.description);
    this.form.controls.country.setValue(this.infoUser.country);
    this.form.controls.state_id.setValue(this.infoUser.state_id);
    this.form.controls.profile_public.setValue((this.infoUser.profile_public==1));
    this.form.controls.interests.setValue(this.infoUser.interests);
    this.form.controls.title.setValue(this.infoUser.title);
    this.courseId_ = this.activatedRoute.snapshot.queryParamMap.get('courseId');
    this.isMentory =  this.activatedRoute.snapshot.queryParamMap.get('isMentory') != null ? JSON.parse(this.activatedRoute.snapshot.queryParamMap.get('isMentory'))  : false ;
    if(this.infoUser.roleId==2){
      this.getinfoInstructor(userSession.id);
    }else{
      this.getCountries();
    }
  }

  getinfoInstructor(id:number) {
    this.loader.open();
    this.service.getInfoInstructor(id).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.certifications = body.data.certifications;
        this.getCountries();
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      },
    )
  }

  createForm(){
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(2000)
      ]),
      country: new FormControl('', [
      ]),
      state_id: new FormControl('', [
      ]),
      profile_public: new FormControl('', [
        Validators.required,
      ]),
      password: new FormControl('', [
        Validators.maxLength(50)
      ]),
      password_new: new FormControl('', [
        Validators.maxLength(50)
      ]),
      password_confirm: new FormControl('', [
        Validators.maxLength(50),
      ]),
      image: new FormControl('', [
      ]),
      interests: new FormControl('', [
        Validators.maxLength(2000)
      ]),
      title: new FormControl('', [
        Validators.maxLength(2000)
      ]),
    });
  }

  onFileChanged(event) {
    const files = event.target.files;
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.service.openDialog('Archivo incorrecto, sube una foto por favor');
      return;
    }
    this.imageProfileFile = files[0];
    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imageUrl = reader.result; 
      this.message = 'Foto seleccionada.'
      this.form.controls['image'].setValue(this.imageUrl);
    }
  }

  fileUploaded():boolean{
    return (this.imagePath != null && this.imagePath.length > 0)?true:false;
  }

  removeFile(){
    this.imagePath = null;
    this.imageUrl = 'assets/images/entrepeneurs/not-found.png';
    this.message = 'Seleccione una foto.'
    this.imageProfileFile = null;
  }

  uploadImageProfile(){
    this.openConfirm(`¿Seguro(a) que desea actualizar su foto de perfil?`).subscribe(
      (response) => {
        if (response) {
          this.loader.open();
          let formData = new FormData();
          formData.append('imagen',this.imageProfileFile);
          formData.append('idUser',this.infoUser.id);
          this.service.updateImageProfile(formData).subscribe(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.openDialog(body.message).subscribe(
                () => {
                  if (body.success) {
                    this.imageProfileFile = null;
                    this.imagePath = null;
                    let userSession = this.storage.getUserInfo();
                    userSession.imageUrl = body.data;
                    this.infoUser.imageUrl = body.data;
                    this.imageUrl = API.URL_BASE_STORAGE+userSession.imageUrl;
                    this.profile.setinfoProfile({ username: this.infoUser.username, imagenURL: body.data });
                    this.storage.setUserInfo(userSession);
                  }
                }
              );
            },
            (error:HttpErrorResponse)=>{
              this.loader.close();
              this.proccessHttpResponse(error);
            }
          );
        }
      }
    );
  }

  openConfirmDialog(){
    if (this.form.valid){
      
      const dialog = this.dialog.open(DialogConfirmComponent, {
        data: {
          title: '',
          message: '¿Seguro(a) que desea guardar los cambios?',
          confirm: true
        }
      }).afterClosed().subscribe(result => {
        if (result == true){
          this.loader.open();
          this.infoUser.name = this.form.controls.name.value;
          this.infoUser.lastname = this.form.controls.lastname.value;
          this.infoUser.description = this.form.controls.description.value;
          this.infoUser.country = this.form.controls.country.value;
          this.infoUser.state_id = this.form.controls.state_id.value;
          this.infoUser.password = this.form.controls.password.value;
          this.infoUser.newPassword = this.form.controls.password_new.value;
          this.infoUser.interest = this.form.controls.interests.value;
          this.infoUser.profile_public = this.form.controls.profile_public.value;
          this.infoUser.title = this.form.controls.title.value;
          this.infoUser.certifications = this.certifications;
          this.service.updateProfile(this.infoUser).subscribe(
            (response)=>{
              this.loader.close();
              let body:IResponse = response.body;
              this.openDialog(body.message).subscribe(
                () => {
                  if (body.success) {
                    let userSession = this.storage.getUserInfo();
                    userSession.name = this.infoUser.name;
                    userSession.lastname = this.infoUser.lastname;
                    userSession.description = this.infoUser.description;
                    userSession.country = this.infoUser.country;
                    userSession.state_id = this.infoUser.state_id;
                    userSession.interest = this.infoUser.interest;
                    userSession.profile_public = (this.infoUser.profile_public ? 1 : 0);
                    userSession.title = this.infoUser.title;
                    userSession.countryId = this.infoUser.country;
                    this.storage.setUserInfo(userSession);
                    if(this.courseId_ != null){
                      this.router.navigate(['/dashboard/course/buy/',this.courseId_,{isMentoria: this.isMentory}]);
                    }else{
                      window.location.reload();
                    }  
                  }
                }
              );
            },
            (error:HttpErrorResponse)=>{
              this.loader.close();
              this.proccessHttpResponse(error);
            }
          );
        }
      });
    } else {
      this.form.markAllAsTouched();
      this.scrollTop();
      this.openSnackBar(`Complete el formulario por favor`);
    }
  }

  addCertification(): void {
    const dialogRef = this.dialog.open(CertificationDialogComponent, {
      disableClose: true,
      width: '350px',
      data: null,autoFocus:false
    });

    dialogRef.afterClosed().subscribe(certification => {
      if (certification) {
        this.certifications.push(certification);
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.certifications.push({certification: value.trim(), id: 0, user_id: this.infoUser.id, image_id:1});
    }
    if (input) {
      input.value = '';
    }
  }

  remove(certifications: CertificatesInstructor): void {
    const index = this.certifications.indexOf(certifications);
    if (index >= 0) {
      this.certifications.splice(index, 1);
    }
  }

  getCountries(){
    this.loader.open();
    this.service.getCountries().subscribe(
      (data:any)=>{
        this.countriesArray = (data.success==1) ? data.data : [];
        let countryId = Number(this.infoUser.country);
        if(countryId>0){
          this.getStates(countryId);
        }else{
          this.loader.close();
        }
      },
      (error)=>{
        // this.service.openDialog('recarge la pagina nuevamente');
        this.loader.close();
      }
    );
  }

  public getStates(countryId:any,loader:boolean=false){
    if(loader) this.loader.open();
    this.service.getStates(countryId).subscribe(
      (data:any)=>{
        this.statesArray = (data.success==1) ? data.data : [];
      },
      (error)=>{},
      ()=>{
        this.loader.close();
      }
    );
  }

}
