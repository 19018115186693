import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { Activity } from 'app/views/shared/interfaces';
import { DialogCourseComponent } from '../dialog-course/dialog-course.component';
import { DatePipe } from '@angular/common'
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import moment from 'moment';

@Component({
  selector: 'app-dialog-activity',
  templateUrl: './dialog-activity.component.html',
  styleUrls: ['./dialog-activity.component.css']
})
export class DialogActivityComponent implements OnInit {
  public form: FormGroup;
  title:string;
  temas:[];
  constructor(
    public dialogo: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Activity,
    public datepipe: DatePipe,
    private loader:AppLoaderService,
    private service: AdminService,
    private dialog: MatDialog
  ) { }

  students:any[] = [
  ];
  allowedGroups:number[] = [];
  minAllowed:number = 12;
  maxAllowed:number = 24;
  divisorsList:any[] = [];
  divisors = (n)=>[...Array(n+1).keys()].slice(1)
       .reduce((s, a)=>{
          var divisor = !(n % a) && a;
          if (divisor) this.divisorsList.push(divisor);
          return s+divisor;
       }, 0);
  getAllowedGroups(n:number) {
    this.divisors(n);
    this.divisorsList.forEach(
      (divisor:number) => {
        let students:number = n / divisor;
        if (students >= this.minAllowed && students <= this.maxAllowed) {
          this.allowedGroups.push(divisor);
        }
      }
    );
  }
  groups:number;
  setGroups(groups:number) {
    this.groups = groups;
  }
  
  userInfo:any;
  ngOnInit(): void {
    this.userInfo = this.service.getUserInfo();
    if(this.data.id==0){
      this.title = "Alta Actividad"
    }else{
      this.title="Editar Actividad";
    }

    this.form = new FormGroup({
      activity: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      activityDate: new FormControl({ value: '', disabled: true }, [
      Validators.required]
      ),

      socialLink: new FormControl('', [
        Validators.required
      ]),
      topicRequiredId: new FormControl('0'),
      IsCheckedActivo: new FormControl('', [
        Validators.required
      ])

    });

    this.form.controls.activity.setValue(this.data.activity);
    this.form.controls.activityDate.setValue(this.data.activityDate);
    this.form.controls.activityDate.enable();
    this.form.controls.socialLink.setValue(this.data.socialLink);
    this.form.controls.topicRequiredId.setValue(this.data.topicRequiredId);
    if (this.data.id > 0) this.form.controls.topicRequiredId.disable();
    this.form.controls.IsCheckedActivo.setValue((this.data.active==0?false:true));
    this.getTopicAll();
  }

  cerrarDialogo(): void {
    this.dialogo.close(null);
  }

  confirmado(): void {
    this.form.controls.topicRequiredId.enable();
    var formulario = this.form.value;
    moment.locale('es-MX');
    let latest_date = moment(formulario.activityDate).format('yyyy-MM-DD HH:mm:ss'); 
    let offset = new Date().getTimezoneOffset() * -1;
    let dataSave ={
      id:this.data.id,
      courseId :this.data.courseId,
      activity : formulario.activity,
      activityDate : latest_date,
      socialLink : formulario.socialLink,
      topicRequiredId : formulario.topicRequiredId,
      active : formulario.IsCheckedActivo?1:0,
      offset: offset,
      students:JSON.stringify(this.students),
      topicId:formulario.topicRequiredId,
      instructorId:this.userInfo.id
    }
    //
    let message:string =  (this.data.id)?`actualizar la actividad`:`crear la actividad`; 
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          this.dialogo.close(dataSave);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    ); 
  }

  getTopicAll(){
    this.loader.open();
    this.service.getTopics(this.data.courseId).subscribe(
      (response) => {
        this.loader.close();
        if (response.status == 200) {
          this.temas = response.body.content.topics;
          if (this.temas.length <= 0) this.service.openDialog(`No hay temas para elegir.`,'Mensaje del sistema:',`Verifique con el administrador para registrar un tema en el módulo correspondiente`).subscribe(
            () => {this.dialogo.close();}
          );
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  createActivity:boolean = false;
  group:any[] = [];
  createGroup(students:any[]) {
    this.group = [];this.createActivity = false;
    if (students.length >= this.minAllowed || students.length <= this.maxAllowed) {
      this.createActivity = true;
      for (let index = 0; index < students.length && index < this.maxAllowed; index++) {
        this.group.push(students[index]);
      }
    }
  }

  getAvailableUsersByTopic(topicId) {
    if (this.data.id == 0) {
      this.loader.open();
      this.service.getAvailableUsersByTopic(this.data.courseId,topicId).subscribe(
        (response) => {
          this.loader.close();
          if (response.status == 200) {
            this.students = response.body.content;
            this.createGroup(this.students);
          }
        },
        (http: HttpErrorResponse) => {
          this.loader.close();
          console.log(http);
          this.service.processHttpResponse(http);
        }
      );
    }
  }

}
