<div class="header mat-elevation-z2 fixed" fxFlex.sm="100"  fxFlex.xs="100">
  <div class="topnav" fxLayout="row" fxLayoutAlign="space-between end" fxFlex="100"  fxFlex.xs="100" >
    <ul class="menu" fxFlex>
      <li *ngFor="let item of menuItems; let i = index;" [attr.id]="item.name">
        <div class="text-center" *ngIf="item.type !== 'separator'" routerLinkActive="open">
          <a matRipple routerLink="/{{item.state}}" *ngIf="item.type === 'link'">
            <mat-icon *ngIf="item.icon != '' && item.icon != undefined">{{item.icon}}</mat-icon> 
            {{item.name | translate}}
          </a>
          <div *ngIf="item.type === 'dropDown'">
            <label matRipple for="drop-{{i}}" class="toggle"><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</label>
            <a matRipple><mat-icon>{{item.icon}}</mat-icon> {{item.name | translate}}</a>
            <input type="checkbox" id="drop-{{i}}" />
            <ul>
              <li *ngFor="let itemLvL2 of item.sub; let j = index;" routerLinkActive="open">
                <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}" 
                *ngIf="itemLvL2.type !== 'dropDown'">
                  <mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  
                  {{itemLvL2.name | translate}}
                </a>
                
                <div *ngIf="itemLvL2.type === 'dropDown'">
                  <label matRipple for="drop-{{i}}{{j}}" class="toggle">{{itemLvL2.name | translate}}</label>
                  <a matRipple><mat-icon *ngIf="itemLvL2.icon">{{itemLvL2.icon}}</mat-icon>  {{itemLvL2.name | translate}}</a>
                  <input type="checkbox" id="drop-{{i}}{{j}}" />
                    <!--Level 3 -->
                  <ul>
                    <li *ngFor="let itemLvL3 of itemLvL2.sub" routerLinkActive="open">
                      <a matRipple routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                        <mat-icon *ngIf="itemLvL3.icon">{{itemLvL3.icon}}</mat-icon>
                        {{itemLvL3.name | translate}}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
    <div fxFlex="0" fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="infoUser && role.isClient() && !layoutConf.isMobile" 
        mat-button 
        style="height: 100%; font-weight: bold; vertical-align: middle !important; margin-bottom: 5px;margin-right: 0px;" 
        (click)="showTutorial()">
        <mat-label class="m-1">Guia</mat-label> 
        <mat-icon class="color-purple">book</mat-icon>
      </button>
      <div *ngIf="!infoUser"></div>
      <!--
      <button mat-button style="height: 100%; font-weight: bold; vertical-align: middle !important; margin-bottom: 5px;">
        <mat-label class="m-1">Ayuda</mat-label> 
        <mat-icon class="color-purple">help</mat-icon>
      </button>
      -->
    </div>
  </div>
</div>
<div class="separator" [ngStyle.xs]="'margin-bottom:1em'"></div>