import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService } from './shared/services/route-parts.service';
import { filter } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { AdminService } from './views/services/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppLoaderService } from './shared/services/app-loader/app-loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    appTitle = 'Sayucfox';
    pageTitle = '';
    isPopState = false;
    echoEvent: any = null;
    constructor(
        public title: Title,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routePartsService: RoutePartsService,
        public services: AdminService,
        private loader: AppLoaderService,
        // private themeService: ThemeService,
        // private layout: LayoutService,
        // private renderer: Renderer2,
        private locStrat: LocationStrategy
    ) {}

    ngOnInit() {
        this.changePageTitle();
        //Scroll to top on change ROUTE
        this.locStrat.onPopState(() => {
            this.isPopState = true;
        });
        this.router.events.subscribe(event => {
            // Scroll to top if accessing a page, not via browser history stack
            if (event instanceof NavigationEnd && !this.isPopState) {
                window.scrollTo(1, 1);
                this.isPopState = false;
            }
            // Ensures that isPopState is reset
            if (event instanceof NavigationEnd) {
                this.isPopState = false;
            }
            this.restoreScroll();
        });
    }

    restoreScroll(): void {
        setTimeout(() => {
            document.scrollingElement.scrollTo({ 
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
            });
        }, 500);
    }

    ngAfterViewInit(): void {
        this.restoreScroll();
    }

    ngAfterViewChecked(){
        /*if (this.echoEvent==null && this.services.issetUserInfo()) {
            this.echoEvent = new Echo((API.PROD)?ECHO_PROD:ECHO_TEST);
            let user = this.services.getUserInfo();
            this.echoEvent.channel('change-session-' + user.id)
                .listen('SessionEvent', (response) => {
                    if (response.data && response.data.success && this.services.issetUserInfo()) {
                        this.services.openDialog(response.data.message,'Alerta!');
                        this.logout();
                    }
                }).error((error) => { console.log(error);
                });
        }*/
    }

    ngOnDestroy(){
        this.eliminarEventoSession();
    }
    
    eliminarEventoSession(){
        if (this.services.issetUserInfo()) {
            let user = this.services.getUserInfo();
            this.echoEvent.leaveChannel('change-session-'+user.id);
            this.echoEvent = null;
        }
    }

    logout(){
        // this.loader.open('Cerrando Sesión');
        // const loader = this.loader.getDialog();
        this.eliminarEventoSession();
        this.services.logout().subscribe(
          (data) => {
            // loader.close();
            //console.log(data);
            if (data.status == 200){
              this.services.clearUserInfo();
              this.services.openSnackBar('Sesión cerrada correctamente, ¡Vuelva pronto!');
              this.services.navigateTo('/signin');
            }
          }, 
          (http: HttpErrorResponse) => {
            // loader.close();
            switch (http.status) {
              case 401:
                this.services.openDialog('Operación no permitida');
                this.services.clearUserInfo();
                this.services.navigateTo('/signin');
                break;
              default:
                this.services.openDialog('Error con el servicio.');
            }
          }
        );
        
      }

    changePageTitle() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
            const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
            if (!routeParts.length) {
                return this.title.setTitle(this.appTitle);
            }
            // Extract title from parts;
            this.pageTitle = routeParts
                .reverse()
                .map((part) => part.title)
                .reduce((partA, partI) => `${partA} > ${partI}`);
            this.pageTitle += ` | ${this.appTitle}`;
            this.title.setTitle(this.pageTitle);
        });
    }
}
