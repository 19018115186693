import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogModuleComponent } from 'app/shared/components/dialog-module/dialog-module.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { Module } from "app/views/shared/interfaces";
@Component({
  selector: 'app-admin-module',
  templateUrl: './admin-module.component.html',
  styleUrls: ['./admin-module.component.css']
})
export class AdminModuleComponent extends BaseService implements OnInit {
  row:Module;
  public courseId:number=0;
  displayedColumns: string[] = [ 'module','description','activeLabel','Borrar','Editar','Temas'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    injector:Injector
  ) { super(injector); }

  async ngOnInit() {   
    this.courseId =0; 
    await this.activatedRoute.paramMap.subscribe(async params => { 
      this.courseId = await Number(params.get('id'));
      if (this.courseId>0) {; 
        this.dataSource.data = await []; 
        await this.getModule(this.courseId); 
      } else {
        this.openDialog('No fue posible obtener la información del módulo.');
        this.router.navigateByUrl('/dashboard/cmsCourses');
      }
    });
  }
  
  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }

  getModule(courseId:number){
    this.loader.open();
    this.service.getModelesXIdCatCurse(courseId).subscribe(
      (response) => { 
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.dataSource.data = body.data;
      }, 
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  public onClickNuevo() {
    let item:any = {
      id : 0,
      module : '',
      description : '',
      active : 1,
      courseId : this.courseId
    }
     this.row = item;
    this.openDialogModule();
  }
  saveModule(data){
    this.loader.open();
    this.service.createModule(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getModule(this.courseId);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }
  onClickEdit(item:any){
    this.row =item;
    this.openDialogModule();
  }
  public openDialogModule() {
    this.dialog.open(DialogModuleComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          if(this.row.id==0){
            this.saveModule(data);
          }else{
            
            this.updateModule(data);
          }
        } 
      });
  }
  updateModule(data){
    this.loader.open();
    this.service.updateModule(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getModule(this.courseId);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }
  deleteModule(data){
    this.row = data;
    this.loader.open();
    this.service.deleteModule(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.getModule(this.courseId);
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }
  goToCatCourse(){
    this.router.navigate(['/dashboard/cmsCourses']);
  }
  goToTopics(moduloId){
    this.router.navigate(['/dashboard/cmsTopic',moduloId,this.courseId]);
  }
  onClickDelete(item:any){
    this.row =item;
    this.openConfirm(`¿Seguro(a) que desea eliminar el módulom [${item.module}]?`).subscribe(
      (response) => {
        if (response) this.deleteModule(item);
      }
    );
  }
  public openDialogConfirm(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteModule(this.row);
        } 
      });}
  
  
  dialogCongfirm(message:string){
    this.openDialog(message)
    .subscribe(
      (response) => {
        this.getModule(this.courseId);
      }
    )
  }
}
