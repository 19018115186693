import { Injectable } from '@angular/core';
import { User } from '../shared/interfaces';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  /*
    1	Cliente
    2	Instructor
    3	Administrador
  */

  constructor(
    private storage:StorageService
  ) { }

  public getSession():any{
    let user = this.storage.getUserInfo();
    return (user)?user:undefined;
  }

  public getRoleBySession():number{
    let session:any = this.getSession();
    const role:number = (session)?session.roleId:0;
    return role;
  }

  public isClient():boolean{
    let roleId = this.getRoleBySession();
    return (roleId == 1)?true:false;
  }

  public isInstructor():boolean{
    let roleId = this.getRoleBySession();
    return (roleId == 2)?true:false;
  }

  public isAdmin():boolean{
    let roleId = this.getRoleBySession();
    return (roleId == 3)?true:false;
  }

}
