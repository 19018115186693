import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { API } from 'app/views/shared/api';

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.css'],
  animations: [egretAnimations]
})
export class InstructorsComponent extends BaseService implements OnInit {
  public instructors:Array<any> = [];
  
  constructor(
    injector:Injector
  ) { super(injector); }

  userInfo:any = {};
  ngOnInit(): void {
    this.userInfo = this.storage.getUserInfo();
    if (this.userInfo.country) {
      this.getInstructors();
    }
  }

  ngAfterViewInit(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialInstructorsIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialInstructorsIsDisplayed','1');
      this.service.showTutorialInstructorsClient();
    }
  }

  getInstructors(){
    this.loader.open();
    this.service.getInstructors().subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.instructors = body.data;
        if (this.instructors.length > 0){
          this.instructors.map((instructor)=>{
            instructor.image_url = API.URL_BASE_STORAGE+instructor.image_url;
          })
        }
      },
      (http:HttpErrorResponse) => {
        this.loader.close()
        this.proccessHttpResponse(http);
      },
    )
  }

  onImgError(instructor:any){
    instructor.imageUrl = 'assets/images/perfil-usuario.png';
  }

  goToDetails(id:number){
    this.router.navigate(['/dashboard/instructor', id]);
  }

}
