import { Component, Injector, OnInit } from '@angular/core';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent extends BaseService implements OnInit {

  constructor(
    injector: Injector
  ) { 
    super(injector);
  }

  userInfo:any;
  ngOnInit(): void {
    this.userInfo = this.storage.getUserInfo();
    if (this.userInfo) {
      let url:string = this.storage.getCheckoutUrl();
      this.userInfo.checkoutUrl = url;
    }
  }

  openCheckoutUrl(): void {
    window.location.href = this.userInfo.checkoutUrl;
  }

}
