import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService, ICourse, IResponse, IStripePrice } from 'app/views/services/base.service';
import { Observable } from 'rxjs';
import { DialogCourseComponent } from '../dialog-course/dialog-course.component';

@Component({
  selector: 'app-dialog-promotion',
  templateUrl: './dialog-promotion.component.html',
  styleUrls: ['./dialog-promotion.component.css']
})
export class DialogPromotionComponent extends BaseService implements OnInit {

  public form: FormGroup;
  title:string;
  tipoPromocines:[];
  coursesSelect:FormControl= new FormControl([]);
  courses:any=[];

  constructor(
    public dialogRef: MatDialogRef<DialogCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    injector:Injector
  ) {
    super(injector);
  }

  applyAll:boolean = false;
  setApplyAll(value):void {
    if (value) {
      this.coursesSelect.disable();
    } else {
      this.coursesSelect.enable();
    }
  }

  ngOnInit(): void {
    if(this.data.id==0){
      this.title = "Alta Promoción"
    }else{
      this.title="Editar Promoción";
    }
    this.form = new FormGroup({
      promotion: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.maxLength(2000),
      ]),
      expiresAt: new FormControl('', Validators.required),
      amount: new FormControl('', [
        Validators.required,
        Validators.pattern("^[0-9]+(.[0-9]{0,2})?$"),
      ]),
      promotionTypeId: new FormControl('', [
        Validators.required
      ]),
      applyAll: new FormControl('',[
        Validators.required
      ]),
      active: new FormControl('',[
        Validators.required
      ])
    });
    this.form.controls.promotion.setValue(this.data.promotion);
    this.form.controls.description.setValue(this.data.description);
    this.form.controls.expiresAt.setValue(this.data.expiresAt);
    this.form.controls.amount.setValue(this.data.amount);
    this.form.controls.active.setValue((this.data.active==0?false:true));
    if (this.data.id > 0){
      this.form.controls.promotionTypeId.disable();
      this.coursesSelect.setValue(this.data.courses);
    }
    this.getTypePromotion();
  }

  cerrarDialogo(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    try {
      if (this.form.invalid) {
        this.openDialog(`Complete el formulario por favor`).subscribe(
          () => {this.form.markAllAsTouched();}
        )
      } else {
        let message:string =  (this.data.id)?`actualizar la promoción`:`crear la promoción`; 
        this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
        .then(
          (response) => {
            if (response) {
              this.form.controls.promotionTypeId.enable();
              let data:any = this.form.value;
              data.expiresAt = this.datepipe.transform(data.expiresAt, 'yyyy-MM-dd H:mm:ss');
              data.courses   = data.applyAll ? [] : this.coursesSelect.value;
              if (this.data.id > 0) {
                data.id = this.data.id;
                this.updatePromotion(data);
              } else {
                this.createPromotion(data);
              }
            }
          }
        ); 
      }
    } catch (error) {
      this.openDialog(`Ocurrió un error generar la información de la promoción: ${error.message}`);
    }
  }

  updatePromotion(data:any): void {
    this.loader.open();
    this.service.updateCatPromotion(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(`${body.message}`).subscribe(
          () => {
            if (body.success) this.dialogRef.close(true);
          }
        );        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  createPromotion(data:any): void {
    this.loader.open();
    this.service.saveCatPromocion(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(`${body.message}`).subscribe(
          () => {
            if (body.success) this.dialogRef.close(true);
          }
        );        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  getTypePromotion(){
    this.loader.open();
    this.service.getAllTypePromotion().subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.tipoPromocines = body.data;
        let promotion:any = this.tipoPromocines.find((tp:any) => tp.id === 1);
        if (promotion) {
          this.promotionTypeId = promotion.id;
          this.form.controls.promotionTypeId.setValue(promotion.id);
        };
        this.getAllCourseActive();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  getAllCourseActive(){
    this.loader.open();
    this.service.geetAllCourseActive().subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        if (!body.success) {
          this.openDialog(body.message);
          return;
        }
        this.courses = body.data;
        if (this.data.id > 0) {
          this.setCourses(this.data.courses);
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  promotionTypeId:number;
  setPromotionTypeId(promotionTypeId:number) {
    this.promotionTypeId = promotionTypeId;
  }

  selectedCourses:any[] = [];
  setCourses(courses:number[]): void {
    try {
      this.selectedCourses = [];
      courses.forEach(
        (sc) => {
          let course:any = this.courses.find((c) => c.id === sc);
          if (course) {
            this.selectedCourses.push(course);
          }
        }
      );
      this.verifyAmounts();
    } catch (error) {
      this.openDialog(`Ocurrió un error al elegir curso [${error.message}]`);
    }
  }

  amountMessage:string = '';
  verifyAmounts(): void {
    try {
      this.amountMessage = '';
      let invalidCourses:string[] = [];
      this.selectedCourses.forEach(
        (sc:any) => {
          if (sc.salePrice <= Number(this.form.controls['amount'].value)) {
            invalidCourses.push(sc.course);
          }
        }
      );
      if (invalidCourses.length > 0) {
        this.amountMessage = `El curso [${invalidCourses.join(', ')}] tiene un monto menor o igual al monto de la promoción.`
      }
    } catch (error) {
      this.openDialog(`Ocurrió un error al validar monto de promoción por curso [${error.message}]`);
    }
  }

  verifyAmount(): void {
    this.form.controls['amount'].markAsTouched();
    this.verifyAmounts();
  }

}
