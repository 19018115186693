
export class Sku{
  private id:number;
  private sku:string;

  constructor(id:number,sku:string){
    this.id = id;
    this.sku = sku;
  }

  public getID(){
    return this.id;
  }

  public getSku(){
    return this.sku;
  }

}

export class ProfileInfoInstructor{
  constructor(
      public id:number,
      public name:string,
      public username:string,
      public email:string,
      public description:string,
      public interest:string,
      public imageUrl:string,
      public lastname:string,
  ){}
}

export class CertificatesInstructor{
  constructor(
      public id:number,
      public certification:string,
      public user_id:number,
      public image_id:number
  ){}
}

export class modalMultimedia{
  constructor(
      public title:string,
      public url:string,
      public type:number,
      public message:string
  ){}
}