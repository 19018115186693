import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Observable } from 'rxjs';
import { DialogConfirmComponent } from '../dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from './admin.service';
import { DateService } from './date.service';
import { PaginatorService } from './paginator.service';
import { RoleService } from './role.service';
import { DatePipe, Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StorageService } from './storage.service';
import { ComunicationService } from 'app/comunication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Profile } from '../dashboard/users/profile';
import { LayoutService } from 'app/shared/services/layout.service';
import { NavigationService } from 'app/shared/services/navigation.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  public router:Router;
  public service:AdminService;
  public redirect:string;
  public activatedRoute:ActivatedRoute;
  public role:RoleService;
  public dateService:DateService;
  public paginatorService:PaginatorService;
  public dialog:MatDialog;
  public loader:AppLoaderService;
  public location:Location;
  public formBuilder:FormBuilder;
  public storage:StorageService;
  public datepipe: DatePipe;
  public form:FormGroup;
  public roleService:RoleService;
  public notificationC:ComunicationService;
  public sanitizer: DomSanitizer;
  public profile:Profile;
  public layout: LayoutService;
  public navService: NavigationService;
  public themeService: ThemeService;
  public translate: TranslateService;
  public renderer: Renderer2;
  public profileInfo: Profile;
  public notificacionC:ComunicationService;
  public date:DateService;
  private snackBar:MatSnackBar;
  public currency:string = 'USD';

  constructor(
    injector: Injector
  ) {
    this.redirect= '/signin';
    this.router  = injector.get(Router);
    this.service = injector.get(AdminService);
    this.activatedRoute  = injector.get(ActivatedRoute);
    this.role = injector.get(RoleService);
    this.dateService = injector.get(DateService);
    this.paginatorService = injector.get(PaginatorService);
    this.dialog = injector.get(MatDialog);
    this.loader = injector.get(AppLoaderService);
    this.location = injector.get(Location);
    this.formBuilder = injector.get(FormBuilder);
    this.storage = injector.get(StorageService);
    this.datepipe = injector.get(DatePipe);
    this.roleService = injector.get(RoleService);
    this.notificationC = injector.get(ComunicationService);
    this.sanitizer = injector.get(DomSanitizer);
    this.profile = injector.get(Profile);
    this.layout = injector.get(LayoutService);
    this.navService = injector.get(NavigationService);
    this.themeService = injector.get(ThemeService);
    this.translate = injector.get(TranslateService);
    this.renderer = injector.get(Renderer2);
    this.profileInfo = injector.get(Profile);
    this.notificacionC = injector.get(ComunicationService);
    this.date = injector.get(DateService);
    this.snackBar = injector.get(MatSnackBar);
  }

  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  public getVersion():string {
    return `Versión - 0.0.1`;
  }

  public openSnackBar(message: string, duration:number = 4000) {
    let action: string = 'Aceptar'
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  public scrollTop(){
    let top = document.querySelector('.shop-wrap');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
  
  public navigateTo(url:string) {
    this.router.navigate([url],{replaceUrl:true});
  }

  public openDialog(message:string):Observable<any> {
    return this.dialog.open(DialogComponent, {
      data: {
        title: "Mensaje de la Plataforma: ",
        message: message
      },
      autoFocus: false,
      disableClose:true
    }).afterClosed();
  }

  public openConfirm(message: string):Observable<any> {
    return this.dialog.open(DialogConfirmComponent, {
      data: { message: message, confirm:true },
      autoFocus: false,
      disableClose: true
    }).afterClosed();
  }

  public proccessHttpResponse(http:HttpErrorResponse):void{
    console.log(http);
    let datetime:string = this.dateService.getCurrentDateTime();
    let message:string = (http.error.hasOwnProperty('message'))?http.error.message:"";
    switch (http.status) {
      case 0:
        this.openDialog(`Servicio no disponible intente más tarde o verifique su conexión. ${datetime}`);
        break;
      case 401:
        if (this.router.url.includes('signup')) {
          this.openDialog(`${http.error.message}`);
        } else {
          let msg:string = (this.router.url.includes('signin')) 
          ? http.error.message
          : `Operación no permitida: "${message}" Serás redirigido al inicio ${datetime}`
          ;
          this.openDialog(`${msg}`).subscribe(
            () => {
              if (this.router.url != '/sessions/signin') this.navigateTo(this.redirect);
            }
          );
        }
        
        break;
      case 404:
        this.openDialog(`Recurso o servicio no encontrado, intente de nuevo o contacte con el área de soporte. ${datetime}`);
        break;
      case 405:
        this.openDialog(` Método de consumo no válido: [${message}]. ${datetime}`);
        break;
      case 422:
        this.openDialog(`${message} ${datetime}`);
        break;
      case 500:
        this.openDialog(`${message} ${datetime}`);
        break;
      default:
        this.openDialog(`Error con el servicio. Intente entrar nuevamente o contacte con el area de soporte. ${datetime}`).subscribe(
          () => {
            this.router.navigate([this.redirect],{replaceUrl:true});
          }
        );
        break;
    }
  }

  public signout() {
    this.service.logout().subscribe(
      (response) => {
        let body:IResponse = response.body;
        if (!body.success){
          this.openDialog(body.message);
        }
        this.navigateTo('/sessions/signin');
      }, 
      (error:HttpErrorResponse) => {
        this.proccessHttpResponse(error);
      }
    )
  }
  
  goTo(url:string){
    if (url.includes('home')) {
      window.location.replace(url);
    } else {
      this.router.navigateByUrl(url,{replaceUrl:true});
    } 
  }

  getActiveRouteClass(url:string):string {
    let activeClass:string = "";
    if (url === this.router.url) {
      activeClass = "active-route";
    }
    return activeClass;
  }

}
export interface ICourse {
  courseId:number;
  course:string;
  productId?:string;
  salePrice:number;
};
export interface IResponse{
  success:boolean;
  message:string;
  datetime?:string;
  data:any;
};
export interface IRelCoursesPaymentsMethods{
  rcpmId:number;
  courseId:number;
  paymentMethodId:number;
  course?:string;
  paymentMethod?:string;
};
export interface ICatPaymentMethods{
  id:number;
  paymentMethod:string;
  active?:boolean;
};
export interface IStripePriceType{
  id:number;
  priceType:string;
};
export interface IStripePrice{
  priceId:string;
  productId:string;
  courseId:number;
  priceTypeId:number;
  active:boolean;
  createdAt:string;
  updatedAt:string;
  priceType:string;
  unitAmount:number;
  courseSalePrice:any;
  promotionAmount?:number;
}
