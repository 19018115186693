<app-header-top-sessions fxFlex *ngIf="!activo"></app-header-top-sessions>
<app-header-top fxFlex *ngIf="activo"></app-header-top>
<div [style.margin-top]="activo ? '0px;' : '150px' " style="overflow-x: hidden;width: 100%;">
	<div class="page-wrapper">

			<div class="stricky-header stricked-menu main-menu">
					<div class="sticky-header__content">

					</div><!-- /.sticky-header__content -->
			</div><!-- /.stricky-header -->

			

			<section class="main-slider main-slider-one">
					<div class="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 1, "loop": false, "effect": "fade", "pagination": {
			"el": "#main-slider-pagination",
			"type": "bullets",
			"clickable": true
			},
			"navigation": {
			"nextEl": "#main-slider__swiper-button-next",
			"prevEl": "#main-slider__swiper-button-prev"
			},
			"autoplay": {
			"delay": 7000
			}}'>

			

							<div class="swiper-wrapper">
									<!--Start Single Swiper Slide-->
									<div class="swiper-slide">
											<div class="image-layer"
													style="background-image: url(assets/new/images/backgrounds/main-slider-v1-1.jpg);"></div>
											<!-- /.image-layer -->
											<div class="container">
													<div class="main-slider__content">
															<div class="main-slider__content-tagline">
																	<h2>Personaliza tu stream</h2>
															</div>
															<h2 class="main-slider__content-title">Demuestra<br>tu creatividad</h2>
															<p class="main-slider__content-text">Cursos, interfaces, alertas <br>&nbsp;y mucho más para mejorar tu stream.</p>
															<div class="main-slider__content-btn">
																<a [routerLink]="activo ? ['/dashboard/courses'] : ['/signup']" class="thm-btn">Cambia tu stream ahora</a>
															</div>
															<video autoplay muted loop id="myVideo">
																<source src="../../../../assets/video.mp4" type="video/mp4">
																Your browser does not support HTML5 video.
															</video>
													</div>
											</div>
									</div>
									<!--End Single Swiper Slide-->
							</div>
					</div>
			</section>

			<!--Courses One Start-->
			<section *ngIf="courses.length > 0" class="courses-one" style="padding-top:120px" id="cursos">
				<div class="container">
						<div class="section-title text-center">
								<h2 class="section-title__title text-white">Nuestros Assets</h2>
						</div>
						<!-- courses-container -->
						<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}" style="justify-content: center;align-items: center;">
							<!-- Course Box -->
							<div class="product-wrap product-wrap-details m-1 course-container"
								*ngFor="let course of courses; let i = index;"
								[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
								<div fxLayout="column" fxFlex="100">
									<div 
										fxFlex="100" 
										fxLayout="column" 
										fxLayoutAlign="center center" 
										class="courses-one__single wow fadeInLeft"
										[routerLink]="activo ? ['/dashboard/courses'] : ['/courses']"
										>
										<div class="courses-one__single-img">
											<img [src]="course.imageUrl" alt="" />
											<div class="overlay-text" style="cursor: pointer;">
												<p [routerLink]="activo ? ['/dashboard/courses'] : ['/courses']">Ver detalles</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- end course box -->
						</div>
				</div>
		</section>
		<!--Courses One End-->
		<section *ngIf="courses.length <= 0" class="courses-one" style="padding-top:120px" id="cursos">
			<div class="container">
				<div class="section-title text-center">
						<h2 class="section-title__title text-white">Nuestros Cursos</h2>
				</div>
				<div class="text-white text-center">
					<h4>No hay cursos disponibles</h4>
				</div>
			</div>
		</section>

			<!--Features One Start-->
			<section class="features-one">
					<div class="container">
							<div class="row">
									<!--Start Single Features One-->
									<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
											<div class="features-one__single">
													<div class="features-one__single-icon">
															<span class="icon-empowerment"></span>
													</div>
													<div class="features-one__single-text">
															<h4>Aprende</h4>
															<p>Con Nuestros Cursos</p>
													</div>
											</div>
									</div>
									<!--End Single Features One-->

									<!--Start Single Features One-->
									<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
											<div class="features-one__single">
													<div class="features-one__single-icon">
															<span class="icon-human-resources-1"></span>
													</div>
													<div class="features-one__single-text">
															<h4>Personaliza</h4>
															<p>Overlays temáticas</p>
													</div>
											</div>
									</div>
									<!--End Single Features One-->

									<!--Start Single Features One-->
									<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
											<div class="features-one__single">
													<div class="features-one__single-icon">
															<span class="icon-recruitment"></span>
													</div>
													<div class="features-one__single-text">
															<h4>Comparte</h4>
															<p>Comunidad de streamers</p>
													</div>
											</div>
									</div>
									<!--End Single Features One-->
							</div>
					</div>
			</section>
			<!--Features One End-->        

			<!--Why Choose One Start-->
			<section class="why-choose-one">
					<div class="container">
							<div class="row">
									<!--Start Why Choose One Left-->
									<div class="col-xl-6 col-lg-6">
											<div class="why-choose-one__left">
													<div class="section-title">
															<span class="section-title__tagline">¿Por qué nosotros?</span>
															<h2 class="section-title__title text-white">De streamers <br>para streamers</h2>
													</div>
													<div class="why-choose-one__left-learning-box">
															<div class="icon">
																	<span class="icon-professor"></span>
															</div>
															<div class="text">
																	<h4 class="text-yellow">Sabemos lo dificil que es destacar y queremos apoyarte a lograrlo.</h4>
															</div>
													</div>
													<div class="why-choose-one__left-list">
															<ul class="list-unstyled">
																	<li class="why-choose-one__left-list-single">
																			<div class="icon">
																					<span class="icon-confirmation"></span>
																			</div>
																			<div class="text">
																					<p style="font-size:22px;" class="text-white">Transforma tu stream y dale ese toque que te caracteriza</p>
																			</div>
																	</li>

															</ul>
													</div>
											</div>
									</div>
									<!--End Why Choose One Left-->

									<!--Start Why Choose One Right-->
									<div class="col-xl-6 col-lg-6">
											<div class="video-one__box slideInRight wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
											style="background-image: url(assets/new/images/resources/why-choose-v1-img.jpg);">
											<div class="video-one__box-title">
													<div class="why-choose-one__right-img-overlay">
															<p>Sayucfox</p>
													</div>
											</div>
											</div>
									</div>
									<!--End Why Choose One Right-->

							</div>
					</div>
			</section>
			<!--Why Choose One End-->  

			

			



			<!--Counter One Start-->
			


			

			<!--Testimonials One Start-->
			<section *ngIf="testimonials.length > 0" class="testimonials-one clearfix" style="height: 500px;">
				<div class="auto-container">
						<div class="section-title text-center">
								<span class="section-title__tagline">Testimonios</span>
								<h2 class="section-title__title"> Lo que se dice</h2>
						</div>
						<div class="row">
								<div class="col-xl-12">
										<div class="testimonials-one__wrapper">
												<div class="testimonials-one__pattern"><img
																src="assets/new/images/pattern/testimonials-one-left-pattern.png" alt="" /></div>
												<div class="shape1"><img src="assets/new/images/shapes/thm-shape3.png" alt="" /></div>
										</div>
								</div>
						</div>
				</div>
			</section>
			<div *ngIf="testimonials.length > 0" class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}" style="justify-content: center;align-items: center;transform: translate(0px,-35vh);">
				<!-- Course Box -->
				<div style="margin-bottom: 2vh;"
							*ngFor="let t of testimonials | paginate: { id:'pagination',itemsPerPage: itemsPerPageP1, currentPage: currentPageP1, totalItems: rowsNumberP1 }; let i = index;"
							[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
							<div fxLayout="column" fxFlex="100" class="testimonial-container">
								<div 
									fxFlex="100" 
									fxLayout="column" 
									fxLayoutAlign="center start" class="testimonial-content">
									<h4 class="testimonials-one__single-title">{{t?.title}}</h4>
									<div class="meet-teachers-one__single-img_2">
										<img src="{{t?.imageUrl}}" alt=""/>
									</div>
									<p class="testimonials-one__single-text">{{t?.testimonial}}</p>
									<div class="testimonials-one__single-client-info">
											<div class="testimonials-one__single-client-info-text">
													<h5>{{t?.author}}</h5>
													<p>{{t?.ocupation}}</p>
											</div>
									</div>
								</div>
							</div>
							
				</div>
			</div>
			<div *ngIf="testimonials.length > 0" class="text-center" style="min-height:120px;transform: translate(0px,-35vh)">
				<pagination-controls id="pagination" (pageChange)="changePagePaginator1($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
			</div>
			<!--Testimonials One End-->

			<!--Start Registration Two-->
			<section class="registration-two">
					<div class="container">
							<div class="row">
									<div class="col-xl-12">
											<div class="registration-two__wrapper">
													<div class="shape2 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"><img
																	src="assets/new/images/shapes/thm-shape3.png" alt="" /></div>
													<div class="registration-two__left">
															<h2 class="registration-two__left-text" style="text-transform: none;">Te ayudamos a mejorar tu stream<br>y tu contenido</h2>
													</div>
													<div class="registration-two__right">
															<div class="registration-two__right-btn">
																	<a [routerLink]="['/signup']" class="thm-btn">Registrate</a>
															</div>
													</div>
											</div>
									</div>

							</div>
					</div>
			</section>
			<!--End Registration Two-->    
									

			<!--Start Footer One-->
			<footer *ngIf="!activo" class="footer-one">
					<div class="footer-one__bg" style="background-image: url(assets/new/images/backgrounds/footer-v1-bg.jpg);">
					</div><!-- /.footer-one__bg -->
					<div class="footer-one__top">
							<div class="container">
									<div class="row">
											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
													<div class="footer-widget__column footer-widget__about">
															<div class="footer-widget__about-logo">
																	<a href="index.html"><img src="assets/new/images/resources/footer-logo.png" alt=""></a>
															</div>
													</div>
											</div>
											<!--End Footer Widget Column-->
									

											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
													<div class="footer-widget__column footer-widget__courses">
															<h3 class="footer-widget__title">Enlaces útiles</h3>
															<ul class="footer-widget__courses-list list-unstyled">
																	<li><a href="https://discord.gg/TUdhuAX9gJ">Discord</a></li>
																	<li><a href="https://www.youtube.com/@sayucfox">Youtube</a></li>

															</ul>
													</div>
											</div>
											<!--End Footer Widget Column-->

											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
													<div class="footer-widget__column footer-widget__links">
															<h3 class="footer-widget__title">Legales</h3>
															<ul class="footer-widget__links-list list-unstyled">
																<li><a (click)="(isLogged)?openDialogPrivacy():services.goTo('/privacy')">Aviso de privacidad</a></li>
                          			<li><a (click)="(isLogged)?openDialogPrivacy():services.goTo('/privacy')">Términos y condiciones</a></li>
															</ul>
													</div>
											</div>
											<!--End Footer Widget Column-->


											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 col-12 wow animated fadeInUp" data-wow-delay="0.9s">
													<div class="footer-widget__column footer-widget__social-links">
															<ul class="footer-widget__social-links-list list-unstyled clearfix">
																	<li><a href="https://www.instagram.com/sayucfox/" target="_blank"><i class="fab fa-instagram"></i></a></li>
																	<li><a href="https://www.tiktok.com/@sayucfox" target="_blank"><i class="fab fa-tiktok"></i></a></li>
																	<li><a href="https://www.youtube.com/@sayucfox" target="_blank"><i class="fab fa-youtube"></i></a></li>
															</ul>
													</div>
											</div>
											<!--End Footer Widget Column-->
									</div>
							</div>
					</div>

					<!--Start Footer One Bottom-->
					<div class="footer-one__bottom">
							<div class="container">
									<div class="row">
											<div class="col-xl-12">
													<div class="footer-one__bottom-inner">
															<div class="footer-one__bottom-text text-center">
																<p>&copy; Todos los derechos reservados 2023.<br>Ninguna parte de este material puede ser reproducido, copiado, distribuido o replicado sin el permiso por escrito de Sayuc Element S.A de C.V
																</p>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
					<!--End Footer One Bottom-->
			</footer>
			<!--End Footer One-->


	</div><!-- /.page-wrapper -->



	<div class="mobile-nav__wrapper">
			<div class="mobile-nav__overlay mobile-nav__toggler"></div>
			<!-- /.mobile-nav__overlay -->
			<div class="mobile-nav__content">
					<span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

					<div class="logo-box">
							<a href="index.html" aria-label="logo image"><img src="assets/new/images/resources/mobilemenu_logo.png"
											width="155" alt="" /></a>
					</div>
					<!-- /.logo-box -->
					<div class="mobile-nav__container"></div>
					<!-- /.mobile-nav__container -->

					<ul class="mobile-nav__contact list-unstyled">
							<li>
									<i class="icon-phone-call"></i>
									<a href="mailto:needhelp@packageName__.com">Únete al DISCORD</a>
							</li>
							<li>
									<i class="icon-letter"></i>
									<a href="tel:666-888-0000">666 888 0000</a>
							</li>
					</ul><!-- /.mobile-nav__contact -->
					<div class="mobile-nav__top">
							<div class="mobile-nav__social">
									<li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
									<li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
									<li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
									<li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
							</div><!-- /.mobile-nav__social -->
					</div><!-- /.mobile-nav__top -->
			</div>
			<!-- /.mobile-nav__content -->
	</div>
	<!-- /.mobile-nav__wrapper -->


	<a (click)="gotoTop()" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
</div>

<script>
	var video = document.getElementById("myVideo");
	var btn = document.getElementById("myBtn");
	
	function myFunction() {
	  if (video.paused) {
		video.play();
		btn.innerHTML = "Pause";
	  } else {
		video.pause();
		btn.innerHTML = "Play";
	  }
	}
	</script>