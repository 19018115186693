<div fxLayout="row" fxLayoutAlign="space-between center" >
  <h2 mat-dialog-title style="margin: 0px;">{{multimedia.title}}</h2>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false"  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" fxLayout="row" fxLayoutAlign="center center" style="width: 100% !important; height: 100% !important; margin: 0px !important; padding: 0px 0px 7px 0px !important;">
  <img *ngIf="multimedia.type==1" style="height: 100%;" mat-card-image [src]="multimedia.url">
  <video *ngIf="multimedia.type==2" controls controlsList="nodownload" preload="auto" style="height: 100%; border-radius:20px; box-shadow: 5px 5px 5px 2px #cecece;">
    <source [src]="multimedia.url"/>
  </video>
</mat-dialog-content>