import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  message:string = "";
  title:string = "";

  ngOnInit(): void {
    this.message = this.data.message || this.data?.content;
    this.title = this.data.title;
  }
  
  close(): void {
    this.dialogRef.close(false);
  }
  
  sanitizeText(text:string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}

export interface DialogData {
  title: string;
  message: string;
  content: string;
}
