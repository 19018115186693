import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-dialog-order-topics',
  templateUrl: './dialog-order-topics.component.html',
  styleUrls: ['./dialog-order-topics.component.css']
})
export class DialogOrderTopicsComponent implements OnInit {

  topics = [];

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.topics, event.previousIndex, event.currentIndex);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOrderTopicsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private service: AdminService
  ) { }

  ngOnInit(): void {
    this.topics = this.data.topics;
  }

  openConfirmUpdateOrder() {
    let message:string = `actualizar el orden de los temas`;
    this.service.openConfirm(`¿Seguro(a) que desea ${message}?`)
    .then(
      (response) => {
        if (response) {
          let orderedTopics:any = this.prepareTopics();
          this.dialogRef.close(orderedTopics);
        }
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    ); 
  }

  prepareTopics() {
    let orderedTopics:any[] = [];
    this.topics.forEach(
      (topic:any) => {
        let indexOf:number = this.topics.indexOf(topic);
        orderedTopics.push(
          {
            topicId:topic.id,
            topicName:topic.topic,
            order:indexOf
          }
        );
      }
    )
    this.topics = [];
    return orderedTopics;
  }


}

export interface DialogData {
  topics:any;
}