const API = {
  //LOCAL
  //URL_BASE: 'http://localhost:8000/api/',
  //URL_BASE_STORAGE: 'http://localhost:8000',
  //URL_BASE_FRONT: 'http://localhost:4200/',
  //TEST
  //URL_BASE: 'http://celex.webhop.net/sayucfox/services/public/api/',
  //URL_BASE_STORAGE: 'http://celex.webhop.net/sayucfox/services/public',
  //URL_BASE_FRONT: 'http://celex.webhop.net/sayucfox/web/',
  //PROD
  URL_BASE: 'https://sayucfox.com/services/public/api/',
  URL_BASE_STORAGE: 'https://sayucfox.com/services/public',
  URL_BASE_FRONT: 'https://sayucfox.com/',
}
export{ API };