import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Profile {
    public profileInfoUser = new profileInfo('','');
    public _profileInfoUser = new BehaviorSubject<any>({});
    profileInfoUser$ = this._profileInfoUser.asObservable();
    constructor() {}
    
    setinfoProfile(value:any){
        this.profileInfoUser = value;
        this._profileInfoUser.next(this.profileInfoUser);
    }

    public profileInfoPartner = new profileInfo('','');
    public _profileInfoPartner = new BehaviorSubject<any>({});
    profileInfoPartner$ = this._profileInfoPartner.asObservable();
    setinfoProfilePartner(value:any){
        this.profileInfoPartner = value;
        this._profileInfoPartner.next(this.profileInfoPartner);
    }
}

export class profileInfo{
    constructor(
        public username:string,
        public imagenURL:string,
        public userLevelId?:number
    ){}
}