import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EgretCalendarEvent } from 'app/shared/models/event.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  public events: EgretCalendarEvent[];
  constructor(
    private http: HttpClient
  ) {}

  public setEvents(events: EgretCalendarEvent[]){
    this.events = events;
  }

  public getEvents(): Observable<EgretCalendarEvent[]> {
    // return this.http.get('api/calendar/events')
    // .map((events: CalendarEvent[]) => {
    //   this.events = events;
    //   return events;
    // });

    //let eventDB = new CalendarEventDB();
    //console.log(eventDB.events);
    return of(this.events)
      .pipe(
        map(events => {
          this.events = events;
          return events;
        })
      ) ;
  }

  public addEvent(event): Observable<EgretCalendarEvent[]> {
    // return this.http.post('api/calendar/events', event)
    // .map((events: EgretCalendarEvent[]) => {
    //   this.events = events;
    //   return events;
    // });

    this.events.push(event);
    return of(this.events);
  }

  public updateEvent(event): Observable<EgretCalendarEvent[]> {
    // return this.http.put('api/calendar/events/'+event._id, event)
    // .map((events: EgretCalendarEvent[]) => {
    //   this.events = events;
    //   return events;
    // });

    this.events = this.events.map(e => {
      if(e._id === event._id) {
        return Object.assign(e, event);
      }
      return e;
    });
    return of(this.events);
  }

  public deleteEvent(eventID: string): Observable<EgretCalendarEvent[]> {
    // return this.http.delete('api/calendar/events/'+eventID)
    // .map((events: EgretCalendarEvent[]) => {
    //   this.events = events;
    //   return events;
    // });

    this.events = this.events.filter(e => e._id !== eventID);
    return of(this.events);
  }

  public getColorEvents():any{
    return new CalendarEventDB().getColors();
  }

}

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';

export class CalendarEventDB {
  private colors: any = {
    red: {
      primary: '#f44336',
      secondary: '#FAE3E3'
    },
    blue: {
      primary: '#247ba0 ',
      secondary: '#D1E8FF'
    },
    yellow: {
      primary: '#ffd97d',
      secondary: '#FDF1BA'
    },
    green: {
      primary: '#80B937',
      secondary: '#b5d68a'
    }
  };

  public getColors():any{
    return this.colors;
  }

  public events:  any[] = [{
    _id: '100',
    start: subDays(startOfDay(new Date()), 1),
    end: addDays(new Date(), 1),
    title: 'A 3 day event',
    color: this.colors.green
  }, {
    _id: '101',
    start: startOfDay(new Date()),
    title: 'An event with no end date',
    color: this.colors.yellow
  }, {
    _id: '102',
    start: subDays(endOfMonth(new Date()), 3),
    end: addDays(endOfMonth(new Date()), 3),
    title: 'A long event that spans 2 months',
    color: this.colors.blue
  }, {
    _id: '103',
    start: addHours(startOfDay(new Date()), 2),
    end: new Date(),
    title: 'A draggable and resizable event',
    color: this.colors.yellow,
    resizable: {
      beforeStart: true,
      afterEnd: true
    },
    draggable: true
  }];
}