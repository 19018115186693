<!--
  <footer class="main-footer bg-purple text-light">
  
  <div style="padding-bottom: 0; margin-bottom: 0; margin-top: 35px;" class="container" fxFlexAlign="center" fxLayout="column" fxLayoutGap="20px" >
    <div fxLayoutAlign="center center" class="cardWrap" fxLayout.lt-md="column" fxLayout.md="column"  fxLayout="row" fxFlex.md="90" fxLayoutAligmnet="space-between center" fxLayoutGap="100px" >
      <div fxLayoutAlign.xs="center center" fxLayoutAlign.md="center center" fxLayoutAlign.sm="center center" fxLayout.xs="column" fxFlex.lt-md="100" fxFlex="305px">
        <img src="./assets/images/entrepeneurs/Group 797.png">
        <small style="width: 304px; font-size: 14px; display: inline-block; text-align: center; margin-top: 10px;">Articulamos conocimiento, oportunidades y talento con la prosperidad.</small>
      </div>
      <div fxFlexFill fxLayoutAlign.md="space-between center" fxLayoutAlign.xs="space-between center" fxLayoutAlign.sm="space-between center" 
      fxLayout.xs="column" fxLayout.md="column" fxLayout.sm="column" fxFlexAlign="center" fxFlex.lt-md="100" fxFlex="305px" fxFlex.xs="100">
        <mat-label>Enlaces útiles</mat-label>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex.xs="100" fxFlex.md="100" fxFlex.sm="100">
          <div [ngStyle.xs]="'width:160px;margin-right:5px'" fxFlexAlign="center" fxFlex.lt-md="100" fxFlex="120px" fxFlex.xs="50" fxLayout.xs="column" fxLayoutAlign.xs="stretch end">
            <small href="" > <a href="#" onclick='window.open("https://www.fundacione.org/");return false;'> Fundación E.</a></small>
            <br>
            <small style="cursor: pointer;" (click)="(isLogged)?openDialogPrivacy():service.goTo('/privacy')">Aviso de privacidad</small>
          </div>
          <div [ngStyle.xs]="'width:160px;margin-left:5px'" fxFlexAlign="center" fxFlex.lt-md="100" fxFlex="120px" fxFlex.xs="50" fxLayout.xs="column" fxLayoutAlign.xs="stretch start">
            <small>Blog</small>
            <br/>
            <small style="cursor: pointer;" (click)="(isLogged)?openDialogTerms():service.goTo('/terms')">Términos y Condiciones</small>
          </div>
        </div>
      </div>
      <div fxFlexAlign="center" fxLayoutAlign.md="space-between center" fxLayoutAlign.xs="space-between center" fxLayoutAlign.sm="space-between center" fxLayout="column" fxFlex.lt-md="100" fxFlex="20">
        <mat-label>Síguenos en:</mat-label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <button class="m-1" mat-mini-fab color="white" [matTooltip]="'Facebook'" (click)="openNewTab('https://www.facebook.com/4x4Emprendedores/')">
            <img class="color-purple" src='assets/images/entrepeneurs/facebook24x24.png'>
          </button>
          <button class="m-1" mat-mini-fab color="white" [matTooltip]="'Instagram'" (click)="openNewTab('https://www.instagram.com/4x4emprendedores/')">
            <img class="color-purple" src='assets/images/entrepeneurs/instagram24x24.png'>
          </button>
          <button class="m-1" mat-mini-fab color="white" [matTooltip]="'YouTube'">
            <img class="color-purple" src='assets/images/entrepeneurs/youtube24x24.png'>
          </button>
          <button class="m-1" mat-mini-fab color="white" [matTooltip]="'LinkedIn'" (click)="openNewTab('https://www.linkedin.com/company/4x4emprendedores/?viewAsMember=true')">
            <img class="color-purple" src='assets/images/entrepeneurs/linkedin24x24.png'>
          </button>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 20px; margin-bottom: 20px; text-align: center;" class="cardWrap" fxLayout.lt-md="column" fxLayout.md="column" fxLayout="row" fxFlex.md="50" fxLayoutAligmnet="space-between center" fxLayoutGap="20px">
      <div fxFlexFill fxFlexAlign="center" fxFlex.lt-md="100" [ngStyle.xs]="'text-align: justify;'">
        <small>"Todos los derechos reservados 2021 - 2031" ninguna parte de este material puede ser reproducido, copiado, distribuido o replicado sin el permiso por escrito de Fundación Educación para Emprendedores AC.</small>
      </div>
    </div>
  </div>

</footer>
-->
<!--Start Footer One-->
<footer class="footer-one">
  <div class="footer-one__bg" style="background-image: url(assets/new/images/backgrounds/footer-v1-bg.jpg);">
  </div><!-- /.footer-one__bg -->
  <div class="footer-one__top">
      <div class="container">
          <div class="row">
              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                  <div class="footer-widget__column footer-widget__about">
                      <div class="footer-widget__about-logo">
                          <a href="index.html"><img src="assets/new/images/resources/footer-logo.png" alt=""></a>
                      </div>
                  </div>
              </div>
              <!--End Footer Widget Column-->
          

              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                  <div class="footer-widget__column footer-widget__courses">
                      <h3 class="footer-widget__title">Enlaces útiles</h3>
                      <ul class="footer-widget__courses-list list-unstyled">
                          <li><a href="https://discord.gg/TUdhuAX9gJ">Discord</a></li>
                          <li><a href="https://www.youtube.com/@sayucfox">Youtube</a></li>

                      </ul>
                  </div>
              </div>
              <!--End Footer Widget Column-->

              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                  <div class="footer-widget__column footer-widget__links">
                      <h3 class="footer-widget__title">Legales</h3>
                      <ul class="footer-widget__links-list list-unstyled">
                          <li><small class="footer-link" (click)="(isLogged)?openDialogPrivacy():service.goTo('/privacy')">Aviso de privacidad</small></li>
                          <li><small class="footer-link" (click)="(isLogged)?openDialogPrivacy():service.goTo('/privacy')">Términos y condiciones</small></li>
                      </ul>
                  </div>
              </div>
              <!--End Footer Widget Column-->


              <!--Start Footer Widget Column-->
              <div class="col-xl-3 col-lg-6 col-md-6 col-12 wow animated fadeInUp" data-wow-delay="0.9s">
                  <div class="footer-widget__column footer-widget__social-links">
                      <ul class="footer-widget__social-links-list list-unstyled clearfix">
                          <li><a href="https://www.instagram.com/sayucfox/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                          <li><a href="https://www.tiktok.com/@sayucfox" target="_blank"><i class="fab fa-tiktok"></i></a></li>
                          <li><a href="https://www.youtube.com/@sayucfox" target="_blank"><i class="fab fa-youtube"></i></a></li>
                      </ul>
                  </div>
              </div>
              <!--End Footer Widget Column-->
          </div>
      </div>
  </div>

  <!--Start Footer One Bottom-->
  <div class="footer-one__bottom">
      <div class="container">
          <div class="row">
              <div class="col-xl-12">
                  <div class="footer-one__bottom-inner">
                      <div class="footer-one__bottom-text text-center">
                          <p>&copy; Todos los derechos reservados 2023.<br>Ninguna parte de este material puede ser reproducido, copiado, distribuido o replicado sin el permiso por escrito de Sayuc Element S.A de C.V
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <!--End Footer One Bottom-->
</footer>
<!--End Footer One-->